<template>
  <div>
    <div class="swiper-container display_between"
         id="jiangzhe">
      <div class="prevbutton">
        <img src="../../assets/icons/sleft1.png"
             alt="">
      </div>
      <swiper :options="jzSwiperOption"
              class="jzSwiper"
              ref="mySwiper">
        <swiper-slide v-for="(item, index) in expertsList"
                      class="m3jz display_flex"
                      :key="index">
          <img :src="item.headPortraitUrl"
               alt="">
          <div class="m3jzr display_column">
            <span class="m3jzname">{{item.name}}</span>
            <div class="m3jzteach">{{item.unit}}</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="nextbutton"
           style="z-index:1000">
        <img src="../../assets/icons/sright1.png"
             alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      cid: 0,
      uid: 0,
      jzSwiperOption: {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.nextbutton',
          prevEl: '.prevbutton'
        }
      },
    }
  },
  props: {
    expertsList: [],
  },
  watch: {
  },
  created () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
#jiangzhe {
  width: 720px;
  height: 42px;
  overflow: hidden;
  margin-left: 18px;
  position: relative;
  .jzSwiper {
    margin: 0 10px;
    flex: 1;
  }
  .m3jz {
    width: 265px;
    height: 42px;
    padding: 0 5px;
    .m3jzr {
      flex: 1;
    }
    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .m3jzname {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
    }
    .m3jzteach {
      width: 203px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9a9a9a;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .nextbutton,
  .prevbutton {
    cursor: pointer;
  }
  .nextbutton:focus,
  .prevbutton:focus {
    outline: none;
  }
}
</style>