import Vue from 'vue'
import VueRouter from 'vue-router'
import detail from '../views/detail'
import detailVideo from '../views/detail/video'
import home from '../views/home/index'
import detailAudio from '../views/detail/audio'
import detailEbook from '../views/detail/ebook'
import allCourse from '../views/course/all'
import mSearch from '../views/course/mSearch'
import list from '../views/course/list'
import medical from '../views/medical/index'
//老版本页面跳转
import oldDetail from '../views/oldPage/detail'
import oldList from '../views/oldPage/list'
const routes = [
  { path: '', redirect: '/home' },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: { navShow: true, title: '全科知家' },
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail,
    meta: { navShow: true, title: '课程详情' },
    children: [
      {
        path: '/video',
        name: 'detailVideo',
        component: detailVideo,
        meta: { navShow: true, title: '课程详情-视频' }
      },
      {
        path: '/audio',
        name: 'detailAudio',
        component: detailAudio,
        meta: { navShow: true, title: '课程详情-音频' }
      },
      {
        path: '/ebook',
        name: 'detailEbook',
        component: detailEbook,
        meta: { navShow: true, title: '课程详情-电子书' }
      },
    ]
  },
  {
    path: '/allCourse',
    name: 'allCourse',
    component: allCourse,
    meta: { navShow: true, title: '全部课程' },
  },
  {
    path: '/mSearch',
    name: 'mSearch',
    component: mSearch,
    meta: { navShow: true, title: '搜索' },
  },
  {
    path: '/list',
    name: 'list',
    component: list,
    meta: { navShow: true, title: '课程列表' },
  },
  //老版本空页面
  {
    path: '/AllCourse/AllCoursedetail',
    name: 'oldDetail',
    component: oldDetail,
    meta: { navShow: true, title: '跳转中..' },
  },
  {
    path: '/HomeAllCourseList',
    name: 'oldList',
    component: oldList,
    meta: { navShow: true, title: '跳转中..' },
  },
  {
    path: '/Promote',
    name: 'oldList',
    component: oldList,
    meta: { navShow: true, title: '跳转中..' },
  }, {
    path: '/medical',
    name: 'medical',
    component: medical,
    meta: { navShow: true, title: '秒知医技' },
  },
]

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
})
export default router
