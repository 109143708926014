<template>
  <div>
    <div v-if="!isMobile()">
      <div class="topf">
        <img :src="topUrl" alt="">
      </div>
      <div class="box">
        <div class="mid display_between">
          <div class="midleft display_flex">
            <div :style="midIndex == 0 ? 'background: #0B8586;color:#fff' : ''" @click="toType(1, 0)" class="mlbq">全部
            </div>
            <div :style="midIndex == 1 ? 'background: #0B8586;color:#fff' : ''" @click="toType(1, 1)" class="mlbq">会员专享
            </div>
            <div :style="midIndex == 2 ? 'background: #0B8586;color:#fff' : ''" @click="toType(1, 2)" class="mlbq">免费
            </div>
            <!-- <div :style="midIndex == 4 ? 'background: #0B8586;color:#fff' : ''"
                 @click="midIndex = 4"
                 class="mlbq">课件</div> -->
          </div>
          <div class="midright display_flex">
            <div :style="midIndex2 == 0 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''" @click="toType(2, 0)"
              class="mlbq2">默认</div>
            <div :style="midIndex2 == 1 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''" @click="toType(2, 1)"
              class="mlbq2">最新</div>
            <div :style="midIndex2 == 2 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''" @click="toType(2, 2)"
              class="mlbq2">最热</div>
            <!-- <div :style="midIndex2 == 3 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''"
                 @click="midIndex2 = 3"
                 class="mlbq2">限时折扣</div> -->
          </div>
        </div>
        <div class="bot EC_width display_flex">
          <Course :info="item" :restype="midIndex" :ressort="midIndex2" :resindex="pageIndex" :listType="type"
            class="botbox" v-for="(item, index) in courseList" :style="index % 4 == 3 ? 'margin-right:0px' : ''"
            :key="index"></Course>
        </div>
        <div class="nolist" v-show="pageCount == 0">暂无课程</div>
        <div class="fenye">
          <pagination :total="pageCount" :limit="pageSize" :page="pageIndex" :isNum="true" @page-change="pageChange">
          </pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <Mlist  :type="type"></Mlist>
    </div>
  </div>
</template>

<script>
import Mlist from "../../components/mobile/course/list.vue"
import Course from '../../components/course/index.vue';
import pagination from '../../components/pagination/index.vue';
export default {
  components: {
    Mlist,
    Course,
    pagination,
  },
  data() {
    return {
      topUrl: '',
      type: 0,
      midIndex: 0,
      midIndex2: 0,
      pageCount: 0,
      pageIndex: 1,
      pageSize: 16,
      courseList: [],
    }
  },
  created() {
    if (this.$route.query.type) {
      this.type = Number(this.$route.query.type)
    }
    if (this.$route.query.restype) {
      this.midIndex = Number(this.$route.query.restype);
    }
    if (this.$route.query.ressort) {
      this.midIndex2 = Number(this.$route.query.ressort);
    }
    if (this.$route.query.resindex) {
      this.pageIndex = Number(this.$route.query.resindex);
    }
    this.getAllCoures();
    this.getlogo()

    this.wx.miniProgram.getEnv(res => {
      if (res.miniprogram) {
        this.wx.miniProgram.postMessage({
          data: {
            shareTitle: '【全科知家】',
            page: '/pages/index/webview',
            shareUrl: window.location.href
          },
        });
      } else {
        //alert("不在小程序里");
      }
    })
  },
  methods: {
    toType(type, value) {
      if (type == 1) this.midIndex = value
      else this.midIndex2 = value
      this.pageIndex = 1;
      this.getAllCoures();
    },
    getlogo() {
      this.httphelper
        .get('CourseApi/GetCourseNavigConfig', {
          Id: this.type
        })
        .then(res => {
          if (res.code == 200) {
            this.topUrl = res.data.pcBackImgUrl

          }
        })
    },
    getAllCoures() {
      this.httphelper
        .post('CourseApi/GetNaviCoursePageList', {
          navId: this.type,
          courseType: this.midIndex,
          orderType: this.midIndex2,
          page: this.pageIndex,
          limit: 16
        })
        .then(res => {
          if (res.code == 200) {
            this.courseList = res.data;
            this.pageCount = res.totalcount;

          }
        })
    },
    pageChange(page) {
      if (page == this.pageIndex) {
        return;
      }
      this.pageIndex = page;
      this.getAllCoures();
    },
  }
}
</script>
<style lang="scss" scoped>
.topf {
  width: 100%;
  height: 355px;
  overflow: hidden;

  img {
    width: 1920px;
    height: 355px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background: #f3f5f6;
  }
}

.mid {
  width: 1290px;
  height: 85px;
  background: #ffffff;
  margin: -67px auto 0;
  padding: 33px 45px 18px;

  .mlbq {
    width: 100px;
    height: 34px;
    border: 1px solid #e5e5e5;
    border-radius: 17px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }

  .mlbq2 {
    height: 36px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
    padding: 10px 1px 10px 1px;
    margin-left: 40px;
    cursor: pointer;
  }
}

.nolist {
  width: 100%;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.bot {
  margin-top: 17px;
  flex-wrap: wrap;

  .botbox {
    margin-bottom: 25px;
    margin-right: 26px;
  }
}
</style>