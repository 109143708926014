<template>
  <div>
    <div class="top EC_width display_between">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-show="listType==0"
                            :to="{ path: '/allCourse', query: { restype: restype, ressort: ressort,resindex:resindex } }">全部课程</el-breadcrumb-item>
        <el-breadcrumb-item v-show="listType!=0"
                            :to="{ path: '/list', query: { type:listType,restype: restype, ressort: ressort,resindex:resindex } }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tright display_center_ud">
        <el-popover placement="bottom"
                    width="170"
                    popper-class="fx"
                    trigger="click">
          <div class="fxbox display_column_start_center">
            <span>微信扫一扫分享</span>
            <div class="qrcbox"
                 style="width: 90px; height: 90px; margin-top: 10px">
              <div id="qrc"
                   ref="qrcodeImg"></div>
            </div>
          </div>
          <div class="trfx display_center_ud"
               slot="reference">
            <img src="../../assets/icons/fenXiang.png"
                 alt="" />
            <span>分享</span>
          </div>
        </el-popover>
        <div class="trsc display_center_ud"
             v-show="!isShoucang"
             @click="tosc">
          <img src="../../assets/icons/shouCang.png"
               alt="" />
          <span>收藏</span>
        </div>
        <div class="trsc display_center_ud"
             v-show="isShoucang"
             @click="toquxiao">
          <img src="../../assets/icons/shouCang.png"
               alt="" />
          <span>取消收藏</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
export default {
  data () {
    return {
      cid: 0,
      uid: 0,
      qrc: '',
      restype: 0,
      ressort: 1,
      resindex: 1,
      listType: 0
    };
  },
  props: {
    isShoucang: {
      type: Boolean,
      default: false,
    },
    toShoucang: {
      type: Function,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
  },
  watch: {
    $route: {
      handler () {
        if (this.cid != this.$route.query.courseId) {
          this.cid = Number(this.$route.query.courseId);
          if (this.cid == 0) {
            this.cid = Number(this.$route.query.cidIng);
          }
          if (this.qrc != '') {
            this.qrc = '';
            document.getElementById('qrc').innerHTML = '';
            this.getFenXiangCode();
          }
        }
      },
      deep: true,
    },
  },
  created () {
    if (this.$route.query.restype) {
      this.restype = Number(this.$route.query.restype);
    }
    if (this.$route.query.ressort || this.$route.query.ressort == 0) {
      this.ressort = Number(this.$route.query.ressort);
    }
    if (this.$route.query.resindex) {
      this.resindex = Number(this.$route.query.resindex);
    }
    if (this.$route.query.listType) {
      this.listType = Number(this.$route.query.listType);
    }
    if (this.$route.query.courseId) {
      this.cid = Number(this.$route.query.courseId);
      if (this.cid == 0) {
        this.cid = Number(this.$route.query.cidIng);
      }
    }
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
    }
  },
  mounted () {
    this.getFenXiangCode();
  },
  methods: {
    getFenXiangCode () {
      this.qrc = new QRCode(document.getElementById('qrc'), {
        width: 90,
        height: 90,
        text: window.location.href, // 二维码地址
        colorDark: '#000',
        colorLight: '#fff',
      });
    },
    tosc () {
      if (this.uid == 0) {
        this.$message({
          message: '请登录',
          type: 'warning',
        });
        return;
      }
      this.httphelper
        .post('CGPInfoApi/AddGpFavorites', {
          courseId: this.cid,
          userId: this.uid,
        })
        .then(res => {
          if (res.code == 200) {
            this.toShoucang();
            this.$message.success('收藏成功');
          }
        });
    },
    toquxiao () {
      this.httphelper
        .post('CGPInfoApi/DeleteGpFavorites', {
          courseId: this.cid,
          userId: this.uid,
        })
        .then(res => {
          if (res.code == 200) {
            this.toShoucang();
            this.$message('取消收藏成功');
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
    height: 53px;
    .tright {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        img {
            margin-right: 10px;
        }
        .trfx {
            cursor: pointer;
        }
        .trsc {
            cursor: pointer;
            margin-left: 30px;
        }
        .fx {
            height: 300px;
            .qrcbox {
                width: 150px;
                height: 150px;
                background-color: #fff;
            }
        }
    }
}
</style>
