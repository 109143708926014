<template>
  <div class="top">
    <el-carousel :interval="5000"
                 arrow="never">
      <el-carousel-item v-for="(item,index) in Bannerswip"
                        class="text_center"
                        :key="index">
        <img :src="item.resUrl"
             @click="tourl(item.url)"
             alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Bannerswip: []
    }
  },
  props: {
    toRecordMp3: {
      type: Function,
      default: null
    },
    isMp3IngId: {
      type: Number,
      default: 0
    },
  },
  created () {
    this.getBannerswip()
  },
  methods: {
    getBannerswip () {
      this.httphelper
        .post('WebSetMessApi/GetRotationInfo', {
          type: 1,
          ruselocation: 1,
          source: 1
        })
        .then(res => {
          if (res.code == 200) {
            this.Bannerswip = res.data;
          }
        })
    },
    tourl (url) {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      window.open(url, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
.top {
    height: 450px;
    ::v-deep .el-carousel {
        height: 450px;
    }
    ::v-deep .el-carousel__container {
        height: 450px;
        position: relative;
        img {
            height: 450px;
            width: 1920px;
            object-fit: cover;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
        }
    }
    ::v-deep .el-carousel__button {
        width: 10px;
        height: 10px;
        background: #6c9ca3;
        border-radius: 50%;
    }
    ::v-deep .is-active .el-carousel__button {
        width: 40px;
        height: 10px;
        background: #ffffff;
        border-radius: 5px;
    }
}
</style>