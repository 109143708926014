<template>
  <div class="mbox">
    <div id="mp4"
         ref="mp4"
         v-show="player != null && !isTip"></div>
    <div class="fengmian display_center"
         v-show="player == null && !isTip"
         v-if="courseInfo != null"
         :style="'background-image: url(' + courseInfo.acr_PictResUrl + ');'">
      <img src="../../../assets/icons/bofang8.png"
           @click="getMp4"
           alt="" />
    </div>
    <div class="tipBg display_center"
         v-show="isTip"
         style="background: #15161b">
      <span>{{ tiptext }}</span>
    </div>
    <div class="m2box">
      <div class="m2top display_between_nocenter"
           v-if="info != null">
        <div class="m2title">{{ courseInfo.title }}</div>
        <div class="m2bq2"
             v-if="info.price>info.discount&&info.discountStatus == 3">
          <span>特惠</span>
        </div>
        <div class="m2bq">
          <span>{{ info.res_Type == 2 ? '视频' : info.res_Type == 3 ? '音频' : '电子书' }}</span>
        </div>
      </div>
      <div class="m2mid display_column"
           v-if="info != null">
        <span>讲师：{{ info.expertName ? info.expertName : info.expertlist[0].name }}</span>
        <span>单位：{{ info.unit ? info.unit : info.expertlist[0].unit }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      uid: 0,
    };
  },
  props: {
    courseInfo: {
      type: Object,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    getMp4: {
      type: Function,
      default: null,
    },
    toRecordMp4: {
      type: Function,
      default: null,
    },
    player: {
      type: Object,
      default: null,
    },
    isTip: {
      type: Boolean,
      default: false,
    },
    tiptext: {
      type: String,
      default: '',
    },
  },
  watch: {
    player (e) {
      if (e == null) {
        this.$refs.mp4.innerHTML = '';
      }
    },
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
    }
  },
  mounted () { },
  methods: {},
};
</script>
<style lang="scss" scoped>
.mbox {
    width: 100%;
    // min-height: 210px;
    .fengmian {
        width: 100%;
        min-height: 210px;
        background-size: 100% 100%;
        img {
            width: 50px;
            height: 50px;
        }
    }
    .tipBg {
        min-height: 210px;
        color: #fff;
    }
}
.m2box {
    padding: 7px 17px 3px;
    margin-top: -5px;
    .m2top {
        padding: 15px 0;
        .m2title {
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
            flex: 1;
        }
        .m2bq {
            height: 18px;
            background: #ffffff;
            border: 1px solid #e60012;
            border-radius: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e60012;
            line-height: 17px;
            padding: 0 5px;
            margin-left: 5px;
            margin-top: 2px;
        }
        .m2bq2 {
            height: 18px;
            background: #e60012;
            border-radius: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fff;
            line-height: 17px;
            padding: 0 5px;
            margin-left: 5px;
            margin-top: 2px;
        }
    }
    .m2mid {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #626262;
        line-height: 21px;
    }
}
</style>
