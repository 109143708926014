<template>
  <div>
    <div class="top2 EC_width display_flex">
      <div class="t2box1 display_flex">
        <img src="../../assets/images/home/zhuanjia.png"
             class="t2img"
             alt="">
        <div class="t2span display_column_between">
          <div class="t2st1">专家知家</div>
          <div class="t2st2">以知促行 以行求知</br>专家邀您一起优质高效学习</div>
          <div class="t2st3"
               @click="toExpert">查看更多</div>
        </div>
      </div>
      <div class="t2box2 display_flex">
        <img src="../../assets/images/home/jigou.png"
             class="t2img"
             alt="">
        <div class="t2span display_column_between">
          <div class="t2st1"
               style="color: #0B8586;">机构知家</div>
          <div class="t2st2">特色模式 典型案例</br>广大社区卫生服务机构共建共享精彩</div>
          <div class="t2st3"
               @click="toOrgan">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toRecordMp3: {
      type: Function,
      default: null
    },
    isMp3IngId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    toExpert () {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      window.open('https://gp519.com/experts', '_blank');
    },
    toOrgan () {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      window.open('https://gp519.com/organization', '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
.top2 {
    height: 340px;
    background: #f7f7f7;
    padding: 45px 0;
    .t2box1 {
        width: 585px;
        height: 250px;
        background: #e9ddd2;
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
    }
    .t2box2 {
        width: 585px;
        height: 250px;
        background: #d2e9e8;
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        margin-left: 30px;
    }
    .t2img {
        width: 400px;
        height: 250px;
    }
    .t2span {
        padding: 33px 25px;
        flex: 1;
        .t2st1 {
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ae0002;
            line-height: 54px;
        }
        .t2st2 {
            height: 96px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
        }
        .t2st3 {
            width: 100px;
            height: 34px;
            background: #ffffff;
            border-radius: 17px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #404040;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>