<template>
  <div style="overflow: hidden;">
    <div class="box display_flex">
      <div class="left">
        <el-carousel :interval="5000"
                     @change="change"
                     arrow="never">
          <el-carousel-item v-for="(item,index) in list"
                            class="text_center"
                            :key="index">
            <img :src="item.resUrl"
                 alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right"
           v-if="list[index]">
        <div class="rtop">{{list[index].title}}</div>
        <div class="rmid">{{list[index].detail}}</div>
        <div class="rbot"
             @click="Tomenuhomswipe(list[index].url)">
          <span>查看详情</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: [],
      index: 0
    }
  },
  created () {
    this.getList()
  },
  props: {
    toRecordMp3: {
      type: Function,
      default: null
    },
    isMp3IngId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    getList () {
      this.httphelper
        .post('WebSetMessApi/GetRotationInfo', {
          type: 2
        })
        .then(res => {
          if (res.code == 200) {
            this.list = res.data;
          }
        })
    },
    change (e) {
      this.index = e
    },
    Tomenuhomswipe (url) {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      window.open(url, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
    height: 319px;
    width: 100%;
    margin-top: 20px;
    .left {
        width: 553px;
        height: 319px;
        ::v-deep .el-carousel__item {
            img {
                width: 553px;
                height: 319px;
            }
        }
        ::v-deep .el-carousel {
            height: 319px;
        }
        ::v-deep .el-carousel__container {
            height: 319px;
        }
        ::v-deep .el-carousel__indicators--horizontal {
            right: 17px;
            left: auto;
            transform: translateX(0);
        }
        ::v-deep .el-carousel__button {
            width: 10px;
            height: 10px;
            background: #6c9ca3;
            border-radius: 50%;
        }
        ::v-deep .is-active .el-carousel__button {
            width: 40px;
            height: 10px;
            background: #ffffff;
            border-radius: 5px;
        }
    }
    .right {
        margin-left: 48px;
        width: 599px;
        .rtop {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 60px;
        }
        .rmid {
            height: 200px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #404040;
            line-height: 33px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
            margin-bottom: 19px;
        }
        .rbot {
            width: 144px;
            height: 40px;
            background: #0b8586;
            border-radius: 20px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
            cursor: pointer;
        }
    }
}
</style>