<template>
  <div>
    <div class="boxf">
      <div class="box EC_width">
        <div class="left display_center_ud">
          <img src="../../assets/images/header/logo.png" class="logo" @click="tohome" alt="" />
          <div class="nav">
            <a href="https://gp519.com" target="_blank">
              <span>网站首页</span>
            </a>
            <router-link to="/home">
              <span
                :style="$route.name == 'home' || $route.name == 'list' ? 'border-bottom: 2px solid #0b8586;color:#0b8586;font-weight:800' : ''">全科知家</span>
            </router-link>
            <router-link to="/allCourse">
              <span :style="$route.name == 'allCourse' || $route.name == 'detailVideo' || $route.name == 'detailAudio' || $route.name == 'detailEbook'
                ? 'border-bottom: 2px solid #0b8586;color:#0b8586;font-weight:800'
                : ''">全部课程</span>
            </router-link>
            <a href="https://tcm.cgphome.com/" target="_blank">
              <span style="font-weight: bold; font-size: 24px;">中医技能提升</span>
            </a>
            <!-- <router-link to="">
              <span @click="toPage">秒知医技</span>
            </router-link>
            <router-link to="">
              <span @click="toPage">华佗视频</span>
            </router-link> -->
            <!-- <router-link to="">
              <span @click="toPage">每周会诊</span>
            </router-link> -->
            <router-link to="">
              <span @click="toCme">CME课程</span>
            </router-link>
          </div>
          <div class="input">
            <input type="text" v-model="keyword" placeholder="输入关键词" v-on:keyup.enter="toSearch" />
            <img src="../../assets/images/header/search.png" class="search" @click="toSearch" alt="" />
          </div>
        </div>
        <div class="right display_center_ud">
          <div class="login display_center_ud" v-show="info == ''">
            <img src="../../assets/images/header/people.png" alt="" />
            <span @click="toLogin">登录</span>
            <div></div>
            <span @click="toReg">注册</span>
          </div>
          <div class="msg display_center_ud" v-show="info != ''">
            <a href="https://gp519.com/user/userMymsg" target="_blank" style="position: relative">
              <img src="../../assets/icons/msg.png" style="cursor: pointer" alt="" />
              <span v-show="msgNum != 0" class="msgnum">{{ msgNum }}</span>
            </a>
            <a href="https://gp519.com/user/userShopping" target="_blank" style="position: relative;margin-left:12px">
              <img src="../../assets/icons/car2.png" style="cursor: pointer;width:19px" alt="" />
              <span v-show="false" class="msgnum">{{ msgNum }}</span>
            </a>
            <div class="name display_center_ud" style="margin-left: 23px" @click.capture="taggleinfor = true">
              <img :src="info.avatarUrl" class="touxiang" alt="" />
              <img src="../../assets/images/header/s.png" class="huangguan" v-show="userType == 1" alt="" />
              <img src="../../assets/images/header/v.png" class="huangguan" v-show="userType == 2" alt="" />
              <span>{{ info.nickname }}</span>
            </div>
            <div class="opra" v-show="taggleinfor" @click.stop>
              <div class="text_right" style="position: relative">
                <img src="../../assets/icons/sanjiao3.png" alt="" class="ArrowTop" />
              </div>

              <div class="display_flex">
                <!-- <img src="/image/index/stateHead.png" /> -->
                <a href="https://gp519.com/user/userInfor" target="_blank">
                  <img :src="info.avatarUrl" class="img-border" />
                </a>
                <div class="flex1 marginLeft10">
                  <p class="marginTop10 marginBottom10">
                    {{ info.nickname != '' ? info.nickname : info.mobile }}
                    <a href="https://gp519.com/user/userMyInforShow" target="_blank">
                      <span class="informy" style="cursor: pointer"> 个人资料 </span>
                    </a>
                    <!-- </a> -->
                  </p>
                  <p>
                    <img src="../../assets/icons/phoneIcon.png" alt="" />
                    {{ info.mobile }}
                  </p>
                </div>
                <div>
                  <span class="out" @click="out">退出</span>
                </div>
              </div>
              <div class="vip display_between marginTop20">
                <div v-if="userType == 0" class="display_center_ud">
                  <img src="../../assets/images/header/svip.png" style="width:38px" alt="" />
                  <span class="marginLeft10 redColor">全站通-198元</span>
                  <span>/年</span>
                </div>
                <div v-else class="display_center_ud">
                  <img style="width:38px" src="../../assets/images/header/svip.png" alt="" />
                  <!-- <img v-show="userType==2"
                       style="width:38px"
                       src="../../assets/images/header/vip.png"
                       alt="" /> -->
                  <span class="marginLeft10 redColor" style="font-size: 13px">{{ userType == 1 ? 'SVIP' : 'VIP' }}到期时间：{{
                    info.memberDate.m_EndDate.substr(0, 10) }}</span>
                  <!-- <span>/年</span> -->
                </div>
                <div class="kaitong" style="cursor: pointer">
                  <span v-if="userType == 0">
                    <a href="https://gp519.com/user/userVip" target="_blank"> 立即开通 </a>
                  </span>
                  <span v-else-if="userType == 1" style="cursor: pointer">
                    <a href="https://gp519.com/user/userVip" target="_blank"> 立即续费 </a>
                  </span>
                  <span v-else-if="userType == 2" style="cursor: pointer">
                    <a href="https://gp519.com/user/userVip" target="_blank"> 立即升级 </a>
                  </span>
                </div>
              </div>
              <div class="display_between marginTop20">
                <div class="display_column display_center" style="cursor: pointer">
                  <a href="https://gp519.com/user/userMyOrder" target="_blank"
                    style="display: flex; flex-direction: column; align-items: center">
                    <img src="../../assets/images/header/menu01.png" alt="" />
                    <div class="marginTop10" style="color: black; fontsize: 16px">我的订单</div>
                  </a>
                </div>
                <div class="display_column display_center" style="cursor: pointer">
                  <a href="https://gp519.com/user/userMyCourse" target="_blank"
                    style="display: flex; flex-direction: column; align-items: center">
                    <img src="../../assets/images/header/menu02.png" alt="" />
                  </a>
                  <div class="marginTop10" style="color: black; fontsize: 16px">我的课程</div>
                </div>
                <div class="display_column display_center" style="cursor: pointer">
                  <a href="https://gp519.com/user/userMyCollection" target="_blank"
                    style="display: flex; flex-direction: column; align-items: center">
                    <img src="../../assets/images/header/menu03.png" alt="" />
                    <div class="marginTop10" style="color: black; fontsize: 16px">我的收藏</div>
                  </a>
                </div>
                <div class="display_column display_center" style="cursor: pointer">
                  <a href="https://gp519.com/user/userVip" target="_blank"
                    style="display: flex; flex-direction: column; align-items: center">
                    <img src="../../assets/images/header/menu04.png" alt="" />
                    <div class="marginTop10" style="color: black; fontsize: 16px">VIP中心</div>
                  </a>
                </div>
                <div class="display_column display_center" style="cursor: pointer">
                  <a href="https://gp519.com/user/userMyMeeting" target="_blank"
                    style="display: flex; flex-direction: column; align-items: center">
                    <img src="../../assets/images/header/menu05.png" alt="" />
                    <div class="marginTop10" style="color: black; fontsize: 16px">我的会议</div>
                  </a>
                </div>
              </div>
            </div>
            <div class="modal" v-if="taggleinfor" @click="taggleinfor = false"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'headerIndex',
  data() {
    return {
      info: '',
      taggleinfor: false,
      msgNum: 0,
      keyword: '',
      userType: 0,
      tokenstatus: true,
      //   baseUrl: 'https://gp519.com/',
      baseUrl: 'https://new.gp519.com/',
      //   baseUrl: 'http://localhost:8081/'
    };
  },
  watch: {},
  created() {
    let basApi = axios.defaults.baseURL
    if (basApi == 'https://api.gp519.com/api/') this.baseUrl = 'https://gp519.com/'
    this.getLogin();
  },
  methods: {
    out() {
      if (localStorage.getItem('token_newgphome') && localStorage.getItem('token_newgphome') != null) {
        this.httphelper
          .post('LoginApi/ClearToken', {
            tokenKey: localStorage.getItem('token_newgphome'),
          })
          .then(res => {
            if (res.code == 200) {
              localStorage.clear();
              this.taggleinfor = false;
              this.info = '';
              this.$router.go(0);
            } else {
              localStorage.clear();
              this.taggleinfor = false;
              this.info = '';
              this.$router.go(0);
            }
          });
      } else {
        localStorage.clear();
        this.taggleinfor = false;
        this.info = '';
        let curl = this.delUrlData('token');
        window.location.href = curl;
      }

    },
    toLogin() {
      let callback = encodeURIComponent(this.delUrlData('token'));
      window.location.href = this.baseUrl + 'loginIndex/login?callback=' + callback;
    },
    toReg() {
      let callback = encodeURIComponent(this.delUrlData('token'));
      window.location.href = this.baseUrl + 'loginIndex/loginRegister?callback=' + callback;
    },
    getUnreadmsg() {
      this.httphelper
        .post('MessageApi/GetNewMessageCount', {
          userId: this.info.id,
        })
        .then(res => {
          this.msgNum = res.data;
        });
    },
    toSearch() {
      if (this.$route.path == '/allCourse') {
        if (this.$route.query.keyword != this.keyword) {
          this.$router.push({ query: { keyword: this.keyword } });
        }
      } else {
        this.$router.push({
          path: '/allCourse',
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
    getLogin() {
      if (localStorage.getItem('ResData')) {
        if (JSON.parse(localStorage.getItem('ResData')).id) {
          this.tokenstatus = false;
          this.getUserInfo(JSON.parse(localStorage.getItem('ResData')).id, 0);
        } else {
          localStorage.clear();
          let curl = this.delUrlData('token');
          window.location.href = curl;
          this.info = ''
        }
      }
      var _tokenstatus = this.$route.query.token;
      //有登录信息、url地址有token且为false
      if (_tokenstatus == 'false') {
        this.tokenstatus = false;
        //无登录信息、url地址有token且不为false
      } else if (_tokenstatus != null && _tokenstatus != 'false') {
        localStorage.setItem('token_newgphome', _tokenstatus);
        this.httphelper
          .post('LoginApi/CheckToken', {
            tokenKey: _tokenstatus,
          })
          .then(res => {
            if (res.code == 200) {
              this.getUserInfo(res.data.id, 1);
            } else {
              localStorage.clear();
              let curl = this.delUrlData('token');
              window.location.href = curl;
            }
          });
      } else if (this.$route.query.openid || this.$route.query.userid) {
        this.httphelper
          .post('LoginApi/GetUserInfoByOpenid', {
            openid: this.$route.query.openid || '',
            userid: Number(this.$route.query.userid) || 0
          })
          .then(res => {
            if (res.code == 200) {
              localStorage.setItem('token_newgphome', res.data.token);
              this.getUserInfo(res.data.id, 2);
            } else {
              localStorage.clear();
              if (this.$route.query.userid) {
                let curl = this.delUrlData('userid');
                window.location.href = curl;
              } else if (this.$route.query.openid) {
                let curl = this.delUrlData('openid');
                window.location.href = curl;
              }
            }
          });
        //无登录信息、url地址无token、本地有token
      } else if (localStorage.getItem('token_newgphome') && localStorage.getItem('token_newgphome') != null) {
        this.httphelper
          .post('LoginApi/CheckToken', {
            tokenKey: localStorage.getItem('token_newgphome'),
          })
          .then(res => {
            if (res.code == 200) {
              this.getUserInfo(res.data.id, 0);
            } else {
              localStorage.clear();
              let curl = this.delUrlData('token');
              window.location.href = curl;
            }
          });
      } else if (this.tokenstatus) {
        //无登录信息、本地无token
        if (!localStorage.getItem('token_newgphome') || localStorage.getItem('token_newgphome') == null) {
          var callback = encodeURIComponent(window.location.href);
          window.location.href = this.baseUrl + 'loginIndex/ssologin?callback=' + callback;
          //无登录信息、本地有token
        } else {
          this.httphelper
            .post('LoginApi/CheckToken', {
              tokenKey: localStorage.getItem('token_newgphome'),
            })
            .then(res => {
              if (res.code == 200) {
                this.getUserInfo(res.data.id, 0);
              } else {
                localStorage.clear();
                var callback = encodeURIComponent(window.location.href);
                window.location.href = this.baseUrl + 'loginIndex/ssologin?callback=' + callback;
              }
            });
        }
      }
    },
    toPage() {
      this.$alert('建设中...', '提示', {
        confirmButtonText: '确定',
      });
    },
    tohome() {
      this.$router.push('/home');
    },

    /**获取用户信息 */
    getUserInfo(authorId, removetoke) {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: parseInt(authorId),
        })
        .then(res => {
          if (res.code == 200) {
            res.data.id = authorId;
            localStorage.setItem('ResData', JSON.stringify(res.data));
            var aData = new Date();
            var CurrentDategetTime = 0
            var VipEdDategetTime = 0
            if (res.data.memberDate.m_EndDate) {
              var CurrentDate = aData.getFullYear() + '-' + (aData.getMonth() + 1) + '-' + aData.getDate() + ' ' + aData.getHours() + ':' + aData.getMinutes() + ':' + aData.getSeconds();
              var VipEdDate = res.data.memberDate.m_EndDate;
              CurrentDategetTime = new Date(CurrentDate.replace(/-/g, '/')).getTime() / 1000;
              VipEdDategetTime = new Date(VipEdDate.replace(/-/g, '/')).getTime() / 1000;
            }
            if (res.data.memberDate.m_EndDate == null || parseInt(CurrentDategetTime) - parseInt(VipEdDategetTime) >= 0) {
              this.userType = 0;
            } else {
              if (res.data.memberDate.m_Type == 1) {
                this.userType = 1;
              } else if (res.data.memberDate.m_Type == 2) {
                this.userType = 2;
              } else {
                this.userType = 0;
              }
            }
            localStorage.setItem('userType', this.userType);
            this.info = res.data;
            this.getUnreadmsg();
            if (removetoke == 1) {
              let curl = this.delUrlData('token');
              window.location.href = curl;
            } else if (removetoke == 2) {
              if (this.$route.query.userid) {
                let curl = this.delUrlData('userid');
                window.location.href = curl;
              } else if (this.$route.query.openid) {
                let curl = this.delUrlData('openid');
                window.location.href = curl;
              }
            }
          }
        });
    },
    toCme() {
      if (localStorage.getItem('token_newgphome')) {
        let authorId = parseInt(JSON.parse(localStorage.getItem('ResData')).id)
        this.httphelper
          .post('CGpUserApi/GetUserMessage', {
            id: authorId,
          })
          .then(res => {
            if (res.code == 200) {
              res.data.id = authorId;
              let info = res.data
              localStorage.setItem('ResData', JSON.stringify(info));
              if (info.userName
                && info.mobile
                && info.unit
                && info.iDcard
                && info.province
                && info.userName != null
                && info.mobile != null
                && info.unit != null
                && info.iDcard != null
                && info.province != null) {
                window.open('https://www.jumingedu.com/qkyx?token=' + localStorage.getItem('token_newgphome'), "_blank")
              } else {
                this.$alert('请到我的资料中补全姓名、身份证号、单位和所在地区等信息！', '系统提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (action == 'confirm') {
                      window.location.href = this.baseUrl + 'user/userData?type=1'
                    }
                  }
                });
              }
            }
          });
      } else {
        // window.open('https://www.jumingedu.com/qkyx', "_blank")
        this.$alert('请先登录！', '系统提示', {
          confirmButtonText: '去登陆',
          callback: action => {
            if (action == 'confirm') {
              localStorage.clear();
              let callback = encodeURIComponent(this.delUrlData('token'));
              window.location.href = this.baseUrl + 'loginIndex/login?callback=' + callback;
            }
          }
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.boxf {
  height: 77px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.13);
  position: relative;
  z-index: 100;

  .box {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .left {
      height: 100%;
    }

    .logo {
      width: 185px;
      height: 56px;
      cursor: pointer;
    }

    .nav {
      margin-left: 40px;

      // width: 554px;
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        // font-weight: bold;
        color: #000000;
        padding: 24px 0px;
        // margin: 0 15px;
        margin: 0 15px;
        // border-bottom: 2px solid #fff;
      }
    }

    .input {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      input {
        width: 160px;
        height: 30px;
        background: #e9e9e9;
        border-radius: 15px;
        border: none;
        outline: none;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        padding-left: 15px;
        padding-right: 40px;
        box-sizing: border-box;
        margin-left: 20px;
      }

      .search {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 30px;
        left: 155px;
        cursor: pointer;
      }
    }

    .right {
      height: 100%;
      display: flex;
      align-items: center;

      .login {
        display: flex;
        align-items: center;

        img {
          width: 13px;
          height: 15px;
          margin-right: 18px;
        }

        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          cursor: pointer;
        }

        div {
          height: 16px;
          width: 1px;
          margin: 0 15px;
          background-color: #eee;
        }
      }

      .msg {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
        padding-left: 20px;

        .name {
          max-width: 83px;
          position: relative;

          // overflow: hidden;
          span {
            min-width: 54px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          cursor: pointer;
        }

        .touxiang {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .huangguan {
          position: absolute;
          top: -5px;
          left: 16px;
          width: 13px;
          height: 13px;
          transform: rotate(35deg);
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}

.opra {
  padding: 25px;
  display: inline-block;
  position: absolute;
  background: #fff;
  width: 400px;
  box-sizing: border-box;
  right: 0px;
  top: 70px;
  box-shadow: 0px 5px 15px 0px rgba(6, 50, 52, 0.2);
  border-radius: 3px;
  z-index: 10000;
}

.out {
  padding: 2px 8px;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  cursor: pointer;
}

.informy {
  width: 77px;
  height: 25px;
  background: #0b8586;
  border-radius: 13px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  color: #fff;
  margin-left: 5px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.vip {
  height: 50px;
  background: url('../../assets/images/header/gerenbar.png');
  padding: 0 6px;
}

.kaitong {
  width: 96px;
  height: 29px;
  background: linear-gradient(90deg, #ff1e00, #ff6600);
  border-radius: 14px;
  text-align: center;
  line-height: 29px;
  color: #fff;

  a {
    color: #fff;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}

.ArrowTop {
  width: 20px;
  height: 18px;
  position: absolute;
  top: -37px;
  right: 10px;
  background-color: #fff;
}

.img-border {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  width: 60px;
  height: 60px;
}

.msgnum {
  position: absolute;
  top: -9px;
  left: 9px;
  background-color: red;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  padding: 1px 3px 2px;
}
</style>
<style>
.el-message-box__wrapper .el-button {
  background-color: #0b8586 !important;
  border-color: #0b8586 !important;
  color: #fff;
}
</style>