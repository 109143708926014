<template>
  <div>
    <div v-if="!isMobile()">
      <div class="topf">
        <div class="top EC_width">
          <div class="titem display_flex"
               v-for="(item, index) in CourseListSel"
               :style="csList.length < 1 && CourseListSel.length == index + 1 ? 'border-bottom:none' : ''"
               :key="index">
            <span class="ttitle">{{ item.name }}：</span>
            <div class="titemr display_column">
              <div class="titemrt display_flex">
                <div class="tbiaoqian display_center_ud"
                     v-for="(items, indexs) in item.queryCoursesInfoList"
                     @click="toBq(items)"
                     :style="getIsCs(items.id)? 'border: 1px solid #0B8586;background: #EAF1F2;line-height: 22px;color: #0B8586': bqIng == items
                                            ? 'background: #0B8586;color:#fff'
                                            : getIsCs2(items)
                                            ? 'color:#0B8586;'
                                            : ''
                                    "
                     :key="indexs + 's'">
                  <span>{{ items.name }}</span>
                  <img src="../../assets/icons/x.png"
                       v-show="items.queryCoursesInfoList == null && getIsCs(items.id)"
                       alt="" />
                  <img src="../../assets/icons/top1.png"
                       v-show="items.queryCoursesInfoList != null && bqIng == items"
                       alt="" />
                  <img src="../../assets/icons/bottom1.png"
                       v-show="items.queryCoursesInfoList != null && bqIng != items && !getIsCs2(items)"
                       alt="" />
                  <img src="../../assets/icons/bottom2.png"
                       v-show="items.queryCoursesInfoList != null && bqIng != items && getIsCs2(items)"
                       alt="" />
                </div>
              </div>
              <div class="titemrb display_flex"
                   v-show="bqIng.pId == item.id">
                <div class="tbiaoqian display_center_ud"
                     v-for="(items, indexs) in CoursesListSel"
                     @click="toBq2(items)"
                     :style="getIsCs(items.id) ? 'border: 1px solid #0B8586;line-height: 22px;background: #EAF1F2;color: #0B8586' : ''"
                     :key="indexs + 's'">
                  <span>{{ items.name }}</span>
                  <img src="../../assets/icons/x.png"
                       v-show="getIsCs(items.id)"
                       alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="titem display_flex"
               style="border-bottom: none"
               v-show="csList.length > 0">
            <span class="ttitle"
                  style="color: #6e6f6f">检索结果：</span>
            <div class="titemr display_column">
              <div class="titemrt display_flex">
                <div class="tbiaoqian display_center_ud"
                     v-for="(item, index) in csList"
                     @click="toBq3(item)"
                     style="color: #0b8586"
                     :key="index">
                  <span>{{ getBqName(item) }}</span>
                  <img src="../../assets/icons/x.png"
                       alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CourseList :csStr="csStr"></CourseList>
    </div>
    <div v-else>
      <Mall :CourseListSel="CourseListSel"></Mall>
    </div>
  </div>
</template>

<script>
import CourseList from '../../components/course/courseList.vue';
import Mall from '../../components/mobile/course/all.vue';
export default {
  components: {
    CourseList,
    Mall,
  },
  data () {
    return {
      cid: 0,
      uid: 0,
      CourseListSel: [],
      csList: [],
      CoursesListSel: [],
      bqIng: '',
      csStr: '',
      bqtype: 1,
    };
  },
  watch: {
    csList: function (e) {
      this.csStr = e.join(',');
    },
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.getCourseListSel();
  },
  mounted () { },
  methods: {
    getCourseListSel () {
      this.httphelper.get('CGPInfoApi/GetGpLabelInfo').then(res => {
        this.CourseListSel = res.data;
        if (this.$route.query.bqStr) {
          let name = decodeURIComponent(this.$route.query.bqStr);
          this.getBqId(name);
        }
      });
    },
    getIsCs (id) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == id) return true;
      }
      return false;
    },
    // toBq (item) {
    //   if (item.queryCoursesInfoList == null) {
    //     for (let i = 0; i < this.csList.length; i++) {
    //       if (this.csList[i] == item.id) {
    //         this.csList.splice(i, 1)
    //         return
    //       }
    //     }
    //     this.csList.push(item.id)
    //   } else {
    //     if (this.bqIng == item) this.bqIng = ''
    //     else {
    //       this.bqIng = item
    //       this.CoursesListSel = item.queryCoursesInfoList
    //     }
    //   }
    // },
    toBq (item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == item.id) {
          this.csList.splice(i, 1);
          return;
        }
      }
      this.csList.push(item.id);
      if (item.queryCoursesInfoList != null) {
        if (this.bqIng == item) this.bqIng = '';
        else {
          this.bqIng = item;
          this.CoursesListSel = item.queryCoursesInfoList;
        }
      }
    },
    toBq2 (item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == this.bqIng.id) {
          this.csList.splice(i, 1);
        }
      }
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == item.id) {
          this.csList.splice(i, 1);
          return;
        }
      }
      this.csList.push(item.id);
    },
    toBq3 (id) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == id) {
          this.csList.splice(i, 1);
          return;
        }
      }
    },
    getIsCs2 (item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (item.queryCoursesInfoList != null) {
          for (let a = 0; a < item.queryCoursesInfoList.length; a++) {
            if (this.csList[i] == item.queryCoursesInfoList[a].id) return true;
          }
        }
      }
      return false;
    },
    getBqName (id) {
      if (this.bqtype == 2) {
        if (this.$route.query.bqStr) {
          return decodeURIComponent(this.$route.query.bqStr);
        } else {
          return '';
        }
      } else {
        for (let a = 0; a < this.CourseListSel.length; a++) {
          for (let b = 0; b < this.CourseListSel[a].queryCoursesInfoList.length; b++) {
            if (this.CourseListSel[a].queryCoursesInfoList[b].id == id) {
              return this.CourseListSel[a].queryCoursesInfoList[b].name;
            }
            if (this.CourseListSel[a].queryCoursesInfoList[b].queryCoursesInfoList != null) {
              for (let c = 0; c < this.CourseListSel[a].queryCoursesInfoList[b].queryCoursesInfoList.length; c++) {
                if (this.CourseListSel[a].queryCoursesInfoList[b].queryCoursesInfoList[c].id == id) {
                  return this.CourseListSel[a].queryCoursesInfoList[b].queryCoursesInfoList[c].name;
                }
              }
            }
          }
        }
      }
    },
    getBqId (name) {
      this.httphelper
        .get('CourseApi/GetLabelNameById', {
          Name: name,
        })
        .then(res => {
          if (res.code == 200) {
            this.bqtype = 2;
            this.csList.push(res.data);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.topf {
    width: 100%;
    background: #f3f5f6;
    padding: 30px 0 92px;
    .titem {
        padding: 11px 0;
        border-bottom: 1px solid rgb(226, 226, 226);
        .ttitle {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 24px;
            padding: 4px 0;
        }
        .titemr {
            flex: 1;
            .titemrt {
                flex-wrap: wrap;
            }
            .titemrb {
                padding: 10px;
                background: #ffffff;
                border: 1px solid #0b8586;
                margin: 9px 0px 3px 5px;
                flex-wrap: wrap;
            }
            .tbiaoqian {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                //   color: #0b8586;
                color: #000000;
                line-height: 24px;
                margin: 4px 5px;
                padding: 0 8px;
                cursor: pointer;
                img {
                    margin-left: 3px;
                }
            }
        }
    }
}
</style>
