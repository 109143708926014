<template>
  <div style="height: 100%">
    <div class="box"
         v-if="!isMobile()"
         :style="'background-image: url(' + courseInfo.acr_PictResUrl + ');'">
      <img src="../../assets/images/detail/yinpin.png"
           class="img"
           alt="" />
      <img src="../../assets/images/detail/bofang.png"
           class="bofang img"
           @click="toBofang"
           v-show="!isBofang"
           alt="" />
      <img src="../../assets/images/detail/bofang2.png"
           :class="isPlaying ? 'bofang2 img' : 'bofang img'"
           v-show="isBofang"
           @click="isPlaying ? pause() : play()"
           alt="" />
      <audio ref="audio"
             style="display: none"
             @pause="isPlaying = false"
             @play="isPlaying = true"
             src=""
             @timeupdate="onTimeupdate"
             controls="controls"></audio>
      <div class="jindu display_center_ud"
           v-show="isBofang">
        <img src="../../assets/icons/bofang4.png"
             v-show="isPlaying"
             class="jinduImg"
             @click="pause"
             alt="" />
        <img src="../../assets/icons/bofang5.png"
             v-show="!isPlaying"
             class="jinduImg"
             @click="play"
             alt="" />
        <p class="jinduSpan">
          <span style="color: #0b8586">{{ jindu == 0 ? '0:00' : getnowTime() }}</span>
          <span v-if="info.res_duration">/{{ info.res_duration.toFixed(2).toString().replace('.', ':') }}</span>
        </p>
        <el-slider v-model="jindu"
                   class="jindubox"
                   @input="changeJindu"
                   :show-tooltip="false"></el-slider>
      </div>
    </div>
    <div v-else>
      <Maudio :courseInfo="courseInfo == '' ? null : courseInfo"
              :info="info == '' ? null : info"
              :onTimeupdate="onTimeupdate"
              :btime="btime"
              :getnowTime="getnowTime"
              :isPlaying="isPlaying"
              :changeIsPlaying="changeIsPlaying"
              :isBofang="isBofang"
              :toBofang2="toBofang2"
              :toRecord="toRecord"
              :jindus="jindu"
              :cidIng="cidIng"></Maudio>
    </div>
  </div>
</template>

<script>
import Maudio from '../../components/mobile/detail/audio.vue';
import { Dialog } from 'vant';
export default {
  components: {
    Maudio,
  },
  data () {
    return {
      cid: 0,
      uid: 0,
      cidIng: 0,
      courseInfo: '',
      info: '',
      isBofang: false,
      isPlaying: false,
      jindu: 0,
      stime: 0,
      btime: 0,
    };
  },
  props: {},
  watch: {},
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.cid = Number(this.$route.query.courseId);
    this.cidIng = Number(this.$route.query.cidIng);
    this.getCoursedetail();
  },
  beforeRouteLeave (to, from, next) {
    this.toRecord();
    next();
  },
  mounted () {
    window.addEventListener('beforeunload', () => {
      this.toRecord();
    });
    window.addEventListener('unload', () => {
      this.toRecord();
    });
  },
  beforeDestroy () {
    this.toRecord();
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.toRecord);
    window.removeEventListener('unload', () => this.toRecord);
  },
  methods: {
    getCoursedetail () {
      this.httphelper
        .post('CourseApi/GetCourseInfoDetail', {
          userId: this.uid,
          courseId: this.cid == 0 ? this.cidIng : this.cid,
        })
        .then(res => {
          if (res.code == 200) {
            if (res.data.is_Putaway == false) {
              if (this.isMobile()) {
                Dialog.confirm({
                  title: '提示',
                  message: '该课程已下架',
                  confirmButtonText: '返回首页',
                  showCancelButton: false
                }).then(() => {
                  this.$router.push('/home');
                });
              } else {
                this.$confirm('该课程已下架', '提示', {
                  confirmButtonText: '返回首页',
                  showCancelButton: false,
                  showClose: false
                }).then(() => {
                  this.$router.push('/home');
                });
              }
              return
            }
            this.courseInfo = res.data;
            if (this.$route.query.courseId == 0) {
              this.info = res.data;
            } else {
              this.getCourseMenu();
            }
            let minute = Number(this.info.res_duration.toFixed(2).split('.')[0]) * 60;
            let second = Number(this.info.res_duration.toFixed(2).split('.')[1]);
            this.btime = minute + second;
            let fminute = Number(this.info.standingtime.toFixed(2).split('.')[0]) * 60;
            let fsecond = Number(this.info.standingtime.toFixed(2).split('.')[1]);
            this.jindu = ((fminute + fsecond) / this.btime) * 100;
          }
        });
    },
    getCourseMenu () {
      this.httphelper
        .post('CourseApi/GetCourseCataLogList', {
          courseId: this.cid,
          userId: this.uid,
        })
        .then(res => {
          if (res.data != null) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].courseId == this.cidIng) {
                this.info = res.data[i];
              }
            }
          }
        });
    },
    changeJindu (e) {
      if (Math.abs((e * this.btime) / 100 - this.$refs.audio.currentTime) > 1) {
        this.$refs.audio.currentTime = (e * this.btime) / 100;
      }
    },
    toBofang () {
      this.httphelper
        .post('/CGPInfoApi/GetCourseRes', {
          courseId: this.cidIng,
          userId: this.uid,
        })
        .then(res => {
          this.isBofang = true;
          this.$refs.audio.src = res.data;
          this.play();
        });
    },
    toBofang2 () {
      this.isBofang = true;
    },
    play () {
      this.$refs.audio.play();
    },
    pause () {
      this.$refs.audio.pause();
      this.toRecord();
    },
    onTimeupdate (res) {
      var soupttime = parseInt(res.target.currentTime / 60) + Math.ceil(res.target.currentTime % 60) / 100;
      if (this.stime != soupttime) {
        this.stime = soupttime;
        this.jindu = (res.target.currentTime / this.btime) * 100;
      }
    },
    getnowTime () {
      let m = parseInt(((this.jindu / 100) * this.btime) / 60).toString();
      let s = parseInt((this.jindu / 100) * this.btime) % 60;
      if (s < 10) return m + ':0' + s;
      else return m + ':' + s;
    },
    toRecord () {
      if (this.stime == 0 || this.uid == 0) return;
      //   this.httphelper
      //     .post('CourseClassApi/UpdCourseRecord', {
      //       courseid: this.cidIng,
      //       userId: this.uid,
      //       standingtime: this.stime,
      //     })
      //     .then(res => {
      //       if (res.code == 200) {
      //         // console.log('更新成功', this.stime);
      //       }
      //     });
      var img = document.createElement("img");
      img.src = this.httphelper
        .get('CourseClassApi/UpdCourseRecord', {
          courseid: this.cidIng,
          userId: this.uid,
          standingtime: this.stime,
        });
      document.body.appendChild(img);
      img.style.display = 'none'
    },
    changeIsPlaying (boot) {
      this.isPlaying = boot;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
    height: 100%;
    background-size: 100% 100%;
    position: relative;
    .img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .bofang {
        cursor: pointer;
    }
    .bofang2 {
        cursor: pointer;
        margin-top: -64px;
        margin-left: -64px;
        animation: xuanzhuan 10s infinite linear;
    }

    @keyframes xuanzhuan {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    .jindu {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 830px;
        height: 42px;
        background: #ffffff;
        border-radius: 20px;
        padding: 0 13px;
        .jindubox {
            width: 690px;
            ::v-deep .el-slider__bar {
                background-color: #0b8586;
                height: 4px;
            }
            ::v-deep .el-slider__button {
                width: 10px;
                height: 10px;
                border-color: #0b8586;
                top: -16px;
            }
            ::v-deep .el-slider__runway {
                height: 4px;
            }
        }
        .jinduSpan {
            margin: 0 13px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
        }
        .jinduImg {
            cursor: pointer;
        }
    }
}
</style>
