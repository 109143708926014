<template>
  <div>
    <div class="mid display_between">
      <div class="midleft display_flex">
        <div :style="midIndex == 0 ? 'background: #0B8586;color:#fff' : ''"
             @click="toType(1,0)"
             class="mlbq">全部</div>
        <div :style="midIndex == 2 ? 'background: #0B8586;color:#fff' : ''"
             @click="toType(1,2)"
             class="mlbq">视频</div>
        <div :style="midIndex == 3 ? 'background: #0B8586;color:#fff' : ''"
             @click="toType(1,3)"
             class="mlbq">音频</div>
        <div :style="midIndex == 4 ? 'background: #0B8586;color:#fff' : ''"
             @click="toType(1,4)"
             class="mlbq">课件</div>
      </div>
      <div class="midright display_flex">
        <div :style="midIndex2 == 1 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''"
             @click="toType(2,1)"
             class="mlbq2">最新</div>
        <div :style="midIndex2 == 2 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''"
             @click="toType(2,2)"
             class="mlbq2">最热</div>
        <!-- <div :style="midIndex2 == 3 ? 'color: #DF0018;border-bottom:1px solid #DF0018' : ''"
             @click="midIndex2 = 3"
             class="mlbq2">限时折扣</div> -->
      </div>
    </div>
    <div class="bot EC_width display_flex">
      <Course :info="item"
              :restype="midIndex"
              :resindex="csStr==''?pageIndex:1"
              :ressort="midIndex2"
              class="botbox"
              v-for="(item, index) in courseList"
              :style="index % 4 == 3 ? 'margin-right:0px' : ''"
              :key="index"></Course>
    </div>
    <div class="nolist"
         v-show="courseList.length==0">
      暂无课程
    </div>
    <div class="fenye">
      <pagination :total="pageCount"
                  :limit="pageSize"
                  :page="pageIndex"
                  :isNum="true"
                  @page-change="pageChange"></pagination>
    </div>
  </div>
</template>
<script>
import Course from '../../components/course/index.vue';
import pagination from '../../components/pagination/index.vue';
export default {
  data () {
    return {
      midIndex: 0,
      midIndex2: 1,
      pageCount: 0,
      pageIndex: 1,
      pageSize: 16,
      keyword: '',
      courseList: [],
    };
  },
  props: {
    csStr: {
      type: String,
      default: '',
    },
  },
  components: {
    Course,
    pagination,
  },
  watch: {
    csStr: function () {
      this.pageIndex = 1;
      this.getAllCoures();
    },
    $route: {
      handler () {
        this.keyword = this.$route.query.keyword;
        this.getAllCoures();
      },
      deep: true,
    },
  },
  created () {
    if (this.$route.query.restype) {
      this.midIndex = Number(this.$route.query.restype);
    }
    if (this.$route.query.ressort) {
      this.midIndex2 = Number(this.$route.query.ressort);
    }
    if (this.$route.query.resindex) {
      this.pageIndex = Number(this.$route.query.resindex);
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    // if (this.$route.query.type) {
    // console.log(this.$route.query.type);
    // } else {
    this.getAllCoures();
    // }
  },
  methods: {
    getAllCoures () {
      this.httphelper
        .post('CGPInfoApi/GetGpCourseVideoInfoPageLists', {
          labelgather: this.csStr,
          course_Type: 0,
          resinfoType: this.midIndex,
          orderBy: this.midIndex2,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          fuzzysearch: this.keyword,
        })
        .then(res => {
          //   let pageSun = Math.ceil(res.data.pageCount / this.pageSize)
          //   let index = localStorage.getItem('listIndex')
          //   if (index && index <= pageSun && index != this.pageIndex) {
          //     this.pageIndex = Number(index)
          //     this.getAllCoures()
          //   }
          this.courseList = res.data.gpCourseVideoInfoViews;
          this.pageCount = res.data.pageCount;
        });
    },
    pageChange (page) {
      if (page == this.pageIndex) {
        return;
      }
      this.pageIndex = page;
      //   localStorage.setItem('listIndex', page)
      this.getAllCoures();
    },
    toType (type, value) {
      if (type == 1) this.midIndex = value
      else this.midIndex2 = value
      this.pageIndex = 1;
      this.getAllCoures();
    }
  },
};
</script>
<style lang="scss" scoped>
.mid {
    width: 1290px;
    height: 85px;
    background: #ffffff;
    margin: -67px auto 0;
    padding: 33px 45px 18px;
    .mlbq {
        width: 100px;
        height: 34px;
        border: 1px solid #e5e5e5;
        border-radius: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        text-align: center;
        margin-right: 10px;
        cursor: pointer;
    }
    .mlbq2 {
        height: 36px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        padding: 10px 1px 10px 1px;
        margin-left: 40px;
        cursor: pointer;
    }
}
.bot {
    margin-top: 17px;
    flex-wrap: wrap;
    .botbox {
        margin-bottom: 25px;
        margin-right: 26px;
    }
}
.nolist {
    width: 100%;
    text-align: center;
    line-height: 100px;
}
</style>
