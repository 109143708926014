<template>
  <div>
    <div class="contentFooter">
      <div class="EC_width display_between display_center_ud height_100">
        <a v-for="(item, index) in linksRotation" :key="index + 1" :href="item.url" target="_blank">
          <img :src="item.resUrl" width="190" height="50" />
        </a>
      </div>
    </div>
    <div class="cn_globe_footer">
      <div class="cn_globe_footer_bar EC_width">
        <div class="cn_footer_logo display_center_ud">
          <img src="../../assets/images/footer/footerlogo2.png" class="logo" />
        </div>
        <div class="cn_footer_text">
          <div class="cn_footer_text_top">
            <span class="marginRight20">电话：010-83116339</span>
            <span>邮箱：chinagpweb@chinagp.net</span>
          </div>

          <div class="cn_footer_text_down">
            <span class="marginRight20">地址：北京市西城区广义街5号广益大厦A座907</span>
            <span>投诉：chinagp@vip.sina.com</span>
          </div>
        </div>
        <div class="cn_footer_icon">
          <a href="javascript:;" class="footer_icon_link">
            <img src="../../assets/images/footer/icon1.png" />
          </a>
          <a href="javascript:;" class="footer_icon_link">
            <img src="../../assets/images/footer/icon2.png" />
          </a>
          <a href="javascript:;" class="footer_icon_link">
            <img src="../../assets/images/footer/icon3.png" />
          </a>
          <a href="javascript:;" class="footer_icon_link">
            <img src="../../assets/images/footer/icon4.png" />
          </a>
          <a href="javascript:;" class="footer_icon_link">
            <img src="../../assets/images/footer/icon5.png" />
          </a>
          <a href="javascript:;" class="footer_icon_link">
            <img src="../../assets/images/footer/icon6.png" />
          </a>
        </div>
      </div>
    </div>
    <div class="cn_globe_footer_beian">
      <div class="EC_width">
        <span style="border-right: 1px solid #7b889b; padding-right: 10px; margin-right: 10px"> Copyright2023
          北京华卫通科健康科技有限公司版权所有</span>
        <a href="https://beian.miit.gov.cn" style="color: #7b889b;" target="_blank">
          <span style="border-right: 1px solid #7b889b; padding-right: 10px; margin-right: 10px">
            {{ ICPNumber }}
          </span>
        </a>
        <span>
          <a target="_blank" style="color: #7b889b;" :href="PublicNumberURl">
            <img class="gonganicon" src="../../assets/images/footer/gongan.png" />
            {{ PublicNumber }}
          </a>
          <img src="../../assets/icons/icon.gif" />
        </span>

        <a v-for="(itemlnike, indexlnike) in columntopList" :key="indexlnike" href="javascript:;"
          class="cn_footer_beian_link" :class="{ marginLeft50: indexlnike == 0 }" @click="columnclk(itemlnike)">
          {{ itemlnike.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerIndex',
  data() {
    return {
      QRcodeRotation: [],
      linksRotation: [],
      columntopList: [],
      ismagazine: false,
      ICPNumber: '京ICP备2021015394号-2',
      PublicNumber: '京公网安备 11010202009427号',
      PublicNumberURl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202009427'
    };
  },
  created() {
    if (window.location.host.search('chinagp.net') > -1) {
      this.ICPNumber = '冀ICP备12018774号-6';
      this.PublicNumber = '冀公网安备 13040302001295号';
      this.PublicNumberURl = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13040302001295';
    }
    this.GetRotationInfo();
    this.GetColumsInfoAllList();
  },
  methods: {
    //获取轮播数据
    GetRotationInfo() {
      let _this = this;
      let arr = [12, 13]; //分别代表二维码，友情链接,杂志专区友链
      for (let i = 0; i < arr.length; i++) {
        _this.httphelper
          .post('WebSetMessApi/GetRotationInfo', {
            type: arr[i],
            ruselocation: 1,
            source: 2
          })
          .then(res => {
            if (res.code == 200) {
              if (arr[i] == 12) {
                //二维码
                _this.QRcodeRotation = res.data || null;
              }
              if (arr[i] == 13) {
                //友情链接
                _this.linksRotation = res.data || null;
              }
            }
          });
      }
    },
    //获取栏目数据
    GetColumsInfoAllList() {
      let _this = this;
      _this.httphelper
        .post('NewApi/GetColumsInfoAllList', {
          cType: 3,
          isCHorEN: 1
        })
        .then(res => {
          if (res.code == 200) {
            let data = res.data.sort(function (a, b) {
              return a.sort - b.sort;
            });
            data.forEach(item => {
              if (item.parentid == '106') {
                //首页底部信息
                _this.columntopList.push(item);
              }
            });
          }
        });
    },
    columnclk(item) {
      var contextid = 0;
      switch (item.id) {
        case 107:
          contextid = 758;
          break;
        case 108:
          contextid = 757;
          break;
        case 109:
          contextid = 464;
          break;
        case 110:
          contextid = 759;
          break;
        case 111:
          contextid = 221;
          break;
        case 112:
          contextid = 465;
          break;
      }
      //   const { href } = this.$router.resolve({
      //     path: '/home/inforDetails?',
      //     query: {
      //       id: contextid,
      //       column_Type: item.id
      //     }
      //   });
      //   window.open(href, '_blank');
      const hrefurl = 'https://gp519.com/home/inforDetails?id=' + contextid + '&column_Type=' + item.id;
      window.open(hrefurl, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.gonganicon {
  position: relative;
  top: 5px;
}

.logo {
  // width: 100%;
  height: 82px;
}
</style>