<template>
  <div class="mbox">
    <div class="pdfbox"
         v-if="courseInfo != null"
         :style="'background-image: url(' + courseInfo.acr_PictResUrl + ');'">
      <div class="display_center"
           v-loading="loadedRatio != 1"
           v-show="pdfsrc">
        <pdf :src="pdfsrc"
             class="display_center"
             style="width: 100%"
             ref="mpdf"
             :page="currentPage"
             :rotate="pdfRotate"
             @progress="pdfJiazai($event)"
             @num-pages="pageCount = $event"
             @page-loaded="currentPage = $event"></pdf>
        <div class="jindu"
             v-show="loadedRatio != 1 && firstload">{{ Math.floor(loadedRatio * 100) }}%</div>
      </div>
      <div class="fengmian display_center"
           v-show="!pdfsrc">
        <img src="../../../assets/icons/bofang6.png"
             @click="getPdf()"
             alt="" />
      </div>
      <div class="btn display_between">
        <!-- <div class="bmid bxuanzhuan display_center">
          <img @click="scaleX()"
               src="../../../assets/icons/suoxiao.png"
               alt="">
        </div> -->
        <!-- <div class="bmid bxuanzhuan display_center">
          <img @click="pdfsrc?pdfRotate=pdfRotate-90:''"
               src="../../../assets/icons/xuanzhuan1.png"
               alt="">
        </div> -->
        <div style="width: 46px"></div>
        <div class="bmid display_center">
          <img @click="changePdfPage(0)"
               src="../../../assets/icons/left3.png"
               alt="" />
          <div class="num">{{ pageCount ? currentPage : 0 }} /{{ pageCount || 0 }}</div>
          <img @click="changePdfPage(1)"
               src="../../../assets/icons/right3.png"
               alt="" />
        </div>
        <div class="bmid bxuanzhuan display_center">
          <img @click="pdfsrc ? (pdfRotate = pdfRotate + 90) : ''"
               src="../../../assets/icons/quanping.png"
               alt="" />
        </div>
        <!-- <div class="bmid bxuanzhuan display_center">
          <img @click="scaleD()"
               src="../../../assets/icons/fangda.png"
               alt="">
        </div> -->
      </div>
    </div>
    <van-overlay :show="isQuan"
                 class="pdfquanbg"
                 :lock-scroll="false">
      <div class="wrapper">
        <pdf :src="pdfsrc"
             style="width: 100%"
             :page="currentPage"
             :rotate="pdfRotate"
             @progress="pdfJiazai($event)"
             @num-pages="pageCount = $event"
             @page-loaded="currentPage = $event"></pdf>
        <div class="quanBtn1"
             @click.stop>
          <div class="bmid display_center"
               v-show="!isbtnloading">
            <img @click="changePdfPage(0)"
                 src="../../../assets/icons/top.png"
                 alt="" />
          </div>
        </div>
        <div class="quanBtn0"
             @click.stop>
          <div class="bmid display_center">
            <img @click="pdfRotate = 0"
                 src="../../../assets/icons/cha2.png"
                 alt="" />
          </div>
        </div>
        <div class="quanBtn2"
             @click.stop>
          <div class="bmid display_center"
               v-show="!isbtnloading">
            <img @click="changePdfPage(1)"
                 src="../../../assets/icons/down.png"
                 alt="" />
          </div>
        </div>
      </div>
    </van-overlay>

    <div class="m2box">
      <div class="m2top display_between_nocenter"
           v-if="info != null">
        <div class="m2title">{{ courseInfo.title }}</div>
        <div class="m2bq">
          <span>{{ info.res_Type == 2 ? '视频' : info.res_Type == 3 ? '音频' : '电子书' }}</span>
        </div>
      </div>
      <div class="m2mid display_column"
           v-if="info != null">
        <span>讲师：{{ info.expertName ? info.expertName : info.expertlist[0].name }}</span>
        <span>单位：{{ info.unit ? info.unit : info.expertlist[0].unit }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf';
import { Toast } from 'vant';
export default {
  components: {
    pdf,
  },
  data () {
    return {
      uid: 0,
      cid: 0,
      userType: 0,
      pdfsrc: '',
      loading: false,
      cidIng: 0,
      discountStatus: 0,
      discountStatustype: 1,
      loadedRatio: 1,
      currentPage: 1, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      pdfRotate: 0,
      isQuan: false,
      isbtnloading: false,
      firstload: true,
      //   pdfScale: 100
      //   pdfSize: 1,
    };
  },
  props: {
    courseInfo: {
      type: Object,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
  },
  watch: {
    pdfRotate () {
      this.isQuan = !this.isQuan;
    },
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType');
    } else {
      this.userType = 0;
    }
    this.cid = Number(this.$route.query.courseId);
    this.cidIng = Number(this.$route.query.cidIng);
  },
  mounted () { },
  methods: {
    getPdf () {
      console.log(this.info);
      if (this.pdfsrc) {
        this.pdfsrc = '';
      }
      //课程已购买
      if (this.courseInfo.isPurchase == true) {
        this.toPdf();
        //课程未购买
      } else {
        //免费课程、会员的会员课程
        let dis = this.courseInfo.discountStatus
        if ((dis == 2 && this.uid != 0) || (dis == 1 && this.userType != 0) || (dis == 3 && this.userType == 1)) {
          this.httphelper
            .post('CourseApi/CourseApply', {
              courseId: this.cid == 0 ? this.cidIng : this.cid,
              userId: this.uid,
            })
            .then(res => {
              if (res.code == 200) {
                this.toPdf();
              }
            });
          //未登录看免费课程
        } else if (this.courseInfo.discountStatus == 2 && this.uid == 0) {
          if (this.info.isApply == true && this.info.tryTime != null && this.info.tryTime != 0) {
            this.discountStatus = this.info.tryTime;
            this.discountStatustype = 1;
            this.toPdf();
          } else {
            Toast.fail('请登录');
          }
        } else {
          if (this.info.isApply == true && this.info.tryTime != null && this.info.tryTime != 0) {
            this.discountStatus = this.info.tryTime;
            this.discountStatustype = 2;
            this.toPdf();
          } else {
            Toast.fail('请购买课程后观看');
          }
        }
      }
    },
    pdfJiazai (e) {
      this.loadedRatio = e;
      if (e == 1) {
        this.firstload = false;
      }
    },
    toPdf () {
      //   this.discountStatus = 4
      this.loading = true;
      this.httphelper
        .post('/CGPInfoApi/GetCourseRes', {
          courseId: this.cidIng,
          userId: this.uid,
        })
        .then(res => {
          this.pdfsrc = res.data;
          if (this.pdfsrc == '') return;
          var img = document.createElement("img");
          img.src = this.httphelper
            .get('CourseClassApi/UpdCourseRecord', {
              courseid: this.cidIng,
              userId: this.uid,
              standingtime: 1,
            });
          document.body.appendChild(img);
          img.style.display = 'none'
          //   this.httphelper
          //     .post('CourseClassApi/UpdCourseRecord', {
          //       courseid: this.cidIng,
          //       userId: this.uid,
          //       standingtime: 1,
          //     })
          //     .then(res => {
          //       if (res.code == 200) {
          //         console.log('更新成功');
          //       }
          //     });
        });
    },
    changePdfPage (val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        this.isbtnloading = true;
        var ssttime1 = setTimeout(() => {
          this.isbtnloading = false;
          clearTimeout(ssttime1);
        }, 300);
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        if (this.discountStatus != 0 && this.currentPage == this.discountStatus) {
          if (this.discountStatustype == 1) {
            Toast.fail('试看结束，请登录后观看');
          } else if (this.discountStatustype == 2) {
            Toast.fail('试看结束，请购买后观看');
          }
          return;
        }
        this.currentPage++;
        this.isbtnloading = true;
        var ssttime2 = setTimeout(() => {
          this.isbtnloading = false;
          clearTimeout(ssttime2);
        }, 300);
      }
    },
    //放大
    scaleD () {
      this.pdfScale += 5;
      this.$refs.mpdf.$el.style.width = parseInt(this.pdfScale) + '%';
    },

    //缩小
    scaleX () {
      if (this.pdfScale == 50) {
        return;
      }
      this.pdfScale += -5;
      this.$refs.mpdf.$el.style.width = parseInt(this.pdfScale) + '%';
    },
  },
};
</script>
<style lang="scss" scoped>
.mbox {
    .pdfbox {
        position: relative;
        width: 100%;
        min-height: 210px;
        background-image: url('../../../assets/images/detail/bg.jpg');
        background-size: 100% 100%;
        overflow: hidden;
        .fengmian {
            width: 112px;
            height: 152px;
            margin: 19px auto 0;
            background-size: 100% 100%;
            img {
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
        }
        .btn {
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: 0px;
            .bleft {
                width: 90px;
            }
            .bmid {
                background: #15161b79;
                padding: 5px;
                border-radius: 5px;
                img {
                    width: 12px;
                    height: 12px;
                }
            }
            .bxuanzhuan {
                margin: 0 10px;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            .num {
                height: 16px;
                background: #ffffff;
                padding: 0 6px;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #15161b;
                line-height: 16px;
                margin: 0 8px;
            }
            .bright {
                background: #15161b;
                padding: 5px;
                padding-bottom: 0px;
                border-radius: 5px;
                margin-right: 19px;
                img {
                    width: 13px;
                    height: 13px;
                    cursor: pointer;
                }
            }
        }
        .jindu {
            position: absolute;
            top: 48%;
            z-index: 99999;
            font-size: 12px;
        }
    }
}
.m2box {
    padding: 7px 17px 3px;
    .m2top {
        padding: 15px 0;
        .m2title {
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
            flex: 1;
        }
        .m2bq {
            height: 18px;
            background: #ffffff;
            border: 1px solid #e60012;
            border-radius: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e60012;
            line-height: 17px;
            padding: 0 5px;
            margin-left: 5px;
            margin-top: 2px;
        }
    }
    .m2mid {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #626262;
        line-height: 21px;
    }
}
.pdfquanbg {
    background: rgba(0, 0, 0, 0.9);
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        .quanBtn1 {
            position: absolute;
            top: 10px;
            background: rgba(0, 0, 0, 0.2);
            padding: 4px;
            border-radius: 3px;
            img {
                width: 26px;
                height: 26px;
            }
        }
        .quanBtn2 {
            position: absolute;
            bottom: 10px;
            background: rgba(0, 0, 0, 0.2);
            padding: 4px;
            border-radius: 3px;
            img {
                width: 26px;
                height: 26px;
            }
        }
        .quanBtn0 {
            position: absolute;
            top: 10px;
            right: 10px;
            background: rgba(0, 0, 0, 0.2);
            padding: 4px;
            border-radius: 3px;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
