<template>
  <div>
    <div class="top display_between"
         v-if="isnav">
      <img class="tleft"
           src="../../../assets/icons/fanhui.png"
           @click="fanhui"
           alt="" />
      <div class="tmid">{{ info.title }}</div>
      <div class="tright"></div>
    </div>
    <div class="mid">
      <router-view style="min-height: 210px"
                   ref="mdetail"
                   :changeRfs="changeRfs"></router-view>
    </div>
    <div class="m2bot display_between_end">
      <div class="m2bleft">
        <div class="m2b1 display_start_end"
             v-show="info.discountStatus == 2">
          <span class="m2span1">免费</span>
        </div>
        <div class="m2b2"
             v-show="info.discountStatus == 1">
          <span class="m2span1">VIP专享</span>
        </div>
        <div class="m2b3"
             v-show="info.discountStatus == 3 && userType != 1">
          <!-- <span class="m2span1">￥{{ info.price }}</span>
          <span class="m2span2">原价￥{{ info.discount }}</span>
          <span v-show="info.limitedTime != null"
                class="m2span3">({{ info.limitedTime }})</span> -->
          <span class="m2span1">￥{{ Number(info.discount).toFixed(2) }}</span>
          <span class="m2span2"
                v-show="info.price > info.discount">原价￥{{ Number(info.price).toFixed(2) }}</span>
        </div>
      </div>
      <div class="m2bright">
        <img src="../../../assets/icons/people4.png"
             alt="" />
        <span>{{ info.playSum }}人学过</span>
      </div>
    </div>
    <div class="m3bot display_between"
         v-if="info.is_Group == true">
      <div class="m3bleft display_center_ud">
        <img src="../../../assets/icons/qun2.png"
             alt="" />
        <span>加入课程交流群，获取更多资源与信息！</span>
      </div>
      <div class="m3bright display_center_ud"
           @click="isqun = true">
        <img src="../../../assets/icons/code2.png"
             class="m3br1"
             alt="" />
        <img src="../../../assets/icons/right4.png"
             class="m3br2"
             alt="" />
      </div>
      <van-popup v-model="isqun"
                 closeable
                 position="bottom"
                 round
                 :style="{ height: '350px' }">
        <div class="m3box display_column_start_center">
          <div class="m3btop">加入群聊</div>
          <img :src="info.group_ResUrl"
               class="m3bCode"
               alt="" />
          <div class="m3bbot">长按图片，识别图中二维码</div>
        </div>
      </van-popup>
    </div>
    <div class="every"></div>
    <div class="nav display_flex"
         v-show="!isRfs">
      <div class="navbox"
           :style="navIndex == 1 ? 'color:#0b8586;border-color:#0b8586' : ''"
           @click="navIndex = 1">课程介绍
      </div>
      <div class="navbox"
           :style="navIndex == 2 ? 'color:#0b8586;border-color:#0b8586' : ''"
           @click="navIndex = 2">目录({{
        mp4List.length }})</div>
      <div class="navbox"
           v-show="info.is_Coment"
           :style="navIndex == 3 ? 'color:#0b8586;border-color:#0b8586' : ''"
           @click="navIndex = 3">讨论区</div>
      <div class="navbox"
           :style="navIndex == 4 ? 'color:#0b8586;border-color:#0b8586' : ''"
           @click="navIndex = 4">授课专家
      </div>
      <div class="navbox"
           :style="navIndex == 5 ? 'color:#0b8586;border-color:#0b8586' : ''"
           @click="navIndex = 5">相关课程
      </div>
    </div>
    <div class="bot"
         v-show="!isRfs">
      <div class="bot1"
           v-if="navIndex == 1">
        <img :src="info.details_ResUrl"
             alt="" />
      </div>
      <div class="bot2"
           v-if="navIndex == 2">
        <div class="b2box display_column"
             v-for="(item, index) in mp4List"
             :style="index == 0 ? 'border-top:none' : ''"
             @click="toCourse(item)"
             :key="index">
          <div class="display_between_nocenter">
            <div class="blb2title"
                 :style="item.courseId == $route.query.cidIng ? 'color: #e60012;' : ''">
              <span>{{ item.title }}</span>
            </div>
            <div class="blb2bq"
                 v-show="item.isApply &&
              !info.isPurchase &&
              !((info.discountStatus == 2 && uid != 0) || (info.discountStatus == 1 && userType != 0) || (info.discountStatus == 3 && userType == 1))
              "
                 style="color: #fff; background: #e60012; border: 1px solid #e60012">
              <span>试看</span>
            </div>
            <div class="blb2bq"
                 :style="item.courseId == $route.query.cidIng ? 'border: 1px solid #e60012;color:#e60012' : ''">
              <span>{{ item.res_Type == 2 ? '视频' : item.res_Type == 3 ? '音频' : '电子书' }}</span>
            </div>
          </div>
          <div class="blb2tech">
            <span>讲者：{{ item.expertName }}</span>
            <span>/{{ item.unit }}</span>
          </div>
          <div class="blb2time display_center_ud"
               v-show="item.res_Type != 4">
            <span style="font-weight: 800">{{ item.standingtime == 0 ? '0:00' :
              item.standingtime.toFixed(2).toString().replace('.', ':') }}</span>
            <div>/</div>
            <span>{{ item.res_duration.toFixed(2).toString().replace('.', ':') }}</span>
            <img src="../../../assets/icons/bofang2.png"
                 v-show="item.courseId == $route.query.cidIng"
                 alt="" />
          </div>
        </div>
      </div>
      <div class="bot3"
           v-if="navIndex == 3"
           :style="isPinglun ? 'margin-bottom:100px' : ''">
        <div class="noComment display_column_center"
             v-show="commentList.length == 0 && info.is_Coment">
          <img src="../../../assets/images/detail/noComment.png"
               alt="" />
          <span>暂无评论</span>
        </div>
        <div class="commentbox"
             v-show="info.is_Coment"
             v-for="(item, index) in commentList"
             :style="index == 0 ? 'border-top:none' : ''"
             :key="index">
          <div class="comTop display_between">
            <div class="comTleft display_center_ud">
              <img :src="item.userAvatar"
                   class="comtx"
                   alt="" />
              <div class="comname display_center_ud">
                <span>{{ samsung(item.userName) }}</span>
              </div>
            </div>
            <div class="comTright">
              <!-- <div class="comzan display_center_ud">
                <img src="../../../assets/icons/zan3.png"
                     alt="">
                <span style="margin-right:3px">点赞</span>
                <span>0</span>
              </div> -->
            </div>
          </div>

          <div class="comtext">
            <span v-html="item.comentInfo"></span>
          </div>
          <div class="commore display_center_ud">
            <span>{{ item.comentTime }}</span>
            <!-- <div class="comread display_center_ud">
              <img src="../../../assets/icons/read2.png"
                   alt="">
              <span style="margin-right:3px">阅读</span>
              <span>0</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="bot4"
           v-if="navIndex == 4">
        <div class="expertbox display_flex"
             v-for="(item, index) in expertsList"
             :style="index == 0 ? 'border-top:none' : ''"
             :key="index">
          <div class="expLeft">
            <img :src="item.headPortraitUrl"
                 alt="" />
          </div>
          <div class="expRight">
            <div class="expRtop display_center_ud">
              <div class="expName">{{ item.name }}</div>
              <div class="expbtn"
                   v-show="item.isDredg"
                   @click="toExpert(item.id)">
                <span>个人主页</span>
              </div>
            </div>
            <div class="expRmid">
              <span>{{ item.unit }}</span>
            </div>
            <div class="expRbot">
              <div class="expText1"
                   v-show="ExpShowNum != item.id">{{ item.detailsInfo }}</div>
              <div class="expText2"
                   v-show="ExpShowNum == item.id">{{ item.detailsInfo }}</div>
              <div class="expShow display_center_ud"
                   @click="ExpShowNum == item.id ? (ExpShowNum = 0) : (ExpShowNum = item.id)">
                <span>{{ ExpShowNum == item.id ? '收起' : '展开' }}</span>
                <img src="../../../assets/icons/show1.png"
                     v-show="ExpShowNum != item.id"
                     alt="" />
                <img src="../../../assets/icons/show2.png"
                     v-show="ExpShowNum == item.id"
                     alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bot5"
           v-if="navIndex == 5">
        <div class="row display_between_nocenter"
             v-for="(item, index) in coursesList"
             :key="index + 'f'">
          <Mcourse :info="items"
                   style="width: 47%"
                   v-for="(items, indexs) in item"
                   :key="indexs + 's'"></Mcourse>
        </div>
      </div>
    </div>
    <div class="fot applebottom display_between"
         v-show="!isRfs"
         v-if="!isPinglun">
      <div class="fotleft display_center_ud">
        <div class="flbox display_column_start_center"
             @click="fenxiang"
             style="margin-left: 0px">
          <img src="../../../assets/icons/fenxiang2.png"
               alt="" />
          <span>分享</span>
        </div>
        <div class="flbox display_column_start_center"
             @click="toShoucang()">
          <img src="../../../assets/icons/shoucang2.png"
               v-show="!info.isFavorite"
               alt="" />
          <img src="../../../assets/icons/shoucang3.png"
               v-show="info.isFavorite"
               alt="" />
          <span>收藏</span>
        </div>
        <div class="flbox display_column_start_center"
             v-show="info.isPurchase == false && info.discountStatus == 3"
             @click="toCar">
          <van-badge :content="shopCarNum">
            <img src="../../../assets/icons/car.png"
                 alt="" />
          </van-badge>
          <span>购物车</span>
        </div>
      </div>
      <div class="fotright display_center_ud">
        <div class="frbox"
             @click="toLogin"
             v-if="uid == 0">
          <span>立即登录</span>
        </div>
        <div v-else>
          <div class="frbox"
               @click="toShop"
               v-if="!isShop && info.discountStatus == 1 && navIndex != 3 && userType == 0">
            <span>立即成为VIP</span>
          </div>
          <div id="car"
               v-else-if="!isShop && info.discountStatus == 3 && navIndex != 3 && userType != 1">
            <div class="frboxl"
                 @click="toShopCar">
              <span>加入购物车</span>
            </div>
            <div class="frboxr"
                 @click="toShopCourse">
              <span>立即购买</span>
            </div>
          </div>
          <!-- v-show="info.is_Coment" -->
          <div class="frbox2 display_center_ud"
               @click="topinglun"
               v-else>
            <img src="../../../assets/icons/xie.png"
                 alt="" />
            <span>写评论...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="fot2 applebottom display_between"
         v-show="!isRfs"
         v-else>
      <div class="f2left">
        <el-input type="textarea"
                  placeholder="分享是互助学习的起点"
                  class="f2input"
                  resize="none"
                  :autofocus="true"
                  v-myfocus
                  v-model="plValue"> </el-input>
      </div>
      <div class="f2right"
           @click="toComment">
        <span>发布</span>
      </div>
    </div>
    <van-popup v-model="isShowSub"
               closeable
               style="width: 280px; height: 311px; overflow: hidden"
               position="center">
      <img src="../../../assets/images/detail/sub.jpg"
           style="width: 280px; height: 311px; object-fit: cover" />
    </van-popup>
  </div>
</template>


<script>
import lodash from 'lodash';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import Mcourse from '../../../components/mobile/course/index.vue';
export default {
  components: {
    Mcourse,
  },
  data () {
    return {
      navIndex: 1,
      commentList: [],
      cid: 0,
      cidIng: 0,
      uid: 0,
      userType: 0,
      ExpShowNum: 0,
      coursesList: [],
      isPinglun: false,
      plValue: '',
      isqun: false,
      isShowSub: false,
      shopCarNum: 0,
      isnav: true
    };
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    mp4List: [],
    toCourse: {
      type: Function,
      default: null,
    },
    expertsList: [],
    isShop: {
      type: Boolean,
      default: true,
    },
    isRfs: {
      type: Boolean,
      default: false,
    },
    tofShoucang: {
      type: Function,
      default: null,
    },
    changeRfs: {
      type: Function,
      default: null,
    },
  },

  watch: {
    $route: {
      handler () {
        this.cidIng = Number(this.$route.query.cidIng);
        if (this.cid != this.$route.query.courseId) {
          this.cid = Number(this.$route.query.courseId);
          this.getCommentList();
        }
      },
      deep: true,
    },
    info: function () {
      if (this.info != null) {
        this.$h5weixinshare.WeiXinShare('【全科知家】' + this.info.title, window.location.href, '全科知家 知行合医，海量全科学习资源等您挖掘');
        this.wx.miniProgram.getEnv(res => {
          if (res.miniprogram) {
            // alert("在小程序里");
            this.wx.miniProgram.postMessage({
              data: {
                shareTitle: '【全科知家】' + this.info.title,
                page: '/pages/index/webview',
                shareUrl: window.location.href,
                islogin: false,
              },
            });
          } else {
            // alert("不在小程序里");
          }
        });
        this.getCourses();
      }
    },
    navIndex: function () {
      if (this.navIndex != 3) {
        this.isPinglun = false;
      }
    },
  },
  created () {
    if (this.$route.query.isxcx == 1) {
      this.isnav = false
    }
    if (this.$route.query.courseId) {
      this.cid = Number(this.$route.query.courseId);
      this.cidIng = Number(this.$route.query.cidIng);
    }
    if (localStorage.getItem('ResData')) {
      // console.log(localStorage.getItem('ResData'));
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType');
    } else {
      this.userType = 0;
    }
    if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'sub')) {
      this.isShowSub = true;
    }
    this.getCommentList();
  },
  methods: {
    fanhui () {
      if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'callback')) {
        let callbackurl = decodeURIComponent(this.$route.query.callback);
        if (callbackurl == 'home') {
          this.$router.push({
            name: 'home',
          });
        } else if (callbackurl == 'allCourse') {
          this.$router.push({
            name: 'allCourse',
          });
        } else {
          if (this.$route.name == 'detailVideo') {
            this.$refs.mdetail.toRecordMp4();
          }
          var time = setTimeout(function () {
            window.location.href = callbackurl;
            clearTimeout(time);
          }, 100);
        }
      } else {
        this.$router.push({
          name: 'allCourse',
        });
      }
    },
    getCommentList () {
      this.httphelper
        .post('CGPInfoApi/GetCourseCommentList', {
          courseId: this.cid == 0 ? this.cidIng : this.cid,
          userId: this.uid,
        })
        .then(res => {
          if (res.data != null) {
            this.commentList = res.data;
          }
        });
    },
    getShopCarList () {
      this.httphelper
        .post('CGPInfoApi/GetMyGpChat', {
          userId: this.uid,
        })
        .then(res => {
          if (res.data != null) {
            this.shopCarNum = res.data.length;
          }
        });
    },
    samsung (str) {
      var replcenem = '';
      //三位姓名的中间变星号
      if (str.length >= 3) {
        replcenem = str.replace(/^(.+).(.)$/, '$1*$2');
        return replcenem;
      }
      if (str.length == 2) {
        //两位的姓名，后面以为变星号
        var strSplit = str.split('');
        var strSplitone = strSplit[0];
        replcenem = strSplitone + '*';
        return replcenem;
      }
    },
    toExpert (id) {
      var callback = encodeURIComponent(window.location.href);
      window.open(this.httphelper.IsDebugH5GP519() + '/pages/expert/detail/detail?id=' + id + '&callback=' + callback, '_blank');
    },
    getCourses () {
      this.httphelper
        .post('CGPInfoApi/GetDbGpRelatedRecommendedCoursesList', {
          courseType: this.info.video_Type,
          expertId: this.info.expertlist[0].id.toString(),
          labelgather: this.info.labelgather,
          id: this.cid == 0 ? this.cidIng : this.cid,
        })
        .then(res => {
          if (res.data != null) {
            this.coursesList = lodash.chunk(res.data.slice(0, 8), 2);
            this.getShopCarList();
          }
        });
    },
    toShop () {
      if (this.uid == 0) {
        Dialog.confirm({
          title: '提示',
          message: '请登录',
          showCancelButton: true,
        }).then(() => {
          var callback = encodeURIComponent(window.location.href);
          window.open(this.httphelper.IsDebugH5GP519() + '/pages/login/login?callback=' + callback, '_blank');
        });
        return;
      }
      var callback = encodeURIComponent(window.location.href);
      window.open(this.httphelper.IsDebugH5GP519() + '/pages/user/vip?callback=' + callback, '_blank');
    },
    toShopCourse () {
      this.wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          // alert('是小程序')
          var a = this.cid == 0 ? this.cidIng : this.cid;
          const url_wx = '/pages/pay/index?cid=' + a
          this.wx.miniProgram.navigateTo({ url: url_wx })
        } else {
          var ua = window.navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            this.getWetChatCode();
          } else {
            Toast.fail('请在微信端打开进行支付');
          }
        }
      })

    },
    getWetChatCode () {
      var code = this.getUrlParam('code');
      var local = window.location.href;
      if (code == null || code == '') {
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx710c154c1de7e976&redirect_uri=' +
          encodeURIComponent(local) +
          '&response_type=code&scope=snsapi_base&state=state#wechat_redirect';
      } else {
        this.wetchatcode = code;
      }
    },
    getUrlParam (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      let url = window.location.href.split('#')[0];
      let search = url.split('?')[1];
      if (search) {
        var r = search.substring(0).match(reg);
        if (r !== null) return unescape(r[2]);
        return null;
      } else {
        return null;
      }
    },
    toShoucang () {
      if (this.uid == 0) {
        Dialog.alert({
          title: '提示',
          message: '请登录',
          showCancelButton: true,
        }).then(() => {
          let callback = encodeURIComponent(window.location.href);
          window.open(this.httphelper.IsDebugH5GP519() + '/pages/login/login?callback=' + callback, '_blank');
        });
        return;
      }
      if (this.info.isFavorite) {
        this.httphelper
          .post('CGPInfoApi/DeleteGpFavorites', {
            courseId: this.cid == 0 ? this.cidIng : this.cid,
            userId: this.uid,
          })
          .then(res => {
            if (res.code == 200) {
              Toast.success('已取消收藏');
            }
          });
      } else {
        this.httphelper
          .post('CGPInfoApi/AddGpFavorites', {
            courseId: this.cid == 0 ? this.cidIng : this.cid,
            userId: this.uid,
          })
          .then(res => {
            if (res.code == 200) {
              Toast.success('收藏成功');
            }
          });
      }
      this.tofShoucang();
    },
    topinglun () {
      this.isPinglun = true;
      this.navIndex = 3;
    },
    toComment () {
      if (this.plValue == '') {
        Toast.fail('评论内容不可为空');
      } else if (this.uid == 0) {
        Toast.fail('请先登录');
      } else {
        this.httphelper
          .post('CGPInfoApi/AddCourseComment', {
            coment_Info: this.plValue,
            coment_Course: this.cid == 0 ? this.cidIng : this.cid,
            coment_User: this.uid,
          })
          .then(res => {
            if (res.code == 249 || res.code == 200) {
              this.plValue = '';
              Toast.success('发布成功');
            }
            this.getCommentList();
          });
      }
    },
    fenxiang () {
      Dialog.alert({
        title: '提示',
        message: '请点击右上角“...”进行分享',
      });
    },
    toLogin () {
      let callback = encodeURIComponent(window.location.href);
      window.open(this.httphelper.IsDebugH5GP519() + '/pages/login/login?callback=' + callback, '_blank');

    },
    toShopCar () {
      if (this.uid == 0) {
        Toast.fail('请登录');
        return;
      }
      this.httphelper
        .post('CGPInfoApi/AddGpChat', {
          userId: this.uid,
          courseId: this.cid == 0 ? this.cidIng : this.cid,
        })
        .then(res => {
          if (res.code == 200) {
            this.isCar = true;
            Toast.success('添加成功');
            this.getShopCarList();
          } else if (res.code == 242) {
            this.isCar = true;
            Toast.fail('课程已存在，无需重复添加');
          } else if (res.code == 243) {
            Toast.fail('购物车已满');
          } else if (res.code == 311) {
            Dialog.confirm({
              title: '提示',
              message: '已有订单包含该课程，点击确定跳转至订单中心',
              showCancelButton: true,
            }).then(() => {
              var toenkeys = localStorage.getItem('token_newgphome');
              var callback = encodeURIComponent(this.delUrlData('callback'));
              window.open(this.httphelper.IsDebugH5GP519() + '/pages/user/myOrder?callback=' + callback + '&tokenkey=' + toenkeys, '_blank');
            });
          } else {
            Toast.fail('添加失败');
          }
        });
    },
    toCar () {
      if (this.uid == 0) {
        Toast.fail('请登录');
        return;
      }
      this.wx.miniProgram.getEnv(res => {
        if (res.miniprogram) {
          const url_wx = '/pages/pay/shopcar?callback=' + callback;
          this.wx.miniProgram.navigateTo({ url: url_wx })
        } else {
          var toenkeys = localStorage.getItem('token_newgphome');
          var callback = encodeURIComponent(this.delUrlData('callback'));
          window.open(this.httphelper.IsDebugH5GP519() + '/pages/user/shopcar?callback=' + callback + '&tokenkey=' + toenkeys, '_blank');
        }
      });

    },
  },
};
</script>
<style lang="scss" scoped>
.top {
    height: 46px;
    padding: 0 17px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

    .tleft {
        width: 17px;
        height: 15px;
    }

    .tmid {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tright {
        width: 19px;
        height: 20px;
    }
}

.mid {
    width: 100%;
    min-height: 210px;
}

.m2bot {
    padding: 8px 17px 22px;

    .m2bleft {
        .m2span1 {
            font-size: 15px;
            font-family: Arial;
            font-weight: 400;
            color: #e60012;
            line-height: 16px;
        }

        .m2span2 {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            text-decoration: line-through;
            color: #666666;
        }

        .m2span3 {
            margin-left: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
        }
    }

    .m2bright {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 12px;

        img {
            width: 10px;
            height: 12px;
            margin-right: 3px;
            margin-bottom: -1px;
        }
    }
}

.m3bot {
    height: 50px;
    margin: 0 17px;
    border-top: 1px solid #eee;

    .m3bleft {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #626262;

        img {
            width: 18px;
            height: 18px;
            margin-right: 7px;
        }
    }

    .m3br1 {
        width: 14px;
        height: 14px;
        margin-right: 12px;
    }

    .m3br2 {
        width: 7px;
        height: 13px;
    }

    .m3box {
        height: 100%;
        position: relative;

        .m3btop {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 50px;
        }

        .m3bCode {
            width: 150px;
            height: 150px;
            margin: 30px 0 15px;
        }

        .m3bbot {
            width: 300px;
            height: 35px;
            background: #0b8586;
            border-radius: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 35px;
            text-align: center;
            position: absolute;
            bottom: 35px;
        }
    }
}

.every {
    width: 100%;
    height: 10px;
    background: #f6f7fb;
}

.nav {
    margin: 4px 2px 0;
    overflow-x: scroll;

    .navbox {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 46px;
        margin: 0 15px;
        white-space: nowrap;
        border-bottom: 2px solid #ffffff;
    }
}

.nav::-webkit-scrollbar {
    display: none;
}

.bot {
    border-top: 1px solid #eeeeee;
    margin: 0 10px 60px;

    .bot1 {
        img {
            width: 100%;
        }
    }

    .bot2 {
        margin: 5px 7px 0;

        .b2box {
            padding: 16px 0;
            border-top: 1px solid #eee;
        }

        .blb2title {
            flex: 1;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
            margin-right: 3px;
        }

        .blb2bq {
            height: 18px;
            background: #ffffff;
            border: 1px solid #999999;
            border-radius: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            padding: 0 5px;
            margin-left: 5px;
            margin-top: 2px;
        }

        .blb2tech {
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b1b1b1;
            line-height: 15px;
            margin: 10px 0;
        }

        .blb2time {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 12px;

            div {
                margin: 0 2px;
            }

            img {
                width: 11px;
                height: 11px;
                margin-left: 5px;
            }
        }
    }

    .bot3 {
        margin: 0 7px;

        .noComment {
            margin-top: 50px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #9f9f9f;
            line-height: 40px;
            margin-bottom: 30px;

            img {
                width: 105px;
                height: 73px;
            }
        }

        .commentbox {
            padding: 20px 0;
            border-top: 1px solid #eee;

            .comTop {
                .comTleft {
                    .comtx {
                        width: 31px;
                        height: 31px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .comname {
                        font-size: 13px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #000000;
                        line-height: 25px;
                    }
                }

                .comTright {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 12px;

                    img {
                        width: 11px;
                        height: 12px;
                        margin-right: 8px;
                    }
                }
            }

            .comtext {
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #353535;
                line-height: 22px;
                margin: 5px 0 10px;
            }

            .commore {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 16px;

                img {
                    width: 14px;
                    height: 11px;
                    margin: 0 8px 0 18px;
                }
            }
        }
    }

    .bot4 {
        margin: 0 7px;

        .expertbox {
            padding: 15px 0;
            border-top: 1px solid #eee;

            .expLeft {
                margin-right: 14px;

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                }
            }

            .expRight {
                flex: 1;
                padding-top: 5px;

                .expName {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 18px;
                }

                .expbtn {
                    width: 65px;
                    height: 18px;
                    background: #0b8586;
                    border-radius: 9px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 18px;
                    margin-left: 10px;
                    text-align: center;
                }

                .expRmid {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 16px;
                    margin: 7px 0 3px;
                }

                .expRbot {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #404040;
                    line-height: 23px;

                    .expText1 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }

                    .expShow {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #b0b0b0;
                        line-height: 23px;

                        img {
                            width: 9px;
                            height: 5px;
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
    }

    .bot5 {
        margin: 15px 7px 0;

        .row {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.fot {
    width: 100%;
    height: 55px;
    position: fixed;
    bottom: 0;
    background-color: #fff;

    .flbox {
        padding-left: 20px;
        // margin-left: 17px;
        font-size: 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #545454;
        line-height: 10px;

        img {
            width: 20px;
            height: 20px;
            margin-bottom: 5px;
        }
    }

    .frbox {
        width: 180px;
        height: 40px;
        background: #ff4a00;
        border-radius: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        margin-right: 17px;
    }

    .frbox2 {
        width: 156px;
        height: 40px;
        background: #f3f3f3;
        border-radius: 20px;
        line-height: 40px;
        padding: 0 16px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 17px;

        img {
            width: 16px;
            height: 14px;
            margin-right: 10px;
        }
    }

    .frboxl {
        width: 96px;
        height: 40px;
        background: #ffa600;
        border-radius: 20px 0 0 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        padding-left: 6px;
    }

    .frboxr {
        width: 96px;
        height: 40px;
        background: #ff4a00;
        border-radius: 0 20px 20px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        margin-right: 17px;
        padding-right: 6px;
    }
}

.fot2 {
    width: 100%;
    height: 105px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 15px 0;

    .f2left {
        flex: 1;
        height: 100%;
        margin-left: 17px;

        .f2input {
            padding: 10px 15px;
            height: 100%;
            background: #f8f8f8;

            ::v-deep .el-textarea__inner {
                padding: 0;
                border: none;
                background: #f8f8f8;
            }
        }
    }

    .f2right {
        margin-left: 17px;
        margin-right: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0b8586;
    }
}

#car {
    display: flex;
}
</style>
