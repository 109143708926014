import Vue from 'vue';
import App from './App.vue';
import router from './router';
import httphelper from './assets/units/HttpHelper';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.min.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import h5weixinShare from './assets/units/h5weixinShare.js';
import unit from './assets/units/unit.js';
import './assets/css/iconfont.css';
import vueTouch from 'kim-vue-touch'
import wx from 'weixin-js-sdk';

Vue.prototype.wx = wx;
Vue.prototype.httphelper = httphelper;
Vue.prototype.$h5weixinshare = h5weixinShare;
Vue.prototype.delUrlData = unit.delUrlData;
Vue.config.productionTip = false;
Vue.prototype.isMobile = unit.isMobile;
Vue.directive('myfocus', {
  inserted: el => el.focus(),
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
Vue.use(vueTouch)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
