<template>
  <div class="body">
    <div class="top display_between">
      <img class="tleft"
           src="../../assets/icons/fanhui.png"
           @click="ZomClose"
           alt="" />
      <div class="tmid">订单信息</div>
      <div class="tright"></div>
    </div>
    <div class="every"></div>
    <div class="box display_column">
      <div class="box1">
        <span class="b1s1">确认订单信息</span>
        <span class="b1s2">请在24小时内完成支付，否则订单将被取消</span>
      </div>
      <div class="box2">
        <div class="b2left">
          <img :src="UserInfImage"
               alt="">
        </div>
        <div class="b2right display_column_between">
          <span class="b2s1">账号：{{ UserInfCel }}</span>
          <span class="b2s2">购买后不支持退款、转让，请确认好开课时间或有效期内再提交订单</span>
        </div>
      </div>
      <div class="box3">
        <div v-for="(item, index) in CourseInfoList"
             :key="index"
             class="b3box display_between">
          <div class="b3bl display_center_ud">
            <img :src="item.acr_PictResUrl"
                 alt="" />
            <span v-show="item.title.length <= 27">{{ item.title }}</span>
            <span v-show="item.title.length > 27">{{ item.title.slice(0, 26) }}...</span>
          </div>
          <div class="b3br">￥：{{ Number(item.discount).toFixed(2) }}</div>
        </div>
      </div>
      <div class="box4 display_column_between">
        <p>支付方式</p>
        <div class="b4wx display_center_ud">
          <img class="b4wimg1"
               src="../../assets/icons/xuanzhong.png"
               alt="">
          <img class="b4wimg2"
               src="../../assets/icons/wxpay.png"
               alt="">
          <span>微信支付</span>
        </div>
      </div>
      <div class="box5">
        <span>商品总额：￥{{ Number(totalPrice).toFixed(2) }}</span>
      </div>
    </div>
    <div class="bot display_between">
      <div class="botleft">
        <span>付款金额：￥{{ Number(totalPrice).toFixed(2) }}</span>
      </div>
      <div class="botright"
           @click="topay">
        <span>去支付</span>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/units/preventReClick';
import { Toast } from 'vant';
export default {
  props: ['CourseInfo', 'type', 'payType'],
  data () {
    return {
      // 用户头像
      UserInfImage: '',
      // 用户账号
      UserInfCel: '',
      title: this.info,
      CourseInfoList: [],
      CourseId: [],
      VipCourseId: '',
      AuthorId: '',
      totalPrice: '',
      Ip: '',
      wetchatcode: ''
    };
  },
  created () {
    if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'code')) {
      this.wetchatcode = this.$route.query.code
    }
  },
  methods: {
    // 关闭弹框
    ZomClose () {
      this.close();
    },
    // 手机号中间四位隐藏
    mobileStr (str) {
      if (str.length > 7) {
        return str.substring(0, 3) + '****' + str.substring(7, str.length);
      } else {
        return str.substring(0, str.length - 1) + '****';
      }
    },
    // 获取用户个人信息
    getUserInfo () {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: this.AuthorId
        })
        .then(res => {
          this.UserInfImage = res.data.avatarUrl;
          this.UserInfCel = this.mobileStr(res.data.mobile);
        });
    },
    // 获取用户信息
    getInfo () {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: this.AuthorId
        })
        .then(res => {
          this.expire = res.data.expire;
          localStorage.setItem('expire', this.expire);
        });
    },
    close () {
      window.location.href = this.delUrlData('code')
    },
    topay () {
      let isSix = false
      if (localStorage.getItem('isSix')) {
        isSix = localStorage.getItem('isSix')
      }
      this.httphelper.post("CGpPayApi/PaymentBuy", {
        payType: 1,
        userId: this.AuthorId,
        payWay: 1,
        courseIds: this.CourseId,
        totalFee: isSix ? this.totalPrice * 0.6 : this.totalPrice,
        spBillCreateIp: this.Ip,
        paymethod: 3,
        pageUrl: window.location.href,
        code: this.wetchatcode
      })
        .then(res => {
          if (res.code == 200) {
            this.wetchatPay(res.data);
          } else if (res.code == 310) {
            Toast.fail('已存在一笔未付款的订单')
          } else {
            Toast.fail('金额异常，无法购买')
          }
        });
    },
    //发起微信jsapi支付
    wetchatPay (paymodel) {
      const self = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest", {
        appId: "wx710c154c1de7e976",
        timeStamp: paymodel
          .timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: paymodel.nonceStr, // 支付签名随机串，不长于 32 位
        package: paymodel.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: paymodel.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: paymodel.paySign // 支付签名
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.success('支付成功');
            var test1 = setTimeout(() => {
              clearTimeout(test1);
              self.close();
            }, 1000);
          } else {
            Toast.fail('支付失败')
          }
        }
      );
    },
  },
  mounted () {
    // 获取用户id
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.getUserInfo();
    // type 区分数据格式 课程详情页购买课程和购物车购买课程
    if (this.type == 1) {
      // 处理返回数据
      this.CourseInfoList.push(this.CourseInfo);
      this.CourseId.push(Number(this.CourseInfo.courseId));
      this.totalPrice = Number(this.CourseInfo.discount);
    } else if (this.type == 2) {
      // 处理返回数据
      this.CourseInfoList = this.CourseInfo;
      this.CourseInfoList.forEach(item => {
        this.CourseId.push(item.courseId);
      });

      var _totalPrice = 0;
      for (var kk in this.CourseInfoList) {
        if (this.CourseInfoList[kk].discount) {
          _totalPrice += Number(this.CourseInfoList[kk].discount);
        }
      }
      this.totalPrice = _totalPrice;
    } else if (this.type == 3) {
      this.CourseInfoList = this.CourseInfo;
      this.CourseId.push(this.CourseInfo.id);
      this.totalPrice = this.CourseInfo.disMember;
    }
    // 获取 ip
    this.Ip = localStorage.getItem('userIp');
  }
};
</script>
<style lang="scss" scoped>
.body {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
}

.top {
    height: 46px;
    padding: 0 17px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

    .tleft {
        width: 17px;
        height: 15px;
    }

    .tmid {
        width: 150px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tright {
        width: 19px;
        height: 20px;
    }
}

.every {
    width: 100%;
    height: 10px;
    background: #f6f7fb;
}

.box {
    padding: 0 17px;

    .box1 {
        height: 53px;
        line-height: 50px;
        font-size: 11px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #229091;
        display: flex;
        border-bottom: 1px solid #eee;

        .b1s1 {
            font-weight: bold;
            font-size: 16px;
            border-bottom: 2px solid #229091;
            margin-right: 8px;
        }
    }

    .box2 {
        height: 90px;
        padding: 20px 0;
        display: flex;

        .b2left {
            width: 50px;
            height: 50px;
            margin-right: 10px;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }

        .b2right {
            .b2s1 {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 14px;
            }

            .b2s2 {
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #404040;
                line-height: 13px;
            }
        }
    }

    .box3 {
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;

        .b3box {
            width: 340px;
            height: 77px;
            background: #f6f7fb;
            margin-bottom: 10px;
            padding: 10px;

            .b3bl {
                padding-right: 30px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 15px;
                flex: 1;

                img {
                    width: 100px;
                    height: 57px;
                    margin-right: 10px;
                }
            }

            .b3br {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 14px;
            }
        }
    }

    .box4 {
        height: 90px;
        padding: 20px 0;
        border-bottom: 1px solid #eee;

        p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
        }

        .b4wx {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 14px;

            .b4wimg1 {
                width: 14px;
                height: 14px;
                margin-right: 10px;
            }

            .b4wimg2 {
                width: 17px;
                height: 15px;
                margin-right: 5px;
                margin-bottom: -1px;
            }
        }
    }

    .box5 {
        padding: 20px 0;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
    }
}

.bot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding: 0 17px;

    .botleft {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .botright {
        width: 120px;
        height: 36px;
        background: #0b8586;
        border-radius: 18px;
        line-height: 36px;
        text-align: center;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }
}
</style>
