<template>
  <div>
    <div class="body">
      <div class="nav">
        <van-icon name="scan" />
        <div class="navbox">
          <span>热门</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="navbox">
          <span>关注</span>
        </div>
        <div class="navbox">
          <span>推荐</span>
        </div>
        <van-icon name="search" />
      </div>
      <van-swipe class="boxf"
                 height="100%"
                 :show-indicators="false"
                 @change="boxChange"
                 :loop="false"
                 :initial-swipe="initialIndex"
                 vertical>
        <van-swipe-item class="box"
                        v-for="(item,index) in videoList">
          <video :src="item.url"
                 :class="'videoPlay'+index"
                 webkit-playsinline
                 playsinline
                 @click="toVideo(index)"
                 loop></video>
          <van-icon class="play"
                    v-show="playIndex==-1"
                    @click="toplay(index)"
                    name="play" />
          <div class="boxr">
            <div class="txbox">
              <img :src="item.touxiang"
                   alt="">
              <van-icon name="add"
                        class="txadd" />
            </div>
            <van-icon name="fire"
                      class="brbtn" />
            <p>{{item.fire}}</p>
            <van-icon name="like"
                      color="red"
                      class="brbtn" />
            <p>{{item.like}}</p>
            <van-icon name="chat"
                      @click="getChat()"
                      class="brbtn" />
            <p @click="getChat()">{{item.chat}}</p>
            <van-icon name="share"
                      class="brbtn" />
          </div>
          <div class="progress">
            <van-slider v-model="progress"
                        max="100"
                        bar-height="3px"
                        active-color="red"
                        button-size="5"
                        @change="toprogress" />
          </div>
          <div class="boxb">
            <h5>@{{item.name}}</h5>
            <p>{{item.text}}</p>
            <p>
              <span v-for="(items,indexs) in item.biaoqian.split(',')"
                    :key="indexs+'s'"
                    v-show="items!=''">#{{items}}</span>
            </p>
          </div>
        </van-swipe-item>
      </van-swipe>
      <van-popup v-model="isChat"
                 round
                 closeable
                 position="bottom"
                 :overlay-style="{backgroundColor:'rgba(0,0,0,.1)'}"
                 v-swipeup="()=>toChatHeight()"
                 v-swipedown="()=>{isChat=false}"
                 class="chat"
                 :style="{ height: chatBoxHeight }">
        <p class="chatTop">{{videoList[boxIndex].chat}}条评论</p>
        <van-search v-model="chatText"
                    show-action
                    class="chatInput"
                    placeholder="请输入搜索关键词"
                    @search="toSendChat"
                    @focus="isSendIng=true">
          <template #action>
            <div @click="toSendChat">发表</div>
          </template>
        </van-search>
        <van-overlay :show="isSendIng"
                     :custom-style="{backgroundColor:'rgba(0,0,0,.2)'}"
                     @click="isSendIng = false">
        </van-overlay>
      </van-popup>
    </div>
    <!-- <Mfooter></Mfooter> -->
  </div>
</template>

<script>
import Mfooter from '../../components/mobile/index/footer.vue';
export default {
  components: {
    Mfooter
  },
  data () {
    return {
      pageHeight: 600,
      playIndex: -1,
      boxIndex: 0,
      initialIndex: 0,
      progress: 0,
      videoTime: 10,
      timer1: null,
      videoList: [],
      videoLists: [
        {
          name: 'nono',
          url: 'https://cgp-resource.oss-cn-beijing.aliyuncs.com/mechanism/video/20220225/99ae5e9399687e3c21764e9b2d528b1f.mp4',
          text: '这是一段视频简介这是一段视频简介这是一段视频简介这是一段视频简介这是一段视频简介',
          biaoqian: '新年快乐,虎年大吉',
          touxiang: 'https://cgp-resource.oss-cn-beijing.aliyuncs.com/Images/UserHeadPict/20211026/3a29b49dca153ef8d0de0d4ecba3a279.jpg',
          fire: '234',
          like: '18',
          chat: '68'
        },
        {
          name: '小艾',
          url: 'https://cgp-resource.oss-cn-beijing.aliyuncs.com/mechanism/video/20220225/1bf86c89a045b74677f48918db5a0828.mp4',
          text: '这是一段视频简介这是一段视频简介这是一段视频简介这是一段视频简介这是一段视频简介',
          biaoqian: '新年快乐,虎年大吉,万事俱备',
          touxiang: 'https://cgp-resource.oss-cn-beijing.aliyuncs.com/mechanism/images/20220222/b8192e4e6663c9ea20e2d517e1db7096.png',
          fire: '24',
          like: '180',
          chat: '8'
        },
        {
          name: '小步小步',
          url: 'https://cgp-resource.oss-cn-beijing.aliyuncs.com/mechanism/video/20220225/fa819fd73f483ee115802730e5ceef1c.mp4',
          text: '这是一段视频简介这是一段视频简介这是一段视频简介这是一段视频简介这是一段视频简介',
          biaoqian: '新年快乐',
          touxiang: 'https://t8.baidu.com/it/u=4208666111,2049368806&fm=218&app=125&f=PNG?w=121&h=75&s=3022B418C69F7BED2A955DE50300A0A0',
          fire: '294',
          like: '19',
          chat: '12'
        },
      ],
      isChat: false,
      chatBoxHeight: '30%',
      chatText: '',
      isSendIng: false
    }
  },
  watch: {
    isChat () {
      let timer = setTimeout(() => {
        if (this.isChat == false) this.chatBoxHeight = '30%'
        clearTimeout(timer)
      }, 500);
    }
  },
  created () {
    this.videoList = this.videoLists
  },
  mounted () {
    // this.pageHeight = document.body.clientHeight
  },
  methods: {
    toplay (index) {
      this.playIndex = index
      let dom = document.querySelector('.videoPlay' + this.playIndex)
      dom.play()
      this.toSetTime()
    },
    topause () {
      document.querySelector('.videoPlay' + this.playIndex).pause()
      if (this.timer1 != null) clearInterval(this.timer1);
      this.playIndex = -1
    },
    boxChange (index) {
      this.boxIndex = index
      if (this.playIndex != -1) {
        this.topause()
        if (this.playIndex != index) this.toplay(index)
      } else {
        this.toplay(index)
      }
      if (index > this.videoList.length - 2) {
        let timer = setTimeout(() => {
          this.initialIndex = index
          this.videoList = this.videoList.concat(this.videoLists)
          clearTimeout(timer)
        }, 500);
      }
    },
    toVideo (index) {
      if (this.playIndex == -1) this.toplay(index)
      else this.topause()
    },
    toprogress (e) {
      let dom = document.querySelector('.videoPlay' + this.playIndex)
      dom.currentTime = e * this.videoTime / 100
      this.toSetTime()
    },
    toSetTime () {
      let dom = document.querySelector('.videoPlay' + this.playIndex)
      if (this.timer1 != null) clearInterval(this.timer1);
      this.timer1 = setInterval(() => {
        if (this.progress == dom.currentTime && (dom.currentTime == 0 || dom.currentTime == dom.duration)) {
          this.topause()
          clearInterval(this.timer1);
        }
        this.videoTime = dom.duration
        this.progress = dom.currentTime / dom.duration * 100
      }, this.videoTime * 6)
    },
    getChat () {
      this.isChat = true
    },
    toChatHeight () {
      let i = 1
      let timer = setInterval(() => {
        if (i == 1) this.chatBoxHeight = '40%'
        else if (i == 2) this.chatBoxHeight = '50%'
        else if (i == 3) this.chatBoxHeight = '60%'
        else if (i == 4) this.chatBoxHeight = '70%'
        else if (i == 5) this.chatBoxHeight = '80%'
        else clearInterval(timer);
        i++
      }, 10)
    },
    toSendChat () {
      console.log('qq');
    }
  }
}
</script>
<style lang="scss" scoped>
.body {
    height: 100vh;
    position: relative;
    .boxf {
        // position: absolute;
        // top: 0;
        // right: 0;
        // left: 0;
        // bottom: 50px;
        // height: 100vh;
        height: 100%;
        .box {
            background-color: #000000;
            color: #868686;
            video {
                width: 100%;
                height: 100%;
            }
            .play {
                width: 60px;
                height: 60px;
                position: absolute;
                font-size: 60px;
                top: 50%;
                left: 50%;
                margin-top: -30px;
                margin-left: -30px;
                color: rgba(225, 225, 225, 0.8);
            }
            .boxr {
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -150px;
                height: 300px;
                width: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .txbox {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    background-color: #fff;
                    position: relative;
                    margin-bottom: 10px;
                    img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                    }
                    .txadd {
                        color: red;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        bottom: -5px;
                        left: 50%;
                        margin-left: -8px;
                    }
                }
                .brbtn {
                    font-size: 30px;
                    margin-top: 16px;
                    color: #ffffff;
                }
                p {
                    color: #ffffff;
                    font-size: 14px;
                    margin-top: 3px;
                }
            }
            .progress {
                position: absolute;
                bottom: 5px;
                width: 100%;
            }
            .boxb {
                position: absolute;
                bottom: 16px;
                left: 0;
                width: 100%;
                color: #fff;
                padding: 0 10px;
                h5 {
                    margin: 5px 0;
                }
                p {
                    font-size: 12px;
                    margin: 3px 0;
                }
                span {
                    margin-right: 10px;
                }
            }
        }
    }
    .nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 40px;
        font-size: 15px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
        z-index: 20;
    }
    .chat {
        // position: relative;
        .chatTop {
            font-size: 12px;
            text-align: center;
            line-height: 30px;
        }
        ::v-deep .van-popup__close-icon {
            font-size: 16px;
            color: #333333;
        }
        ::v-deep .van-popup__close-icon--top-right {
            top: 10px;
            right: 10px;
        }
        .chatInput {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-top: 1px solid #bbbbbb;
            z-index: 10;
        }
    }
}
</style>