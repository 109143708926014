<script>
export default {
  created () {
    let type = this.$route.query.modelType
    if (type == 10) {
      this.topage(18)
    } else if (type == 11) {
      this.topage(19)
    } else if (type == 12) {
      this.topage(20)
    } else if (type == 13) {
      this.topage(21)
    } else if (type == 14) {
      this.topage(22)
    } else if (type == 15) {
      this.topage(23)
    } else if (type == 16) {
      this.topage(24)
    } else if (type == 17) {
      this.topage(25)
    } else if (type == 18) {
      this.topage(26)
    } else if (type == 1) {
      this.topage(15)
    } else if (type == 2) {
      this.topage(5)
    } else if (type == 3) {
      this.topage(28)
    } else if (type == 4) {
      this.topage(1)
    } else if (type == 5) {
      this.topage(6)
    } else if (type == 6) {
      this.topage(29)
    } else if (type == 7) {
      this.topage(27)
    } else if (type == 8) {
      this.topage(4)
    } else {
      this.$router.push({
        name: 'allCourse'
      })
    }
  },
  methods: {
    topage (type) {
      this.$router.push({
        name: 'list',
        query: {
          type: type
        }
      })
    }
  }
}
</script>