<template>
  <div id="app">
    <headerIndex v-if="$route.meta.navShow && !isMobile() && $route.name != null"></headerIndex>
    <router-view style="flex: 1 1 auto"
                 v-if="isRouterAlive"></router-view>
    <footerIndex v-if="$route.meta.navShow && !isMobile() && $route.name != null"></footerIndex>
    <nopage v-if="$route.name == null"></nopage>
  </div>
</template>
<script>
import headerIndex from './components/index/header';
import footerIndex from './components/index/footer';
import nopage from './components/index/404';
import * as sysTool from './assets/units/GetuserAgent';
// import versionTood from './assets/units/versionUpdate'
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload,
    };
  },
  data () {
    return {
      isRouterAlive: true,
      baseUrl: 'https://h5.gp519.com/',
    };
  },
  watch: {
    '$route': {
      handler (to) {
        this.httphelper.post('CGpUserApi/SaveUserBehavior', {
          userID: localStorage.getItem('ResData') == null ? 0 : JSON.parse(localStorage.getItem('ResData')).id,
          ip: localStorage.getItem('Ip') == null ? '未获取' : localStorage.getItem('Ip'),
          area: localStorage.getItem('cityname') == null ? '未获取' : localStorage.getItem('cityname'),
          weburl: window.location.origin + to.fullPath,
          os: sysTool.getOS(),
          browser: sysTool.getBrowser(),
          network: sysTool.getNetworkType()
        });
      },
      immediate: true
    }

  },
  components: {
    headerIndex,
    footerIndex,
    nopage
  },
  created () {
    // versionTood.isNewVersion()
    if (this.isMobile()) {
      this.getLogin();
    }
    this.GetWebdiscount()
  },
  mounted () {
    document.oncontextmenu = function () {
      return false;
    };
    const script = document.createElement('script');
    script.src = 'https://s4.cnzz.com/z_stat.php?id=1279993300&web_id=1279993300';
    script.language = 'JavaScript';
    document.body.appendChild(script);
  },
  methods: {
    /** 获取是否开启六折 */
    GetWebdiscount () {
      this.httphelper.get('CGPInfoApi/GetWebdiscount').then(res => {
        if (res.code == 200) {
          const startDateStr = res.data.startDate;
          const endDateStr = res.data.endDate;
          const isBetweenDates = this.isCurrentTimeBetweenDates(startDateStr, endDateStr);

          if (isBetweenDates) {
            console.log('当前时间在指定日期范围内');
            localStorage.setItem('isSix', true)
          } else {
            console.log('当前时间不在指定日期范围内');
            localStorage.setItem('isSix', false)
          }
        }
      })
    },

    /** 判断是否在六折期间 */
    isCurrentTimeBetweenDates (startDateStr, endDateStr) {
      // 将字符串转换为Date对象  
      const now = new Date();
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      // 确保startDate在endDate之前  
      if (startDate > endDate) {
        const temp = startDate;
        startDate = endDate;
        endDate = temp;
      }

      // 判断当前时间是否在日期范围内  
      return now >= startDate && now <= endDate;
    },

    reload () {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    delUrlData (name) {
      var url = window.location.href;
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        //参数名前缀
        var prefix = encodeURIComponent(name) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //循环查找匹配参数
        for (var i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            //存在则删除
            pars.splice(i, 1);
          }
        }
        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    },
    async getLogin () {
      var _tokenstatus = this.$route.query.tokenkey;
      if (_tokenstatus != 'false' && _tokenstatus != '' && _tokenstatus != null) {
        await this.httphelper
          .post('LoginApi/CheckToken', {
            tokenKey: _tokenstatus,
          })
          .then(async res => {
            if (res.code == 200) {
              if (localStorage.getItem('token_newgphome') != _tokenstatus) {
                localStorage.setItem('token_newgphome', _tokenstatus);
                await this.getUserInfo(res.data.id, 1);
              }
            } else {
              localStorage.clear();
              let curl = this.delUrlData('tokenkey');
              window.location.href = curl;
            }
          });
      } else if (this.$route.query.openid || this.$route.query.userid) {
        await this.httphelper
          .post('LoginApi/GetUserInfoByOpenid', {
            openid: this.$route.query.openid || '',
            userid: Number(this.$route.query.userid) || 0
          })
          .then(async res => {
            if (res.code == 200) {
              if (localStorage.getItem('token_newgphome') != res.data.token) {
                localStorage.setItem('token_newgphome', res.data.token);
                await this.getUserInfo(res.data.id, 2);
              }
            } else {
              localStorage.clear();
              if (this.$route.query.userid) {
                let curl = this.delUrlData('userid');
                window.location.href = curl;
              } else if (this.$route.query.openid) {
                let curl = this.delUrlData('openid');
                window.location.href = curl;
              }
            }
          });
      } else {
        var toenkeys = localStorage.getItem('token_newgphome');
        if (toenkeys) {
          this.httphelper
            .post('LoginApi/CheckToken', {
              tokenKey: toenkeys,
            })
            .then(res => {
              if (res.code == 200) {
                this.getUserInfo(res.data.id, 0);
              } else {
                localStorage.clear();
                let curl = this.delUrlData('tokenkey');
                window.location.href = curl;
              }
            });
        }
      }
    },
    /**获取用户信息 */
    async getUserInfo (authorId, removetoke) {
      await this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: parseInt(authorId),
        })
        .then(async res => {
          if (res.code == 200) {
            res.data.id = authorId;
            localStorage.setItem('ResData', JSON.stringify(res.data));
            var aData = new Date();
            var CurrentDategetTime = 0
            var VipEdDategetTime = 0
            if (res.data.memberDate.m_EndDate) {
              var CurrentDate = aData.getFullYear() + '-' + (aData.getMonth() + 1) + '-' + aData.getDate() + ' ' + aData.getHours() + ':' + aData.getMinutes() + ':' + aData.getSeconds();
              var VipEdDate = res.data.memberDate.m_EndDate;
              CurrentDategetTime = new Date(CurrentDate.replace(/-/g, '/')).getTime() / 1000;
              VipEdDategetTime = new Date(VipEdDate.replace(/-/g, '/')).getTime() / 1000;
            }

            if (res.data.memberDate.m_EndDate == null || parseInt(CurrentDategetTime) - parseInt(VipEdDategetTime) >= 0) {
              this.userType = 0;
            } else {
              if (res.data.memberDate.m_Type == 1) {
                this.userType = 1;
              } else if (res.data.memberDate.m_Type == 2) {
                this.userType = 2;
              } else {
                this.userType = 0;
              }
            }
            await localStorage.setItem('userType', this.userType);
            this.info = res.data;
            // this.getUnreadmsg();
            if (removetoke == 1) {
              let curl = this.delUrlData('tokenkey');
              window.location.href = curl;
            } else if (removetoke == 2) {
              if (this.$route.query.userid) {
                let curl = this.delUrlData('userid');
                window.location.href = curl;
              } else if (this.$route.query.openid) {
                let curl = this.delUrlData('openid');
                window.location.href = curl;
              }
            }
          }
        });
    },
  },
};
</script>
<style>
@import './assets/css/comm.css';
@import './assets/css/globe.css';
#app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
</style>
