<template>
  <div>
    <div class="mid4 EC_width display_center_ud">
      <div v-if="info.labelName" class="display_center_ud">
        <div class="m4bq" :style="index == 1 ? 'background: #FF6600;' : (index == 0 ? 'margin-left:0px;' : '')"
          v-for="(item, index) in info.labelName.split(',').slice(0, 3)" v-show="item != ''" @click="tobiaoqian(item)"
          :key="index">
          <span>{{ item }}</span>
        </div>
      </div>

      <!-- <div class="m4bq"
             style="background: #FF6600;margin-left:6px;">
          <span>特惠</span>
        </div> -->
      <div class="m4people display_center_ud">
        <img src="../../assets/icons/people5.png" class="m4pimg" alt="">
        <span>{{ info.playSum }}个人学过</span>
      </div>
      <div class="m4people display_center_ud" v-if="info.is_Group == true">
        <img src="../../assets/icons/qun.png" alt="">
        <span>加入课程交流群，获取更多资源与信息！</span>
      </div>
      <div v-if="info.is_Group == true">
        <el-popover placement="top" width="212" trigger="hover">
          <div class="display_column_start_center">
            <img class="wxcode" style=" width: 200px;height: 200px;" :src="info.group_ResUrl" alt="">
            <!-- <span>微信扫一扫分享</span> -->
          </div>
          <div class="m4code display_center_ud" slot="reference">
            <img src="../../assets/icons/code.png" alt="">
            <span>扫码加入 >></span>
          </div>
        </el-popover>
      </div>
    </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      cid: 0,
      uid: 0
    }
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  watch: {
  },
  created() {
    if (this.$route.query.courseId) {
      this.cid = Number(this.$route.query.courseId)
    }
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
    }
  },
  methods: {
    tobiaoqian(item) {
      this.$router.push({
        name: 'allCourse',
        query: {
          bqStr: encodeURIComponent(item)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mid4 {
  height: 26px;
  margin-bottom: 35px;

  .m4bq {
    // width: 86px;
    height: 26px;
    background: #ff9000;
    border-radius: 1px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 0 6px;
    margin-left: 6px;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }

  .m4people {
    height: 100%;
    margin-left: 25px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;

    .m4pimg {
      width: 12px;
      height: 14px;
    }
  }

  .m4code {
    height: 100%;
    margin-left: 25px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0b8586;
    line-height: 14px;
    cursor: pointer;
  }

  img {
    margin-right: 10px;
    margin-top: -2px;
  }

  .wxcode {
    width: 110px;
    height: 110px;
  }
}
</style>