<template>
  <div>
    <div class="mid2 display_between">
      <div class="m2box display_between_nocenter" @click="toExpert">
        <div class="m2left display_column">
          <span class="m2title">专家知家</span>
          <span class="m2text">以知促行 以行求知</br>专家邀您一起优质高效学习</span>
        </div>
        <img src="../../../assets/images/home/mzhuanjia.png" alt="">
      </div>
      <div class="m2box display_between_nocenter" @click="toOrgan">
        <div class="m2left display_column">
          <span class="m2title">机构知家</span>
          <span class="m2text">特色模式 典型案例</br>广大社区卫生服务机构共建共享精彩</span>
        </div>
        <img src="../../../assets/images/home/mjigou.png" alt="">
      </div>
    </div>
    <div class="mid3 display_between">
      <div class="m3box display_column_between_center" @click="toBtn(1)">
        <img src="../../../assets/icons/home/m1.png" alt="">
        <span>临床技能</span>
      </div>
      <div class="m3box display_column_between_center" @click="toBtn(2)">
        <img src="../../../assets/icons/home/m2.png" alt="">
        <span>中医技能</span>
      </div>
      <div class="m3box display_column_between_center" @click="toBtn(3)">
        <img src="../../../assets/icons/home/m3.png" alt="">
        <span>康复技能</span>
      </div>
      <div class="m3box display_column_between_center" @click="toBtn(4)">
        <img src="../../../assets/icons/home/m4.png" alt="">
        <span>用药方案</span>
      </div>
    </div>
    <div class="mid4">
      <div class="m4top display_between_nocenter">
        <div class="m4box" :class="'bgc' + (index + 1)" @click="toMore(item.id)" v-for="(item, index) in m4list.slice(0, 3)"
          :key="index">
          {{ item.ntitle }}
        </div>
      </div>
      <div class="m4bot display_between_nocenter">
        <div class="m4box" :class="'bgc' + (index + 4)" @click="toMore(item.id)" v-for="(item, index) in m4list.slice(3, 6)"
          :key="index">
          {{ item.ntitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      m4list: []
    }
  },
  created() {
    this.getm4List()
  },
  props: {
    toMore: {
      type: Function,
      default: null
    },
    toRecordMp3: {
      type: Function,
      default: null
    },
    isMp3IngId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    getm4List() {
      this.httphelper
        .get('CourseApi/GetHomeCourseNaviList', {
          TypeId: 2,
          TypeClass: 1
        })
        .then(res => {
          if (res.code == 200 && res.data.length > 0) {
            this.m4list = res.data
          }
        });
    },
    toExpert() {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      var callback = encodeURIComponent(window.location.href);
      window.location.href = this.httphelper.IsDebugH5GP519() + '/pages/expert/list/list?callback=' + callback
    },
    toOrgan() {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      var callback = encodeURIComponent(window.location.href);
      window.location.href = this.httphelper.IsDebugH5GP519() + '/pages/organ/list/list?callback=' + callback
    },
    toBtn(id) {
      this.$router.push({
        name: 'list',
        query: {
          type: id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mid2 {
  margin: 3px 17px;
  height: 78px;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 4px 9px;

  .m2box {
    padding-left: 10px;
    width: 50%;

    img {
      width: 54px;
      height: 55px;
      margin: 0 5px;
    }

    .m2title {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      line-height: 15px;
      margin-bottom: 8px;
    }

    .m2text {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #404040;
      line-height: 12px;
      text-align: left;
    }
  }
}

.mid3 {
  margin: 11px 17px 13px;
  height: 78px;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 0;

  .m3box {
    width: 25%;
    height: 100%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 14px;

    img {
      width: auto;
      height: 26px;
    }
  }
}

.mid4 {
  margin-bottom: 16px;
  padding: 0 17px;

  .m4top {
    margin-bottom: 6px;
  }

  .m4box {
    width: 32%;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
  }

  .bgc1 {
    background: #efe6dc;
  }

  .bgc2 {
    background: #dee2f2;
  }

  .bgc3 {
    background: #dff0ef;
  }

  .bgc4 {
    background: #f5dfd6;
  }

  .bgc5 {
    background: #d9e4f1;
  }

  .bgc6 {
    background: #e2ddf7;
  }
}
</style>