<template>
  <div>
    <div class="botbox EC_width display_flex">
      <div class="botleft">
        <div class="bltop display_flex">
          <div class="blbq"
               @click="botIndex=1"
               :style="botIndex==1?'background: #0B8586;color:#fff':''">
            <span>课程介绍</span>
          </div>
          <div class="blbq"
               @click="botIndex=2"
               v-show="cid!=0"
               :style="botIndex==2?'background: #0B8586;color:#fff':''">
            <span>目录({{mp4List.length}})</span>
          </div>
          <div class="blbq"
               v-show="info.is_Coment"
               @click="botIndex=3"
               :style="botIndex==3?'background: #0B8586;color:#fff':''">
            <span>讨论区</span>
          </div>
        </div>
        <div class="blbot"
             v-show="botIndex==1">
          <img v-if="info!=null"
               :src="info.details_ResUrl"
               alt="">
        </div>
        <div class="blbot2"
             v-show="botIndex==2">
          <div class="blb2box"
               v-for="(item,index) in mp4List"
               :style="index==0?'border-top:none':''"
               @click="toCourse(item)"
               :key="index">
            <div class="display_center_ud">
              <div class="blb2bq"
                   :style="item.courseId==cidIng?'background: #08A4A5;':''">
                <span>{{item.res_Type==2?'视频':(item.res_Type==3?'音频':'电子书')}}</span>
              </div>
              <div class="blb2bq"
                   v-if="info!=null"
                   v-show="(item.isApply&&!info.isPurchase)&&!((info.discountStatus == 2 && uid != 0) || (info.discountStatus == 1 && userType != 0)|| (info.discountStatus == 3 && userType == 1))"
                   style="color:#fff;background:  #E60012;">
                <span>试看</span>
              </div>
              <div class="blb2title"
                   :style="item.courseId==cidIng?'color: #08A4A5;':''">
                <span>{{item.title}}</span>
              </div>
              <img src="../../assets/icons/boFang.png"
                   v-show="item.courseId==cidIng&&isBofang==false"
                   alt="">
              <img src="../../assets/gifs/bofang.gif"
                   v-show="item.courseId==cidIng&&isBofang==true"
                   alt="">
            </div>
            <div class="blb2tech display_center_ud">
              <span>讲者：{{item.expertName}}</span>
              <div></div>
              <span>单位：{{item.unit}}</span>
            </div>
            <div class="blb2time display_center_ud"
                 v-show="item.res_Type!=4">
              <span>时长：
                <span :class="item.res_duration > item.standingtime ? 'onRed' : ''"
                      style="font-weight: 800;">{{item.standingtime==0?'0:00':item.standingtime.toFixed(2).toString().replace('.', ':')}}</span>
                <span>/{{item.res_duration.toFixed(2).toString().replace('.', ':')}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="blbot3"
             v-show="botIndex==3&&info.is_Coment">
          <div class="blb3top">
            <span class="blb3t1">分享你的学习经验吧！</span>
            <textarea placeholder-style='color:#C0C4CC;'
                      v-model="text"
                      placeholder="记录你的点点滴滴"></textarea>
            <div class="blb3t2">
              <span>{{text.length}}</span>
              <span>/500</span>
            </div>
            <div class="blb3t3"
                 @click="toComment">
              <span>发布</span>
            </div>
          </div>
          <div class="blb3mid">
            <div class="noComment display_column_center"
                 v-show="commentList.length==0">
              <img src="../../assets/images/detail/noComment.png"
                   alt="">
              <span>暂无评论</span>
            </div>
            <div class="commentbox display_flex"
                 v-for="(item, index) in commentList"
                 :key="index">
              <img :src="item.userAvatar"
                   class="comtx"
                   alt="">
              <div class="comment display_column">
                <div class="comname">
                  <span>{{samsung(item.userName)}}</span>
                </div>
                <div class="comtext">
                  <span v-html="item.comentInfo"></span>
                </div>
                <div class="commore display_between">
                  <div class="comleft">
                    <span>{{item.comentTime}}</span>
                    <!-- <span v-show="item.state==1"
                          style="color:#e6a23c">(审核中)</span>
                    <span v-show="item.state==-1"
                          style="color:#f56c6c">(已驳回)</span> -->
                  </div>
                  <div class="comright display_center_ud">
                    <!-- <div class="comzan display_center_ud">
                      <img src="../../assets/icons/zan2.png"
                           alt="">
                      <span>点赞</span>
                      <span>0</span>
                    </div>
                    <div class="comread display_center_ud">
                      <img src="../../assets/icons/read.png"
                           alt="">
                      <span>阅读</span>
                      <span>0</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="botright">
        <div class="brtop display_between">
          <div class="brtl">
            <span>授课专家</span>
          </div>
          <div class="brtr display_center_ud"
               v-if="expertsList.length>1">
            <div class="brtrl display_center_ud">
              <img src="../../assets/icons/bg-left.png"
                   @click="TeachExpertIndex--"
                   v-show="TeachExpertIndex!=0"
                   style="cursor: pointer;"
                   alt="">
              <img src="../../assets/icons/left.png"
                   v-show="TeachExpertIndex==0"
                   alt="">
            </div>
            <div class="brtrr display_center_ud">
              <img src="../../assets/icons/bg-right.png"
                   @click="TeachExpertIndex++"
                   style="cursor: pointer;"
                   v-show="TeachExpertIndex<expertsList.length-1"
                   alt="">
              <img src="../../assets/icons/right.png"
                   v-show="TeachExpertIndex>=expertsList.length-1"
                   alt="">
            </div>
          </div>
        </div>
        <div class="brbox display_column_between_center"
             v-if="expertsList.length>0">
          <img class="brtouxiang"
               v-show="!istxloading"
               :src="expertsList[TeachExpertIndex].headPortraitUrl"
               alt="">
          <img src="../../assets/icons/loading.png"
               v-show="istxloading"
               class="brtouxiang2"
               alt="">
          <span class="brname">{{expertsList[TeachExpertIndex].name}}</span>
          <span class="brtech">{{expertsList[TeachExpertIndex].unit}}</span>
          <div class="brbtn"
               v-show="expertsList[TeachExpertIndex].isDredg"
               @click="toExpert(expertsList[TeachExpertIndex].id)">
            <span>进入个人主页</span>
          </div>
          <!-- <div class="brbtn"></div> -->
          <div class="brjianjie">
            <span>{{expertsList[TeachExpertIndex].detailsInfo}}</span>
          </div>
          <img src="../../assets/images/detail/expbgtop.png"
               class="brbgtop"
               alt="">
          <img src="../../assets/images/detail/expbgright.png"
               class="brbgright"
               alt="">
        </div>
        <div class="brtop display_between"
             v-show="CoursesList.length>0">
          <div class="brtl">
            <span>相关课程</span>
          </div>
          <div class="brtr2 display_center_ud">
          </div>
        </div>
        <div v-for="(item,index) in CoursesList"
             :key="index">
          <BotCourse :info="item"></BotCourse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BotCourse from "../../components/detail/course.vue"
export default {
  name: 'videoDetail',
  components: {
    BotCourse
  },
  data () {
    return {
      botIndex: 1,
      CoursesList: [],
      commentList: [],
      cid: 0,
      uid: 0,
      TeachExpertIndex: 0,
      istxloading: false,
      //   info: '',
      cidIng: 0,
      text: '',
      userType: 1,
    }
  },
  props: {
    mp4List: [],
    expertsList: [],
    toCourse: {
      type: Function,
      default: null
    },
    isBofang: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: null
    }
  },
  watch: {
    TeachExpertIndex: function () {
      this.istxloading = true
      var that = this
      setTimeout(() => {
        that.istxloading = false
      }, 100);
    },
    $route: {
      handler () {
        this.cidIng = Number(this.$route.query.cidIng)
        if (this.cid != this.$route.query.courseId) {
          this.cid = Number(this.$route.query.courseId)
          //   this.getCommentList()
        }
      },
      deep: true
    },
    info: {
      immediate: true,
      deep: true,
      handler () {
        if (Object.keys(this.info).length > 0) {
          this.getCourses()
        }
      }

    }
  },
  created () {
    if (this.$route.query.courseId) {
      this.cid = Number(this.$route.query.courseId)
      this.cidIng = Number(this.$route.query.cidIng)
    }
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType')
    } else {
      this.userType = 0
    }
    this.getCommentList()
  },
  methods: {
    getCommentList () {
      this.httphelper
        .post('CGPInfoApi/GetCourseCommentList', {
          courseId: this.cid == 0 ? this.cidIng : this.cid,
          userId: this.uid
        })
        .then(res => {
          if (res.data != null) {
            this.commentList = res.data;
          }
        })
    },
    samsung (str) {
      var replcenem = '';
      //三位姓名的中间变星号
      if (str.length >= 3) {
        replcenem = str.replace(/^(.+).(.)$/, '$1*$2');
        return replcenem;
      }
      if (str.length == 2) {
        //两位的姓名，后面以为变星号
        var strSplit = str.split('');
        var strSplitone = strSplit[0];
        replcenem = strSplitone + '*';
        return replcenem;
      }
    },
    toComment () {
      if (this.text == '') {
        this.$message({
          message: '评论内容不可为空',
          type: 'warning'
        });
      } else if (this.uid == 0) {
        this.$message({
          message: '请先登录',
          type: 'warning'
        });
      } else {
        this.httphelper
          .post('CGPInfoApi/AddCourseComment', {
            coment_Info: this.text,
            coment_Course: this.cid == 0 ? this.cidIng : this.cid,
            coment_User: this.uid
          })
          .then(res => {
            if (res.code == 249) {
              this.text = '';
              this.$message({
                message: '发布成功',
                type: 'success'
              });
            } else if (res.code == 200) {
              this.text = '';
              this.$message({
                message: '发布成功',
                type: 'success'
              });
            }
            this.getCommentList();
          })
      }
    },
    getCourses () {
      this.httphelper
        .post('CGPInfoApi/GetDbGpRelatedRecommendedCoursesList', {
          courseType: this.info.video_Type,
          expertId: this.info.expertlist[0].id.toString(),
          labelgather: this.info.labelgather,
          id: this.cid == 0 ? this.cidIng : this.cid
        })
        .then(res => {
          if (res.data != null) {
            this.CoursesList = res.data.slice(0, 4);
          }
        })
    },
    toExpert (id) {
      window.open("https://gp519.com/expertsDetail?id=" + id, "_blank");
    }
  }
}
</script>
<style lang="scss" scoped>
.botbox {
    .botleft {
        width: 850px;
        margin-right: 20px;
        .bltop {
            height: 51px;
            border-bottom: 1px solid #36c0c0;
            .blbq {
                height: 50px;
                padding: 14px 35px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000;
                line-height: 22px;
                cursor: pointer;
            }
        }
        .blbot {
            background: #ffffff;
            border: 1px solid #eeeeee;
            box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
            padding: 24px;
            img {
                width: 800px;
            }
        }
        .blbot2 {
            background: #ffffff;
            border: 1px solid #eeeeee;
            box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
            padding: 30px;
            .blb2box {
                padding: 20px 10px;
                cursor: pointer;
                border-top: 10px solid #f6f6f6;
                .blb2bq {
                    width: 50px;
                    height: 18px;
                    background: #c7c7c7;
                    border-radius: 1px;
                    text-align: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fff;
                    line-height: 18px;
                    margin-top: 2px;
                    margin-right: 15px;
                }
                .blb2title {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #000000;
                    line-height: 24px;
                    margin-right: 20px;
                }
                .blb2tech {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #696969;
                    line-height: 50px;
                    div {
                        width: 1px;
                        height: 14px;
                        background-color: #696969;
                        margin: 0 20px;
                    }
                }
                .blb2time {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #696969;
                    line-height: 14px;
                }
            }
        }
        .blbot3 {
            //   min-height: 100%;
            .blb3top {
                width: 850px;
                height: 270px;
                background: #ffffff;
                border: 1px solid #eeeeee;
                box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
                padding: 30px 40px;
                position: relative;
                .blb3t1 {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 16px;
                }
                textarea {
                    width: 768px;
                    height: 107px;
                    background: #f8f8f8;
                    border: 1px solid #dcdfe6;
                    border-radius: 3px;
                    margin: 17px 0 21px;
                    padding: 10px 13px;
                    box-sizing: border-box;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                }
                .blb3t2 {
                    position: absolute;
                    top: 40px;
                    right: 40px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #c0c4cc;
                    line-height: 14px;
                }
                .blb3t3 {
                    width: 180px;
                    height: 36px;
                    background: #0b8586;
                    border-radius: 18px;
                    margin: 0 auto;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 36px;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .blb3mid {
                padding: 18px 40px 30px;
                margin-top: 9px;
                width: 850px;
                //   min-height: 1003px;
                background: #ffffff;
                border: 1px solid #eeeeee;
                box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
                .noComment {
                    //   height: 355px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #9f9f9f;
                    margin: 200px auto 501px;
                    img {
                        margin-bottom: 30px;
                    }
                }
                .commentbox {
                    padding: 30px 0;
                    border-bottom: 1px solid #eee;
                    .comtx {
                        width: 47px;
                        height: 47px;
                        border-radius: 50%;
                        margin-right: 18px;
                    }
                    .comment {
                        // width: 100%;
                        flex: 1;
                        .comname {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #000000;
                            line-height: 25px;
                            margin-bottom: 10px;
                        }
                        .comtext {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #353535;
                            line-height: 26px;
                            margin-bottom: 15px;
                        }
                        .commore {
                            .comleft {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #adadad;
                                line-height: 14px;
                                span {
                                    margin-right: 10px;
                                }
                            }
                            .comright {
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #000000;
                                line-height: 12px;
                                img {
                                    margin-right: 8px;
                                }
                                .comread {
                                    margin-left: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .botright {
        width: 330px;
        .brtop {
            height: 51px;
            border-bottom: 1px solid #36c0c0;
            .brtl {
                height: 50px;
                padding: 14px 0px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000;
                line-height: 22px;
            }
            .brtr {
                .brtrl {
                    margin-right: 7px;
                }
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            .brtr2 {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #404040;
                line-height: 14px;
                cursor: pointer;
                img {
                    margin-right: 5px;
                }
            }
        }
        .brbox {
            width: 330px;
            height: 551px;
            background: #ffffff;
            border: 1px solid #edeeee;
            box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
            padding: 31px 0 34px;
            margin-bottom: 30px;
            position: relative;
            .brtouxiang {
                width: 180px;
                height: 180px;
                border-radius: 50%;
                margin-bottom: 15px;
            }
            .brtouxiang2 {
                width: 30px;
                height: 30px;
                margin: 75px;
                margin-bottom: 90px;
            }
            .brname {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                line-height: 24px;
                z-index: 100;
            }
            .brtech {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #404040;
                line-height: 24px;
                z-index: 100;
                margin: 0 10px;
            }
            .brbtn {
                width: 140px;
                height: 29px;
                border: 1px solid #0b8586;
                border-radius: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0b8586;
                line-height: 27px;
                text-align: center;
                cursor: pointer;
            }
            .brjianjie {
                height: 175px;
                width: 100%;
                padding: 0 25px;
                margin-top: 10px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #404040;
                line-height: 26px;
                overflow-y: scroll;
                scrollbar-color: transparent transparent;
                scrollbar-arrow-color: #f0f2f5; /* 设置滚动条上的箭头颜色  */
                scrollbar-base-color: #f0f2f5; /* 设置滚动条的底色 */
                scrollbar-track-color: #f0f2f5; /* 设置滚动条块的背景色 */
                scrollbar-shadow-color: #f0f2f5; /* 设置箭头和滚动条右侧和底边的颜色 */
            }
            .brjianjie::-webkit-scrollbar {
                /* 滚动条宽 */
                width: 6px;
                height: 4px;
            }
            .brjianjie::-webkit-scrollbar-thumb {
                /* 滚动条 拖动条 */
                background: #0b86864d;
                opacity: 0.3;
                border-radius: 6px;
            }
            .brjianjie::-webkit-scrollbar-track {
                /* 滚动条背景槽 */
                background-color: #fff;
                border-radius: 6px;
            }
            .brbgtop {
                position: absolute;
                top: 0;
                left: 0;
            }
            .brbgright {
                position: absolute;
                top: 120px;
                right: 0;
            }
        }
    }
}
.onRed {
    color: red;
}
</style>