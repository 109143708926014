<template>
  <div>
    <div class="box display_between">
      <div class="boxleft display_center_ud">
        <img :src="imgUrl"
             alt="">
        <span>{{title}}</span>
      </div>
      <div class="boxmid">
        <slot></slot>
      </div>
      <div v-if="!isMore"></div>
      <div class="boxright display_center_ud"
           v-if="isMore"
           @click="toMore!=null?toMore(type):''">
        <img src="../../assets/icons/more.png"
             alt="">
        <span>更多</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    toMore: {
      type: Function,
      default: null
    },
    isMore: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #eee;
    .boxleft {
        border-bottom: 1px solid #000000;
        line-height: 55px;
        img {
            width: 25px;
            height: 28px;
            margin-right: 14px;
            margin-bottom: -2px;
        }
        span {
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
        }
    }
    .boxmid {
        flex: 1;
        padding-left: 28px;
    }
    .boxright {
        cursor: pointer;
        line-height: 14px;
        img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
        }
        span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #404040;
        }
    }
}
</style>