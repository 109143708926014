<template>
    <div>
        <div class="list">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="row display_between_nocenter" v-for="(item, index) in courseList" :key="index + 'f'">
                    <Mcourse :info="items" :callback="callback" style="width: 47%" v-for="(items, indexs) in item" :key="indexs + 's'"></Mcourse>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
import Mcourse from '../../../components/mobile/course/index.vue';
export default {
    data() {
        return {
            loading: false,
            finished: false,
            loadTag: 1,
            pageIndex: 0,
        };
    },
    props: {
        courseList: [],
        pageCount: {
            type: Number,
            default: 0,
        },
        getAllCoures: {
            type: Function,
            default: null,
        },
        callback: {
            type: String,
            default: '',
        },
    },
    components: {
        Mcourse,
    },
    watch: {
        courseList() {
            if (this.courseList.length == 0) {
                this.finished = true;
            }
        },
    },
    created() {},
    methods: {
        onLoad() {
            if (this.loadTag == 1) {
                this.pageIndex++;
                this.loadTag = 0;
                this.getAllCoures(this.pageIndex);
                this.loadTag = 1;
            }
            setTimeout(() => {
                this.loading = false;
                if (this.courseList.length >= this.pageCount / 2) {
                    this.finished = true;
                }
            }, 500);
        },
    },
};
</script>
<style lang="scss" scoped>
.list {
    margin: 0 17px;
    .row {
        margin-bottom: 10px;
    }
}
</style>
