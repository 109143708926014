<template>
  <div style="padding-bottom: 50px">
    <div class="top display_between">
      <img class="tleft"
           @click="toFanhui"
           src="../../../assets/icons/fanhui.png"
           alt="" />
      <span>{{ title }}</span>
      <img class="tright"
           src="../../../assets/icons/search.png"
           @click="$router.push('/mSearch')"
           alt="" />
    </div>
    <div class="every"></div>
    <div class="top2 display_between">
      <div class="t2left display_center_ud">
        <div :style="midIndex == 0 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;margin-left:0' : 'margin-left:0'"
             @click="midIndex = 0">全部</div>
        <div :style="midIndex == 1 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''"
             @click="midIndex = 1">会员专享
        </div>
        <div :style="midIndex == 2 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''"
             @click="midIndex = 2">免费
        </div>
      </div>
      <div class="t2right display_center_ud"></div>
    </div>
    <div class="top3 display_center_ud">
      <div :style="midIndex2 == 0 ? 'color: #fff;background: #0B8586;border-color:#0B8586;' : ''"
           @click="midIndex2 = 0"
           class="mlbq2">默认</div>
      <div :style="midIndex2 == 1 ? 'color: #fff;background: #0B8586;border-color:#0B8586;' : ''"
           @click="midIndex2 = 1"
           class="mlbq2">最新</div>
      <div :style="midIndex2 == 2 ? 'color: #fff;background: #0B8586;border-color:#0B8586;' : ''"
           @click="midIndex2 = 2"
           class="mlbq2">最热</div>
    </div>
    <div class="top4">
      <MCourseList :courseList="courseList"
                   :pageCount="pageCount"
                   :callback="callback"
                   :getAllCoures="getAllCoures">
      </MCourseList>
    </div>
    <Mfooter></Mfooter>
  </div>
</template>
<script>
import MCourseList from '../../../components/mobile/course/courseList.vue';
import Mfooter from '../../../components/mobile/index/footer.vue';
import lodash from 'lodash';
export default {
  data () {
    return {
      midIndex: 0,
      midIndex2: 0,
      courseList: [],
      pageIndex: 1,
      pageCount: 0,
      title: '',
      callback: '',
    };
  },
  components: {
    MCourseList,
    Mfooter,
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    midIndex: function () {
      this.getAllCoures(this.pageIndex);
    },
    midIndex2: function () {
      this.getAllCoures(this.pageIndex);
    },
  },
  created () {
    this.callback = encodeURIComponent(window.location.href);

    this.getlogo();
    this.getAllCoures(this.pageIndex);
  },
  methods: {
    getlogo () {
      this.httphelper
        .get('CourseApi/GetCourseNavigConfig', {
          Id: this.type,
        })
        .then(res => {
          if (res.code == 200) {
            this.title = res.data.title;
            this.$h5weixinshare.WeiXinShare(res.data.title, window.location.href, '全科知家 知行合医，海量全科学习资源等您挖掘');
            //   this.wx.miniProgram.getEnv(res => {
            //     if (res.miniprogram) {
            //       alert("在小程序里");
            //       alert(window.location.href)
            //       this.wx.miniProgram.postMessage({
            //         data: {
            //           shareTitle: '【全科知家】' + this.info.title,
            //           page: '/pages/index/webview',
            //           shareUrl: window.location.href
            //         },
            //       });
            //     } else {
            //        alert("不在小程序里");
            //     }
            //   });
          }
        });
    },
    getAllCoures (index) {
      this.httphelper
        .post('CourseApi/GetNaviCoursePageList', {
          navId: this.type,
          courseType: this.midIndex,
          orderType: this.midIndex2,
          page: index,
          limit: 16,
        })
        .then(res => {
          if (res.code == 200) {
            if (index == 1) {
              this.courseList = lodash.chunk(res.data, 2);
            } else {
              if (this.courseList != lodash.chunk(res.data, 2)) {
                this.courseList = this.courseList.concat(lodash.chunk(res.data, 2));
              }
            }
            this.pageCount = res.totalcount;
          }
        });
    },
    toFanhui () {
      this.$router.push('/home');
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
    height: 46px;
    padding: 0 17px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

    .tleft {
        width: 17px;
        height: 15px;
    }

    .tright {
        width: 19px;
        height: 20px;
    }
}

.every {
    width: 100%;
    height: 10px;
    background: #f6f7fb;
}

.top2 {
    height: 50px;
    margin: 3px 17px 0;
    border-bottom: 1px solid #eee;

    .t2left {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 16px;
    }

    .t2left div {
        margin-left: 24px;
        padding: 15px 0;
        border-bottom: 2px solid #ffffff;
    }

    .t2right {
        .btnText {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 12px;
            margin-right: 5px;
        }

        .btnImg {
            width: 13px;
            height: 12px;
        }
    }
}

.top3 {
    margin: 11px 17px 15px;

    div {
        height: 25px;
        margin-right: 5px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        border: 1px solid #979fa6;
        border-radius: 25px;
        padding: 0 10px;
    }
}
</style>
