<template>
  <div style="padding-bottom: 50px">
    <div class="top display_between">
      <img src="../../../assets/images/home/mlogo.png" class="logo" alt="" />
      <div class="right display_center_ud">
        <img src="../../../assets/icons/search.png" class="search" @click="$router.push('/mSearch')" alt="" />
        <div class="denglu" @click="toLogin" v-show="!islogin">登录</div>
        <div class="denglu2" v-show="islogin" @click="toUrl(httphelper.IsDebugH5GP519() + '/pages/user/mymsg')">
          <img src="../../../assets/icons/lingdang.png" alt="" />
          <div class="msgnum" v-if="msgnum != 0">{{ msgnum }}</div>
        </div>
      </div>
    </div>
    <div class="top2">
      <van-tabs v-model="t2Index" @disabled="toNonePage" line-width="17px" line-height="2px" v-if="!iswxxcx">
        <van-tab title="首页">
          <div class="mid">
            <div class="mid1">
              <van-swipe class="my-swipe" :autoplay="3000">
                <van-swipe-item v-for="(item, index) in MoBannerswip" :key="index">
                  <img :src="item.resUrl" class="img" @click="toUrl(item.url)" alt="" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="jianbian">
              <img class="jianbianbg" src="../../../assets/images/home/jianbian.png" alt="" />
            </div>
            <MbtnBox :toMore="toMore" :toRecordMp3="toRecordMp3" :isMp3IngId="isMp3IngId"></MbtnBox>
            <div class="mid5">
              <MboxTitle :imgUrl="require('../../../assets/icons/home/t3.png')" title="精品课程" :type="15" :toMore="toMore">
              </MboxTitle>
              <div class="m5b display_column">
                <div class="m5row display_between_nocenter" v-for="(item, index) in m5List" :key="index">
                  <div class="m5box" v-for="(items, indexs) in item" :key="indexs + 's'">
                    <Mcourse :info="items" :callback="'home'"></Mcourse>
                  </div>
                </div>
                <div class="m5more display_center" @click="isM5Zhankai = !isM5Zhankai">
                  <img src="../../../assets/icons/home/more.png" :style="isM5Zhankai ? 'transform:rotate(180deg);' : ''"
                    alt="" />
                  <span v-show="!isM5Zhankai">展开更多</span>
                  <span v-show="isM5Zhankai">收起</span>
                </div>
              </div>
            </div>
            <div class="mid6">
              <MboxTitle :imgUrl="require('../../../assets/icons/home/t1.png')" title="专项课程" :type="16" :toMore="toMore">
              </MboxTitle>
              <div class="m6b display_column">
                <div class="m6box display_flex" v-for="(item, index) in m6List" @click="toCourse(item.id)" :key="index">
                  <img :src="item.acr_Pict" class="m6bimg" alt="" />
                  <div class="m6bright display_column">
                    <div class="m6btitle">
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="m6bbq display_flex" v-if="item.labelName">
                      <div class="m6bbqbox" v-for="(items, indexs) in item.labelName.split(',').slice(0, 2)"
                        v-show="items != ''" :key="indexs + 's'">
                        <span>{{ items }}</span>
                      </div>
                    </div>
                    <div class="m6bmore display_between">
                      <div class="m6bmorel">
                        <span v-show="item.discountStatus == 2">免费</span>
                        <span v-show="item.discountStatus == 1">VIP专享</span>
                        <div v-show="item.discountStatus == 3 && userType != 1">
                          <span>￥{{ Number(item.discount).toFixed(2) }}</span>
                          <span class="originalPrice" v-show="item.price > item.discount">原价￥{{
                            Number(item.price).toFixed(2) }}</span>
                        </div>
                        <span v-show="item.discountStatus == 3 && uesrType == 1">SVIP专享</span>
                      </div>
                      <div class="m6bmorer display_center_ud">
                        <img src="../../../assets/icons/people3.png" alt="" />
                        <span>{{ item.playSum }}人</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Mseparate"></div>
            <div class="mid7" v-if="m7List[m7Ing]">
              <MboxTitle :imgUrl="require('../../../assets/icons/home/t5.png')" title="项目专题" :isMore="false"> </MboxTitle>
              <div class="m7box" @click="toXmUrl(m7List[m7Ing].url)">
                <van-swipe class="m7swipe" @change="m7change" :autoplay="3000">
                  <van-swipe-item v-for="(item, index) in m7List" :key="index">
                    <img :src="item.resUrl" class="img" alt="" />
                  </van-swipe-item>
                </van-swipe>
                <div class="m7title">{{ m7List[m7Ing].title }}</div>
                <div class="m7text m7text1" v-show="!isM7show">{{ m7List[m7Ing].detail }}</div>
                <div class="m7text" v-show="isM7show">{{ m7List[m7Ing].detail }}</div>
                <div class="m7btn display_center" @click.stop="isM7show = !isM7show">
                  <span v-show="!isM7show">展开</span>
                  <span v-show="isM7show">收起</span>
                  <img v-show="!isM7show" src="../../../assets/icons/show1.png" alt="" />
                  <img v-show="isM7show" src="../../../assets/icons/show2.png" alt="" />
                </div>
              </div>
            </div>
            <div class="mid8" @click="toM8Url()">
              <img :src="midImgUrl" alt="" />
            </div>
            <div class="mid9">
              <MboxTitle :imgUrl="require('../../../assets/icons/home/t2.png')" title="全科知声" :type="m9BqIng"
                :toMore="toMore"> </MboxTitle>
              <div class="m9box">
                <div class="m9top display_flex noscroll">
                  <div class="m9bq" :class="m9BqIng == item.id ? 'active' : ''" :style="index == 0 ? 'margin-left:0' : ''"
                    v-for="(item, index) in m9BqList" @click="changeM9bqIng(item.id)" :key="index">
                    <span>{{ item.ntitle }}</span>
                  </div>
                </div>
                <div class="m9bot">
                  <div class="m9bbox display_between_nocenter" v-for="(item, index) in m9List"
                    :style="index == 0 ? 'border-top:0' : ''" @click="toMp3Ing(item)" :key="index">
                    <div class="m9bleft display_column">
                      <div class="m9btitle display_between_nocenter">
                        <span>{{ item.title }}</span>
                        <img src="../../../assets/gifs/bofang.gif" v-show="isMp3IngId == item.id" alt="" />
                      </div>
                      <div class="m9bbq display_flex" v-if="item.labelName">
                        <div class="m9bbqbox" v-for="(items, indexs) in item.labelName.split(',').slice(0, 2)"
                          v-show="items != ''" :key="indexs + 's'">
                          <span>{{ items }}</span>
                        </div>
                      </div>
                    </div>
                    <img src="../../../assets/images/home/mp32.png" class="m9bimg" alt="" />
                  </div>
                  <div class="nolist display_center" v-show="m9List.length == 0">暂无资源</div>
                </div>
              </div>
            </div>
            <div class="Mseparate"></div>
            <div class="mid10">
              <MboxTitle :imgUrl="require('../../../assets/icons/home/t4.png')" title="电子书" :type="17" :toMore="toMore">
              </MboxTitle>
              <div class="m10box display_flex noscroll">
                <div class="m10b display_column" v-for="(item, index) in m10List"
                  :style="index == 0 ? 'margin-left:0' : ''" @click="toCourse(item.id)" :key="index">
                  <img :src="item.ver_Pict" alt="" />
                  <div class="m10title">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab v-if="!iswxxcx" title="中医能力提升" title-class="big" url="https://h5tcm.cgphome.com/"></van-tab>
        <van-tab v-if="!isapp && !iswxxcx" title="下载app" disabled></van-tab>
        <van-tab v-if="isapp" title="每周会诊" disabled></van-tab>
      </van-tabs>
      <div class="mid">
        <div class="mid1">
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item v-for="(item, index) in MoBannerswip" :key="index">
              <img :src="item.resUrl" class="img" @click="toUrl(item.url)" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="jianbian">
          <img class="jianbianbg" src="../../../assets/images/home/jianbian.png" alt="" />
        </div>
        <MbtnBox :toMore="toMore" :toRecordMp3="toRecordMp3" :isMp3IngId="isMp3IngId"></MbtnBox>
        <div class="mid5">
          <MboxTitle :imgUrl="require('../../../assets/icons/home/t3.png')" title="精品课程" :type="15" :toMore="toMore">
          </MboxTitle>
          <div class="m5b display_column">
            <div class="m5row display_between_nocenter" v-for="(item, index) in m5List" :key="index">
              <div class="m5box" v-for="(items, indexs) in item" :key="indexs + 's'">
                <Mcourse :info="items" :callback="'home'"></Mcourse>
              </div>
            </div>
            <div class="m5more display_center" @click="isM5Zhankai = !isM5Zhankai">
              <img src="../../../assets/icons/home/more.png" :style="isM5Zhankai ? 'transform:rotate(180deg);' : ''"
                alt="" />
              <span v-show="!isM5Zhankai">展开更多</span>
              <span v-show="isM5Zhankai">收起</span>
            </div>
          </div>
        </div>
        <div class="mid6">
          <MboxTitle :imgUrl="require('../../../assets/icons/home/t1.png')" title="专项课程" :type="16" :toMore="toMore">
          </MboxTitle>
          <div class="m6b display_column">
            <div class="m6box display_flex" v-for="(item, index) in m6List" @click="toCourse(item.id)" :key="index">
              <img :src="item.acr_Pict" class="m6bimg" alt="" />
              <div class="m6bright display_column">
                <div class="m6btitle">
                  <span>{{ item.title }}</span>
                </div>
                <div class="m6bbq display_flex" v-if="item.labelName">
                  <div class="m6bbqbox" v-for="(items, indexs) in item.labelName.split(',').slice(0, 2)"
                    v-show="items != ''" :key="indexs + 's'">
                    <span>{{ items }}</span>
                  </div>
                </div>
                <div class="m6bmore display_between">
                  <div class="m6bmorel">
                    <span v-show="item.discountStatus == 2">免费</span>
                    <span v-show="item.discountStatus == 1">VIP专享</span>
                    <div v-show="item.discountStatus == 3 && userType != 1">
                      <span>￥{{ Number(item.discount).toFixed(2) }}</span>
                      <span class="originalPrice" v-show="item.price > item.discount">原价￥{{
                        Number(item.price).toFixed(2) }}</span>
                    </div>
                    <span v-show="item.discountStatus == 3 && uesrType == 1">SVIP专享</span>
                  </div>
                  <div class="m6bmorer display_center_ud">
                    <img src="../../../assets/icons/people3.png" alt="" />
                    <span>{{ item.playSum }}人</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Mseparate"></div>
        <div class="mid7" v-if="m7List[m7Ing]">
          <MboxTitle :imgUrl="require('../../../assets/icons/home/t5.png')" title="项目专题" :isMore="false"> </MboxTitle>
          <div class="m7box" @click="toXmUrl(m7List[m7Ing].url)">
            <van-swipe class="m7swipe" @change="m7change" :autoplay="3000">
              <van-swipe-item v-for="(item, index) in m7List" :key="index">
                <img :src="item.resUrl" class="img" alt="" />
              </van-swipe-item>
            </van-swipe>
            <div class="m7title">{{ m7List[m7Ing].title }}</div>
            <div class="m7text m7text1" v-show="!isM7show">{{ m7List[m7Ing].detail }}</div>
            <div class="m7text" v-show="isM7show">{{ m7List[m7Ing].detail }}</div>
            <div class="m7btn display_center" @click.stop="isM7show = !isM7show">
              <span v-show="!isM7show">展开</span>
              <span v-show="isM7show">收起</span>
              <img v-show="!isM7show" src="../../../assets/icons/show1.png" alt="" />
              <img v-show="isM7show" src="../../../assets/icons/show2.png" alt="" />
            </div>
          </div>
        </div>
        <div class="mid8" @click="toM8Url()">
          <img :src="midImgUrl" alt="" />
        </div>
        <div class="mid9">
          <MboxTitle :imgUrl="require('../../../assets/icons/home/t2.png')" title="全科知声" :type="m9BqIng" :toMore="toMore">
          </MboxTitle>
          <div class="m9box">
            <div class="m9top display_flex noscroll">
              <div class="m9bq" :class="m9BqIng == item.id ? 'active' : ''" :style="index == 0 ? 'margin-left:0' : ''"
                v-for="(item, index) in m9BqList" @click="changeM9bqIng(item.id)" :key="index">
                <span>{{ item.ntitle }}</span>
              </div>
            </div>
            <div class="m9bot">
              <div class="m9bbox display_between_nocenter" v-for="(item, index) in m9List"
                :style="index == 0 ? 'border-top:0' : ''" @click="toMp3Ing(item)" :key="index">
                <div class="m9bleft display_column">
                  <div class="m9btitle display_between_nocenter">
                    <span>{{ item.title }}</span>
                    <img src="../../../assets/gifs/bofang.gif" v-show="isMp3IngId == item.id" alt="" />
                  </div>
                  <div class="m9bbq display_flex" v-if="item.labelName">
                    <div class="m9bbqbox" v-for="(items, indexs) in item.labelName.split(',').slice(0, 2)"
                      v-show="items != ''" :key="indexs + 's'">
                      <span>{{ items }}</span>
                    </div>
                  </div>
                </div>
                <img src="../../../assets/images/home/mp32.png" class="m9bimg" alt="" />
              </div>
              <div class="nolist display_center" v-show="m9List.length == 0">暂无资源</div>
            </div>
          </div>
        </div>
        <div class="Mseparate"></div>
        <div class="mid10">
          <MboxTitle :imgUrl="require('../../../assets/icons/home/t4.png')" title="电子书" :type="17" :toMore="toMore">
          </MboxTitle>
          <div class="m10box display_flex noscroll">
            <div class="m10b display_column" v-for="(item, index) in m10List" :style="index == 0 ? 'margin-left:0' : ''"
              @click="toCourse(item.id)" :key="index">
              <img :src="item.ver_Pict" alt="" />
              <div class="m10title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Mfooter :toRecordMp3="toRecordMp3" :isMp3IngId="isMp3IngId"></Mfooter>
    <img src="../../../assets/images/home/z.png" alt="" v-show="zshow" @click="zshow = false" class="zImg">
  </div>
</template>
<script>
import Mfooter from '../index/footer.vue';
import MbtnBox from './btnBox.vue';
import MboxTitle from '../index/boxTitle.vue';
import Mcourse from '../course/index.vue';
import { Dialog } from 'vant';
import lodash from 'lodash';
export default {
  components: {
    Mfooter,
    MbtnBox,
    MboxTitle,
    Mcourse
  },
  data() {
    return {
      isapp: false,
      zshow: false,
      t2Index: 0,
      MoBannerswip: [],
      m5List: [],
      isM5Zhankai: false,
      m6List: [],
      m7Ing: 0,
      m7List: [],
      isM7show: false,
      islogin: false,
      userInfo: '',
      msgnum: 0,
      isMp3IngId: 0,
      Mp3Ing: null,
      callback: '',
      iswxxcx: false,
    };
  },
  props: {
    midImgUrl: {
      type: String,
      default: '',
    },
    midUrl: {
      type: String,
      default: '',
    },
    changeM9bqIng: {
      type: Function,
      default: null,
    },
    m9BqIng: {
      type: Number,
      default: 0,
    },
    m9BqList: [],
    m9List: [],
    m10List: [],
  },
  watch: {
    isM5Zhankai() {
      this.getM5List();
    },
  },
  created() {
    this.$h5weixinshare.WeiXinShare('全科知家 知行合医', window.location.href, '以知促行 以行求知 专家邀您一起优质高效学习');
    if (localStorage.getItem('ResData')) {
      this.userInfo = JSON.parse(localStorage.getItem('ResData'));
      this.islogin = true;
      this.getUnreadmsg();
    }
    this.callback = encodeURIComponent(window.location.href);
    this.getMoBanner();
    this.getM5List();
    this.getM6List();
    this.getM7List();
    this.isApp()
    this.wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        // alert("在小程序里");
        this.iswxxcx = true;
        this.wx.miniProgram.postMessage({
          data: {
            shareTitle: '全科知家',
            page: "/pages/index/webview",
            shareUrl: "https://cgphome.com",
            islogin: false
          },
        });
      } else {
        // alert("不在小程序里");
      }
    })
  },
  mounted() {

  },
  destroyed() {
    if (this.isMp3IngId != 0) this.toRecordMp3();
  },
  methods: {
    // 判断时候是app
    isApp() {
      if (window.location.href.indexOf('app') != -1) {
        localStorage.setItem('app', 1)
        this.isapp = true
      } else {
      
        if (localStorage.getItem('app')) {
          // localStorage.removeItem('app')
          this.isapp = true
        }
      }
    },

    toNonePage() {
      if (this.isapp) {
        Dialog.alert({
          title: '提示',
          message: '建设中...',
        });
      } else {
        this.openApp()
      }
    },
    getUnreadmsg() {
      this.httphelper
        .post('MessageApi/GetNewMessageCount', {
          userId: this.userInfo.id,
        })
        .then(res => {
          this.msgnum = res.data;
        });
    },
    toMore(index) {
      if (index == 0) {
        // console.log(11);
      } else {
        this.$router.push({
          name: 'list',
          query: {
            type: index,
          },
        });
      }
    },
    toCourse(id) {
      // this.$router.push({
      //     name: 'detail',
      //     query: {
      //         courseId: id,
      //         callback: 'home',
      //     },
      // });
      let urlss = window.location.origin + '/detail?courseId=' + id + '&callback=home';
      window.location.href = urlss;
    },
    getMoBanner() {
      this.httphelper
        .post('WebSetMessApi/GetRotationInfo', {
          type: 1,
          ruselocation: 2,
          source: 1,
        })
        .then(res => {
          if (res.code == 200) {
            this.MoBannerswip = res.data;
          }
        });
    },
    getM5List() {
      this.httphelper
        .post('CourseApi/GetNaviCoursePageList', {
          navId: 15,
          courseType: 0,
          orderType: 0,
          page: 1,
          limit: this.isM5Zhankai ? 10 : 4,
        })
        .then(res => {
          if (res.code == 200) {
            this.m5List = lodash.chunk(res.data, 2);
          }
        });
    },

    getM6List() {
      this.httphelper
        .post('CourseApi/GetNaviCoursePageList', {
          navId: 16,
          courseType: 0,
          orderType: 0,
          page: 1,
          limit: 2,
        })
        .then(res => {
          if (res.code == 200) {
            this.m6List = res.data;
          }
        });
    },
    m7change(e) {
      this.m7Ing = e;
    },
    getM7List() {
      this.httphelper
        .post('WebSetMessApi/GetRotationInfo', {
          type: 2,
        })
        .then(res => {
          if (res.code == 200) {
            this.m7List = res.data;
          }
        });
    },
    toUrl(url) {
      if (this.isMp3IngId != 0) this.toRecordMp3();
      window.open(url, '_blank');
    },
    toXmUrl(url) {
      if (this.isMp3IngId != 0) this.toRecordMp3();
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        var reg = new RegExp('(^|&)' + 'SpecialID' + '=([^&]*)(&|$)', 'i');
        var r = urlparts[1].match(reg);
        if (r != null) {
          window.open(this.httphelper.IsDebugH5GP519() + '/pages/home/project2?id=' + unescape(r[2]), '_blank');
        }
      }
    },
    toM8Url() {
      if (this.isMp3IngId != 0) this.toRecordMp3();
      window.open(this.midUrl, '_blank');
    },
    toLogin() {
      if (this.isMp3IngId != 0) this.toRecordMp3();
      var callback = encodeURIComponent(window.location.href);
      window.open(this.httphelper.IsDebugH5GP519() + '/pages/login/login?callback=' + callback, '_blank');
    },
    toMp3Ing(item) {
      if (this.isMp3IngId == 0 || this.isMp3IngId != item.id) {
        if (this.Mp3Ing != null || this.isMp3IngId != 0) {
          this.Mp3Ing.pause();
          this.Mp3Ing = null;
          this.isMp3IngId = 0;
        }
        if (this.islogin) {
          this.httphelper
            .post('/CGPInfoApi/GetCourseRes', {
              courseId: item.id,
              userId: 0,
            })
            .then(res => {
              this.Mp3Ing = new Audio(res.data);
              this.Mp3Ing.play();
              this.isMp3IngId = item.id;
            });
        } else {
          Dialog.alert({
            title: '提示',
            message: '请登录后播放音频',
            showCancelButton: true,
          }).then(() => {
            if (this.isMp3IngId != 0) this.toRecordMp3();
            let callback = encodeURIComponent(window.location.href);
            window.open(this.httphelper.IsDebugH5GP519() + '/pages/login/login?callback=' + callback, '_blank');
            return;
          });
        }
      } else {
        this.isMp3IngId = 0;
        this.Mp3Ing.pause();
        this.Mp3Ing = null;
      }
    },
    toRecordMp3() {
      this.Mp3Ing.pause();
      this.Mp3Ing = null;
      this.isMp3IngId = 0;
    },

    //打开app
    openApp() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        alert("暂未开通苹果商城");
      } else {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          // alert('这里是微信浏览器')
          this.zshow = true
        } else {
          window.location.href = 'cgp://'; // 安卓对应的app协议
          setTimeout(function () {
            let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden
            if (typeof hidden == "undefined" || hidden == false) {
              //应用宝下载地址
              window.open('https://h5rd.gp519.com/download')
            }
          }, 500);
          // this.android()
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.top {
  padding: 10px 17px;
  height: 57px;
  width: 100%;

  .logo {
    width: 122px;
    height: 37px;
  }

  .search {
    width: 19px;
    height: 20px;
    margin-right: 16px;
  }

  .denglu {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }

  .denglu2 {
    position: relative;

    img {
      width: 21px;
      height: 20px;
    }

    .msgnum {
      position: absolute;
      top: -7px;
      left: 10px;
      line-height: 12px;
      font-size: 12px;
      padding: 2px 4px;
      border-radius: 6px;
      color: #ffffff;
      background-color: red;
      z-index: 5;
    }
  }
}

.top2 {
  ::v-deep .van-tab__text {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }

  ::v-deep .van-tab--active .van-tab__text {
    color: #e71518;
  }

  ::v-deep .van-tabs__wrap {
    height: 32px;
  }
}

.mid1 {
  padding: 12px 17px;

  .img {
    width: 100%;
    height: 183px;
    border-radius: 5px;
  }

  ::v-deep .van-swipe__indicators {
    left: 10px;
    transform: translateX(0);
  }

  ::v-deep .van-swipe__indicator {
    width: 7px;
    height: 7px;
    background-color: #fff !important;
    opacity: 1 !important;
  }

  ::v-deep .van-swipe__indicator--active {
    width: 17px;
    height: 7px;
    border-radius: 7px;
    background-color: #e71518 !important;
  }
}

.jianbian {
  position: relative;
}

.jianbianbg {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 303px;
  z-index: -1;
}

.mid5 {
  .m5b {
    margin: 15px 17px;

    .m5row {
      .m5box {
        width: 47%;
        margin-bottom: 15px;
      }
    }

    .m5more {
      height: 30px;
      background: #f1f1f1;
      border-radius: 15px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      img {
        width: 9px;
        height: 5px;
        margin-right: 8px;
      }
    }
  }
}

.mid6 {
  .m6b {
    margin: 0 17px 25px;

    .m6box {
      margin-top: 15px;

      .m6bimg {
        width: 161px;
        height: 90px;
      }

      .m6bright {
        margin-left: 18px;

        .m6btitle {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
          margin-top: 5px;
        }

        .m6bbqbox {
          height: 15px;
          width: auto;
          background: #ffffff;
          border: 1px solid #979fa6;
          border-radius: 2px;
          padding: 0 3px;
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #979fa6;
          line-height: 15px;
          margin: 7px 0 12px;
          margin-right: 3px;
        }

        .m6bmore {
          .m6bmorel {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f01414;
            line-height: 12px;
          }

          .m6bmorer {
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #919ca3;
            line-height: 12px;

            img {
              width: 9px;
              height: 10px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}

.mid7 {
  margin-top: 14px;

  ::v-deep .box {
    border-bottom: none;
  }

  ::v-deep .boxleft {
    border-bottom: none;
  }

  .m7box {
    width: 100%;
    padding: 2px 17px;

    .m7title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      line-height: 35px;
    }

    .m7text {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 25px;
    }

    .m7text1 {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .m7btn {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b0b0b0;
      margin: 5px 0 15px;

      img {
        width: 9px;
        height: 5px;
        margin-left: 6px;
      }
    }
  }

  .m7swipe {
    margin-bottom: 10px;

    .img {
      width: 100%;
      height: 183px;
      border-radius: 5px;
    }

    ::v-deep .van-swipe__indicators {
      left: 10px;
      transform: translateX(0);
    }

    ::v-deep .van-swipe__indicator {
      width: 7px;
      height: 7px;
      background-color: #fff !important;
      opacity: 1 !important;
    }

    ::v-deep .van-swipe__indicator--active {
      width: 17px;
      height: 7px;
      border-radius: 7px;
      background-color: #e71518 !important;
    }
  }
}

.mid8 {
  padding: 5px 17px;
  height: 45px;

  img {
    width: 100%;
    height: 35px;
    border-radius: 4px;
  }
}

.mid9 {
  margin-top: 14px;

  .m9box {
    padding: 0 17px;

    .m9top {
      white-space: nowrap;
      overflow: auto;
      margin: 10px 0 12px;

      .m9bq {
        height: 25px;
        background: #ffffff;
        border: 1px solid #979fa6;
        border-radius: 13px;
        padding: 0 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 23px;
        margin-left: 2px;
      }

      .active {
        background: #0b8586;
        border-color: #0b8586;
        color: #fff;
      }
    }

    .m9bot {
      .m9bbox {
        padding: 11px 0 30px;
        border-top: 1px solid #eee;

        .m9bimg {
          width: 56px;
          height: 56px;
          margin-left: 40px;
        }

        .m9btitle {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 22px;
          height: 44px;
          margin-bottom: 6px;

          img {
            width: 14px;
            height: 16px;
            margin-left: 10px;
            margin-top: 2px;
          }

          span {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .m9bbqbox {
          height: 18px;
          background: #ffffff;
          border: 1px solid #979fa6;
          border-radius: 2px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #979fa6;
          line-height: 16px;
          padding: 0 2px;
          margin-right: 3px;
        }
      }

      .nolist {
        padding-bottom: 20px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #979fa6;
        line-height: 30px;
      }
    }
  }
}

.mid10 {
  margin-top: 14px;

  .m10box {
    padding: 15px 17px 50px;
    overflow-x: auto;

    .m10b {
      margin-left: 18px;

      img {
        width: 140px;
        height: 184px;
        margin-bottom: 10px;
      }

      .m10title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        text-align: center;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

::v-deep .big {
  span {
    font-size: 18px !important;
    font-weight: bold !important;
  }
}

.zImg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
