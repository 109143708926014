<template>
  <div style="height: 100%">
    <div v-if="!isMobile()">
      <div class="box">
        <div class="pdfbox">
          <div :class="'pdf' + pdfSize"
               class="display_center"
               v-loading="loadedRatio != 1"
               v-show="pdfsrc">
            <pdf :src="pdfsrc"
                 class="display_center"
                 style="width: 100%"
                 :page="currentPage"
                 @progress="pdfJiazai($event)"
                 @num-pages="pageCount = $event"
                 @page-loaded="currentPage = $event"></pdf>
          </div>
          <div class="fengmian display_center"
               v-show="!pdfsrc"
               :style="'background-image: url(' + courseInfo.acr_PictResUrl + ');'">
            <img src="../../assets/images/detail/bofang.png"
                 @click="getPdf()"
                 alt="" />
          </div>
        </div>

        <div class="btn display_between">
          <div class="bleft"></div>
          <div class="bmid display_center">
            <img @click="changePdfPage(0)"
                 src="../../assets/icons/left3.png"
                 alt="" />
            <div class="num">{{ pageCount ? currentPage : 0 }} /{{ pageCount || 0 }}</div>
            <img @click="changePdfPage(1)"
                 src="../../assets/icons/right3.png"
                 alt="" />
          </div>
          <div class="bright"
               v-if="false">
            <img src="../../assets/icons/jia.png"
                 style="margin-right: 19px"
                 @click="pdfSize < 4 && pdfsrc ? pdfSize++ : ''"
                 alt="" />
            <img src="../../assets/icons/jian.png"
                 @click="pdfSize > 1 && pdfsrc ? pdfSize-- : ''"
                 alt="" />
          </div>
          <div class="bright">
            <img src="../../assets/icons/quanping.png"
                 @click="pdfsrc ? (isQuan = true) : ''"
                 alt="" />
          </div>
        </div>
        <el-dialog :visible.sync="isQuan"
                   width="1180px"
                   top="10vh"
                   class="pdfquan"
                   @close="isQuan = false">
          <pdf :src="pdfsrc"
               class="display_center"
               style="width: 100%"
               :page="currentPage"
               @progress="pdfJiazai($event)"
               @num-pages="pageCount = $event"
               @page-loaded="currentPage = $event"></pdf>
          <div class="pdfquanbtn display_center">
            <el-button type="primary"
                       @click="changePdfPage(0)">上一页</el-button>
            <el-button type="primary"
                       @click="changePdfPage(1)">下一页</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div v-else>
      <Mebook :courseInfo="courseInfo == '' ? null : courseInfo"
              :info="info == '' ? null : info"></Mebook>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import Mebook from '../../components/mobile/detail/ebook.vue';
import { Dialog } from 'vant';
export default {
  components: {
    pdf,
    Mebook,
  },
  data () {
    return {
      cid: 0,
      uid: 0,
      cidIng: 0,
      courseInfo: '',
      pdfsrc: '',
      loadedRatio: 1,
      currentPage: 1, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      info: '',
      pdfSize: 1,
      loading: false,
      userType: 0,
      discountStatus: 0,
      discountStatustype: 1,
      isQuan: false,
    };
  },
  watch: {},
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType');
    }
    this.cid = Number(this.$route.query.courseId);
    this.cidIng = Number(this.$route.query.cidIng);
    this.getCoursedetail();
  },
  mounted () {
    this.getCoursedetail();
  },
  methods: {
    getCoursedetail () {
      this.httphelper
        .post('CourseApi/GetCourseInfoDetail', {
          userId: this.uid,
          courseId: this.cid == 0 ? this.cidIng : this.cid,
        })
        .then(res => {
          if (res.code == 200) {
            if (res.data.is_Putaway == false) {
              if (this.isMobile()) {
                Dialog.confirm({
                  title: '提示',
                  message: '该课程已下架',
                  confirmButtonText: '返回首页',
                  showCancelButton: false
                }).then(() => {
                  this.$router.push('/home');
                });
              } else {
                this.$confirm('该课程已下架', '提示', {
                  confirmButtonText: '返回首页',
                  showCancelButton: false,
                  showClose: false
                }).then(() => {
                  this.$router.push('/home');
                });
              }
              return
            }
            this.courseInfo = res.data;
            if (this.$route.query.courseId == 0) {
              this.info = res.data;
            } else {
              this.getCourseMenu();
            }
          }
        });
    },
    getCourseMenu () {
      this.httphelper
        .post('CourseApi/GetCourseCataLogList', {
          courseId: this.cid,
          userId: this.uid,
        })
        .then(res => {
          if (res.data != null) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].courseId == this.cidIng) {
                this.info = res.data[i];
              }
            }
          }
        });
    },
    changePdfPage (val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        if (this.discountStatus != 0 && this.currentPage == this.discountStatus) {
          if (this.discountStatustype == 1) {
            this.$message({
              message: '试看结束，请登录后观看',
              type: 'warning',
            });
          } else if (this.discountStatustype == 2) {
            this.$message({
              message: '试看结束，请购买后观看',
              type: 'warning',
            });
          }
          return;
        }
        this.currentPage++;
      }
    },
    getPdf () {
      if (this.pdfsrc) {
        this.pdfsrc = '';
      }
      //课程已购买
      if (this.courseInfo.isPurchase == true) {
        this.toPdf();
        //课程未购买
      } else {
        //免费课程、会员的会员课程
        let dis = this.courseInfo.discountStatus
        if ((dis == 2 && this.uid != 0) || (dis == 1 && this.userType != 0) || (dis == 3 && this.userType == 1)) {
          this.httphelper
            .post('CourseApi/CourseApply', {
              courseId: this.cid == 0 ? this.cidIng : this.cid,
              userId: this.uid,
            })
            .then(res => {
              if (res.code == 200) {
                this.toPdf();
              }
            });
          //未登录看免费课程
        } else if (this.courseInfo.discountStatus == 2 && this.uid == 0) {
          if (this.info.isApply == true && this.info.tryTime != null && this.info.tryTime != 0) {
            this.discountStatus = this.info.tryTime;
            this.discountStatustype = 1;
            this.toPdf();
          } else {
            let doms = document.getElementsByClassName('el-message')[0];
            if (doms == undefined) {
              this.$message({
                message: '请登录',
                type: 'warning',
              });
            }
          }
        } else {
          if (this.info.isApply == true && this.info.tryTime != null && this.info.tryTime != 0) {
            this.discountStatus = this.info.tryTime;
            this.discountStatustype = 2;
            this.toPdf();
          } else {
            let doms = document.getElementsByClassName('el-message')[0];
            if (doms == undefined) {
              this.$message({
                message: '请购买课程后观看',
                type: 'warning',
              });
            }
          }
        }
      }
    },
    toPdf () {
      this.loading = true;
      this.httphelper
        .post('/CGPInfoApi/GetCourseRes', {
          courseId: this.cidIng,
          userId: this.uid,
        })
        .then(res => {
          this.pdfsrc = res.data;
          if (this.pdfsrc == '') return;
          var img = document.createElement("img");
          img.src = this.httphelper
            .get('CourseClassApi/UpdCourseRecord', {
              courseid: this.cidIng,
              userId: this.uid,
              standingtime: 1,
            });
          document.body.appendChild(img);
          img.style.display = 'none'
          //   this.httphelper
          //     .post('CourseClassApi/UpdCourseRecord', {
          //       courseid: this.cidIng,
          //       userId: this.uid,
          //       standingtime: 1,
          //     })
          //     .then(res => {
          //       if (res.code == 200) {
          //         console.log('更新成功');
          //       }
          //     });
        });
    },
    pdfJiazai (e) {
      this.loadedRatio = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100%;
    background: #15161b;
    background-image: url('../../assets/images/detail/bg.jpg');
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;

    .fengmian {
        width: 870px;
        height: 470px;
        margin-top: 10px;
        // margin: 51px auto 0;
        background-size: 100% 100%;
        img {
            cursor: pointer;
        }
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
    .pdfbox {
        width: 870px;
        height: 489px;
        overflow: auto;
        scrollbar-color: transparent transparent;
        scrollbar-arrow-color: #f0f2f5; /* 设置滚动条上的箭头颜色  */
        scrollbar-base-color: #f0f2f5; /* 设置滚动条的底色 */
        scrollbar-track-color: #f0f2f5; /* 设置滚动条块的背景色 */
        scrollbar-shadow-color: #f0f2f5; /* 设置箭头和滚动条右侧和底边的颜色 */
    }
    .pdfbox::-webkit-scrollbar {
        /* 滚动条宽 */
        width: 6px;
        height: 4px;
    }
    .pdfbox::-webkit-scrollbar-thumb {
        /* 滚动条 拖动条 */
        background: #0b86864d;
        opacity: 0.3;
        border-radius: 6px;
    }
    .pdfbox::-webkit-scrollbar-track {
        /* 滚动条背景槽 */
        background-color: #15161b;
        border-radius: 6px;
    }
    .pdf1 {
        width: 100%;
        // height: 409px;
        //  margin: 51px auto 0;
        overflow: hidden;
    }
    .pdf2 {
        width: 361px;
        height: 489px;
        margin: 0 auto;
        overflow: hidden;
    }
    .pdf3 {
        width: 517px;
        // height: 700px;
        min-height: 489px;
        margin: 0 auto;
        overflow: hidden;
    }
    .pdf4 {
        width: 864px;
        // height: 1169px;
        min-height: 489px;
        margin: 0 auto;
        overflow: hidden;
    }
    .btn {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0px;
        .bleft {
            width: 90px;
        }
        .bmid {
            background: #15161b86;
            padding: 5px;
            padding-bottom: 4px;
            border-radius: 5px;
        }
        .num {
            height: 20px;
            background: #ffffff;
            padding: 0 12px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #15161b;
            line-height: 20px;
            margin: 0 20px;
        }
        .bright {
            background: #15161b75;
            padding: 6px;
            padding-bottom: 0px;
            border-radius: 5px;
            margin-right: 19px;
            img {
                width: 19px;
                height: 19px;
                cursor: pointer;
            }
        }
    }
}
.pdfquan {
    background: rgba(0, 0, 0, 0.829) !important;
    ::v-deep .el-dialog__header {
        display: none;
    }
    ::v-deep .el-dialog__body {
        padding: 0;
        position: relative;
    }
    .pdfquanbtn {
        position: absolute;
        bottom: -60px;
        left: 500px;
    }
}
</style>
