<template>
  <div style="padding-bottom: 50px">
    <div class="top display_between">
      <img class="tleft" @click="toFanhui" src="../../../assets/icons/fanhui.png" alt="" />
      <span>全部课程</span>
      <img class="tright" src="../../../assets/icons/search.png" @click="$router.push('/mSearch')" alt="" />
    </div>
    <div class="every"></div>
    <div class="top2 display_between">
      <div class="t2left display_center_ud">
        <div :style="midIndex == 0 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;margin-left:0' : 'margin-left:0'"
          @click="midIndex = 0">全部</div>
        <div :style="midIndex == 2 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''" @click="midIndex = 2">视频
        </div>
        <div :style="midIndex == 3 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''" @click="midIndex = 3">音频
        </div>
        <div :style="midIndex == 4 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''" @click="midIndex = 4">课件
        </div>
        <!-- <div>音频</div> -->
      </div>
      <div class="t2right display_center_ud" @click="toshaixuan()">
        <span class="btnText">课程筛选</span>
        <img class="btnImg" src="../../../assets/icons/shaixuan.png" alt="" />
      </div>
    </div>
    <div class="top3 display_center_ud">
      <div :style="midIndex2 == 1 ? 'color: #fff;background: #0B8586;border-color:#0B8586;' : ''" @click="midIndex2 = 1"
        class="mlbq2">最新</div>
      <div :style="midIndex2 == 2 ? 'color: #fff;background: #0B8586;border-color:#0B8586;' : ''" @click="midIndex2 = 2"
        class="mlbq2">最热</div>
      <!-- <div :style="midIndex2 == 3 ? 'color: #fff;background: #0B8586;border-color:#0B8586;' : ''"
           @click="midIndex2 = 3"
           class="mlbq2">限时折扣</div> -->
    </div>
    <van-popup v-model="isshow" position="right" :style="{ height: '100%', width: '100%' }">
      <div class="shaixuan display_column">
        <div class="sxtop display_between">
          <img src="../../../assets/icons/cha.png" @click="isshow = false" alt="" />
          <span>课程筛选</span>
          <div></div>
        </div>
        <div class="sxbox">
          <div class="sxnr" v-for="(item, index) in CourseListSel" :key="index + 'f'">
            <div class="sxtitle">
              <span>{{ item.name }}</span>
            </div>
            <div class="sxbq display_flex">
              <div class="sxbqbox display_between" v-for="(items, indexs) in item.queryCoursesInfoList"
                @click="items.queryCoursesInfoList != null ? toBiaoqians(items) : toBq(items)"
                :style="getIsCs(items.id) || (items.queryCoursesInfoList != null && getIsCs2(items)) ? 'color: #fff;background: #0B8586;' : ''"
                :key="indexs + 's'">
                <span>{{ items.name }}</span>
                <img src="../../../assets/icons/bottom1.png"
                  v-show="items.queryCoursesInfoList != null && !getIsCs2(items)" alt="" />
                <img src="../../../assets/icons/sanjiao.png"
                  v-show="items.queryCoursesInfoList != null && getIsCs2(items)" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="sxbot display_between applebottom">
          <div class="sxcz" @click="tochongzhi">
            <span>重置</span>
          </div>
          <div class="sxqr" @click="toqueren">
            <span>确认</span>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="isshow2" position="right" :style="{ height: '100%', width: '300px' }">
      <div class="shaixuan display_column">
        <div class="sxbox" style="padding-top: 20px">
          <div class="sxtitle">
            <span style="font-size: 14px">{{ biaoqiansInfo.name }}</span>
          </div>
          <div class="sxbq display_flex">
            <div class="sxbqbox sxbqbox2 display_between" v-for="(item, index) in biaoqiansInfo.queryCoursesInfoList"
              :style="getIsCs(item.id) ? 'color: #fff;background: #0B8586;' : ''" @click="toBq2(item)" :key="index">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="sxbot display_between">
          <div class="sxcz"
               @click="isshow2=false"
               style="width:122px">
            <span>取消</span>
          </div>
          <div class="sxqr"
               style="width:122px">
            <span>确认</span>
          </div>
        </div> -->
      </div>
    </van-popup>
    <div class="top4">
      <MCourseList :courseList="courseList" :pageCount="pageCount" callback="allCourse" :getAllCoures="getAllCoures">
      </MCourseList>
    </div>
    <Mfooter></Mfooter>
  </div>
</template>
<script>
import MCourseList from '../../../components/mobile/course/courseList.vue';
import Mfooter from '../index/footer.vue';
import lodash from 'lodash';
export default {
  data() {
    return {
      midIndex: 0,
      midIndex2: 1,
      courseList: [],
      pageIndex: 1,
      csStr: '',
      pageCount: 0,
      isshow: false,
      isshow2: false,
      biaoqiansInfo: '',
      csList: [],
    };
  },
  components: {
    MCourseList,
    Mfooter,
  },
  props: {
    CourseListSel: [],
  },
  watch: {
    midIndex: function () {
      this.getAllCoures(1);
    },
    midIndex2: function () {
      this.getAllCoures(1);
    },
  },
  created() {
    this.getAllCoures(1);
  },
  methods: {
    getAllCoures(index) {
      this.httphelper
        .post('CGPInfoApi/GetGpCourseVideoInfoPageLists', {
          labelgather: this.csStr,
          course_Type: 0,
          resinfoType: this.midIndex,
          orderBy: this.midIndex2,
          pageIndex: index,
          pageSize: 10,
          fuzzysearch: '',
        })
        .then(res => {
          this.pageCount = res.data.pageCount;
          if (index == 1) {
            this.courseList = lodash.chunk(res.data.gpCourseVideoInfoViews, 2);
          } else {
            if (this.courseList != lodash.chunk(res.data.gpCourseVideoInfoViews, 2)) {
              this.courseList = this.courseList.concat(lodash.chunk(res.data.gpCourseVideoInfoViews, 2));
            }
          }
        });
    },
    // toBiaoqians (item) {
    //   this.isshow2 = true;
    //   this.biaoqiansInfo = item;
    // },
    toBiaoqians(item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == item.id) {
          this.csList.splice(i, 1);
          if (!this.getIsCs2(item)) {
            return;
          }
        }
      }
      this.csList.push(item.id);
      this.isshow2 = true;
      this.biaoqiansInfo = item;
    },
    getIsCs(id) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == id) return true;
      }
      return false;
    },
    getIsCs2(item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (item.queryCoursesInfoList != null) {
          for (let a = 0; a < item.queryCoursesInfoList.length; a++) {
            if (this.csList[i] == item.queryCoursesInfoList[a].id) return true;
          }
        }
      }
      return false;
    },
    toBq(item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == item.id) {
          this.csList.splice(i, 1);
          return;
        }
      }
      this.csList.push(item.id);
    },
    toBq2(item) {
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == this.biaoqiansInfo.id) {
          this.csList.splice(i, 1);
        }
      }
      for (let i = 0; i < this.csList.length; i++) {
        if (this.csList[i] == item.id) {
          this.csList.splice(i, 1);
          return;
        }
      }
      this.csList.push(item.id);
    },
    tochongzhi() {
      this.csList = [];
      this.csStr = '';
    },
    toqueren() {
      this.csStr = this.csList.join(',');
      this.isshow = false;
      this.getAllCoures(1);
    },
    toshaixuan() {
      this.csList = this.csStr.split(',');
      this.isshow = true;
      this.getAllCoures(1);
    },
    toFanhui() {
      this.$router.push('/home');
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  height: 46px;
  padding: 0 17px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;

  .tleft {
    width: 17px;
    height: 15px;
  }

  .tright {
    width: 19px;
    height: 20px;
  }
}

.every {
  width: 100%;
  height: 10px;
  background: #f6f7fb;
}

.top2 {
  height: 50px;
  margin: 3px 17px 0;
  border-bottom: 1px solid #eee;

  .t2left {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 16px;
  }

  .t2left div {
    margin-left: 24px;
    padding: 15px 0;
    border-bottom: 2px solid #ffffff;
  }

  .t2right {
    .btnText {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 12px;
      margin-right: 5px;
    }

    .btnImg {
      width: 13px;
      height: 12px;
    }
  }
}

.top3 {
  margin: 11px 17px 15px;

  div {
    height: 25px;
    margin-right: 5px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    border: 1px solid #979fa6;
    border-radius: 25px;
    padding: 0 10px;
  }
}

.shaixuan {
  width: 100%;
  height: 100%;
  padding: 0 23px;

  .sxtop {
    height: 50px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0c0c0c;

    img {
      width: 14px;
      height: 14px;
    }

    div {
      width: 14px;
    }
  }

  .sxbox {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 50px;

    .sxtitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #0c0c0c;
      line-height: 16px;
      margin-bottom: 21px;
      margin-top: 17px;
    }

    .sxbq {
      flex-wrap: wrap;

      .sxbqbox {
        height: 37px;
        background: #f8f8f8;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0c0c0c;
        line-height: 37px;
        padding: 0 12px;
        margin-bottom: 8px;
        margin-right: 8px;

        img {
          width: 8px;
          height: 5px;
          margin-left: 6px;
        }
      }

      .sxbqbox2 {
        height: 31px !important;
        padding: 0 10px !important;
      }
    }
  }

  .sxbot {
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    text-align: center;
    padding: 0 23px;

    .sxcz {
      width: 162px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #0b8586;
      border-radius: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0b8586;
      line-height: 30px;
    }

    .sxqr {
      width: 162px;
      height: 32px;
      background: #0b8586;
      border: 1px solid #0b8586;
      border-radius: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
  }
}
</style>
