<template>
  <div class="mbox">
    <div class="box"
         v-if="courseInfo != null"
         :style="'background-image: url(' + courseInfo.acr_PictResUrl + ');'">
      <img src="../../../assets/images/detail/yinpin.png"
           class="img"
           alt="" />
      <img src="../../../assets/images/detail/bofang2.png"
           :class="isPlaying ? 'bofang2 img' : 'bofang img'"
           @click="isBofang ? (isPlaying ? pause() : play()) : toBofang()"
           alt="" />
      <audio ref="audios"
             style="display: none"
             @pause="changeIsPlaying(false)"
             @play="changeIsPlaying(true)"
             src=""
             @timeupdate="onTimeupdates"
             controls="controls"></audio>
      <div class="jindu display_center_ud"
           v-show="isBofang">
        <img src="../../../assets/icons/bofang4.png"
             v-show="isPlaying"
             class="jinduImg"
             @click="pause"
             alt="" />
        <img src="../../../assets/icons/bofang5.png"
             v-show="!isPlaying"
             class="jinduImg"
             @click="play"
             alt="" />
        <p class="jinduSpan">
          <span style="color: #0b8586">{{ jindu == 0 ? '0:00' : getnowTime() }}</span>
          <span v-if="info.res_duration">/{{ info.res_duration.toFixed(2).toString().replace('.', ':') }}</span>
        </p>
        <el-slider v-model="jindu"
                   class="jindubox"
                   @input="changeJindu"
                   :show-tooltip="false"></el-slider>
      </div>
    </div>
    <div class="m2box">
      <div class="m2top display_between_nocenter"
           v-if="info != null">
        <div class="m2title">{{ courseInfo.title }}</div>
        <div class="m2bq">
          <span>{{ info.res_Type == 2 ? '视频' : info.res_Type == 3 ? '音频' : '电子书' }}</span>
        </div>
      </div>
      <div class="m2mid display_column"
           v-if="info != null">
        <span>讲师：{{ info.expertName ? info.expertName : info.expertlist[0].name }}</span>
        <span>单位：{{ info.unit ? info.unit : info.expertlist[0].unit }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      uid: 0,
      jindu: 0,
    };
  },
  props: {
    courseInfo: {
      type: Object,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    onTimeupdate: {
      type: Function,
      default: null,
    },
    getnowTime: {
      type: Function,
      default: null,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isBofang: {
      type: Boolean,
      default: false,
    },
    toBofang2: {
      type: Function,
      default: null,
    },
    changeIsPlaying: {
      type: Function,
      default: null,
    },
    toRecord: {
      type: Function,
      default: null,
    },
    jindus: {
      type: Number,
      default: 0,
    },
    cidIng: {
      type: Number,
      default: 0,
    },
    btime: {
      type: Number,
      default: 0,
    },
  },
  watch: {},
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
    }
  },
  mounted () { },
  methods: {
    onTimeupdates (res) {
      this.onTimeupdate(res);
      this.jindu = (res.target.currentTime / this.btime) * 100;
    },
    changeJindu (e) {
      if (Math.abs((e * this.btime) / 100 - this.$refs.audios.currentTime) > 1) {
        this.$refs.audios.currentTime = (e * this.btime) / 100;
      }
    },
    toBofang () {
      this.httphelper
        .post('/CGPInfoApi/GetCourseRes', {
          courseId: this.cidIng,
          userId: this.uid,
        })
        .then(res => {
          this.toBofang2();
          this.$refs.audios.src = res.data;
          this.play();
          this.jindu = this.jindus;
        });
    },
    play () {
      this.$refs.audios.play();
    },
    pause () {
      this.$refs.audios.pause();
      this.toRecord();
    },
  },
};
</script>
<style lang="scss" scoped>
.mbox {
    width: 100%;
    min-height: 210px;
    .box {
        width: 100%;
        min-height: 210px;
        background-size: 100% 100%;
        position: relative;
        .img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .bofang {
            cursor: pointer;
            width: 60px;
            height: 60px;
        }
        .bofang2 {
            cursor: pointer;
            width: 60px;
            height: 60px;
            margin-top: -30px;
            margin-left: -30px;
            animation: xuanzhuan 10s infinite linear;
        }

        @keyframes xuanzhuan {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
        .jindu {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 350px;
            height: 28px;
            background: #ffffff;
            border-radius: 20px;
            padding: 0 13px;
            .jindubox {
                width: 220px;
                ::v-deep .el-slider__bar {
                    background-color: #0b8586;
                    height: 4px;
                }
                ::v-deep .el-slider__button {
                    width: 10px;
                    height: 10px;
                    border-color: #0b8586;
                    top: -16px;
                }
                ::v-deep .el-slider__runway {
                    height: 4px;
                }
            }
            .jinduSpan {
                margin: 0 13px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
            .jinduImg {
                cursor: pointer;
            }
        }
    }
}
.m2box {
    padding: 7px 17px 3px;
    .m2top {
        padding: 15px 0;
        .m2title {
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
            flex: 1;
        }
        .m2bq {
            height: 18px;
            background: #ffffff;
            border: 1px solid #e60012;
            border-radius: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e60012;
            line-height: 17px;
            padding: 0 5px;
            margin-left: 5px;
            margin-top: 2px;
        }
    }
    .m2mid {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #626262;
        line-height: 21px;
    }
}
</style>
