<template>
  <div v-if="!isMobile()">
    <div class="box">
      <div id="mp4"
           v-show="player != null && !isTip"></div>
      <div class="fengmian display_center"
           v-show="player == null && !isTip"
           :style="'background-image: url(' + courseInfo.acr_PictResUrl + ');'">
        <img src="../../assets/images/detail/bofang.png"
             @click="getMp4"
             alt="" />
      </div>
      <div class="tipBg display_center"
           v-show="isTip"
           style="background: #15161b">
        <span>{{ tiptext }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <Mvideo :getMp4="getMp4"
            :player="player"
            :courseInfo="courseInfo == '' ? null : courseInfo"
            :info="info == '' ? null : info"
            :tiptext="tiptext"
            :toRecordMp4="toRecordMp4"
            :isTip="isTip"></Mvideo>
  </div>
</template>

<script>
import Player from 'xgplayer';
import Mvideo from '../../components/mobile/detail/video.vue';
import { Dialog } from 'vant';
export default {
  components: {
    Mvideo,
  },
  props: {
    toBofang: {
      type: Function,
      default: null,
    },
    changeRfs: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      cid: 0,
      uid: 0,
      cidIng: 0,
      info: '',
      player: null,
      courseInfo: '',
      stime: 0,
      userType: 0,
      isApplyTime: 0,
      isProgress: false,
      isTip: false,
      tiptext: '',
      isRfs: false,
    };
  },
  watch: {
    $route: {
      handler () {
        if (this.player) this.toRecordMp4();
        this.cidIng = Number(this.$route.query.cidIng);
        this.getCoursedetail();
      },
      deep: true,
    },
    player (e) {
      if (!this.isMobile()) {
        if (e == null) this.toBofang(2);
        else this.toBofang(1);
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (this.player) this.toRecordMp4();
    next();
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType');
    }
    this.cid = Number(this.$route.query.courseId);
    this.cidIng = Number(this.$route.query.cidIng);
    this.getCoursedetail();
  },
  mounted () {
    let url = window.location.href;
    window.addEventListener('beforeunload', () => {
      if (this.player) this.toRecordMp4();
    });
    window.addEventListener('onunload', () => {
      if (this.player) this.toRecordMp4();
    });
    window.addEventListener('unload', () => {
      if (this.player) this.toRecordMp4();
    });
    window.addEventListener('WeixinJSBridgeReady', () => {
      if (this.player) this.toRecordMp4();
    });
    window.addEventListener(
      'popstate',
      () => {
        if (this.player) this.toRecordMp4();
        //判断全屏状态返回
        if (this.player && this.isRfs) {
          var time = setTimeout(function () {
            //   if (url.indexOf("&isRfs=true") != -1) window.location.href = url
            //   else window.location.href = url + '&isRfs=true'
            window.location.href = url;
            clearTimeout(time);
          }, 100);
        }
      },
      false
    );
  },
  beforeDestroy () {
    if (this.player) this.toRecordMp4();
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.toRecordMp4);
    window.removeEventListener('WeixinJSBridgeReady', this.toRecordMp4);
    window.removeEventListener('popstate', this.toRecordMp4);
    window.removeEventListener('unload', this.toRecordMp4);
    window.removeEventListener('onunload', this.toRecordMp4);
  },
  methods: {
    getCoursedetail () {
      this.httphelper
        .post('CourseApi/GetCourseInfoDetail', {
          userId: this.uid,
          courseId: this.cid == 0 ? this.cidIng : this.cid,
        })
        .then(res => {
          if (res.code == 200) {
            if (res.data.is_Putaway == false) {
              if (this.isMobile()) {
                Dialog.confirm({
                  title: '提示',
                  message: '该课程已下架',
                  confirmButtonText: '返回首页',
                  showCancelButton: false
                }).then(() => {
                  this.$router.push('/home');
                });
              } else {
                this.$confirm('该课程已下架', '提示', {
                  confirmButtonText: '返回首页',
                  showCancelButton: false,
                  showClose: false
                }).then(() => {
                  this.$router.push('/home');
                });
              }
              return
            }
            this.courseInfo = res.data;
            if (this.$route.query.courseId == 0) {
              this.info = res.data;
              //   if (this.$route.query.isRfs) this.toPlayer();
            } else {
              this.getCourseMenu();
            }
          }
        });
    },
    getCourseMenu () {
      this.httphelper
        .post('CourseApi/GetCourseCataLogList', {
          courseId: this.cid,
          userId: this.uid,
        })
        .then(res => {
          if (res.data != null) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].courseId == this.cidIng) {
                this.info = res.data[i];
                if (this.player) {
                  this.player.destroy();
                  this.player = null;
                  this.getMp4();
                }
                //  else if (this.$route.query.isRfs) {
                //   this.toPlayer();
                // }
              }
            }
          }
        });
    },
    getMp4 () {
      if (this.player) {
        this.player.destroy();
        this.player = '';
      }
      //   console.log('是否学完：' + this.info.isLearning, '，是否进度条：', this.info.isFastForward)
      //   if (this.info.isLearning == true) {
      //     this.isProgress = true;
      //   } else {
      this.isProgress = this.info.isFastForward;
      //   }
      //课程已购买
      if (this.courseInfo.isPurchase == true) {
        this.toPlayer();
        //课程未购买
      } else {
        //免费课程、会员的会员课程
        let dis = this.courseInfo.discountStatus
        if ((dis == 2 && this.uid != 0) || (dis == 1 && this.userType != 0) || (dis == 3 && this.userType == 1)) {
          this.httphelper
            .post('CourseApi/CourseApply', {
              courseId: this.cid == 0 ? this.cidIng : this.cid,
              userId: this.uid,
            })
            .then(res => {
              if (res.code == 200) {
                this.toPlayer();
              }
            });
          //未登录看免费课程
        } else if ((this.courseInfo.discountStatus == 2 || this.courseInfo.discountStatus == 1) && this.uid == 0) {
          if (this.info.isApply == true) {
            this.isApplyTime = this.info.tryTime;
            this.isProgress = false;
            this.toPlayer('试看结束，请登录后观看');
          } else {
            this.tipBox('提示', '请登录');
          }
        } else if (this.courseInfo.discountStatus == 1) {
          if (this.info.isApply == true) {
            this.isApplyTime = this.info.tryTime;
            this.isProgress = false;
            this.toPlayer('试看结束，请成为VIP后观看');
          } else {
            this.tipBox('提示', 'VIP专享课程，请成为VIP后观看');
          }
        } else {
          if (this.info.isApply == true) {
            this.isApplyTime = this.info.tryTime;
            this.isProgress = false;
            this.toPlayer('试看结束，请购买后观看');
          } else {
            this.tipBox('提示', '请购买课程后观看');
          }
        }
      }
    },
    toPlayer (tip) {
      let minute = Number(this.info.standingtime.toFixed(2).split('.')[0]) * 60;
      let second = Number(this.info.standingtime.toFixed(2).split('.')[1]);
      let starTime = minute + second;
      if (this.courseInfo.isPurchase == false) {
        starTime = 0.00
      }
      let ignores = this.isProgress ? [] : ['progress'];
      let that = this;
      this.httphelper
        .post('/CGPInfoApi/GetCourseRes', {
          courseId: this.cidIng,
          userId: this.uid,
        })
        .then(res => {
          this.player = new Player({
            id: 'mp4',
            url: res.data,
            autoplay: true,
            playsinline: true,
            width: '100%',
            height: '100%',
            lastPlayTime: starTime,
            ignores: ignores,
            rotateFullscreen: this.isMobile() ? true : false,
            'x5-video-player-type': 'h5',
            'x5-video-orientation': 'portraint',
            playbackRate: [0.5, 1, 1.5, 2, 3],
          });
          this.player.on('ended', () => {
            this.toRecordMp4();
          });
          this.player.on('pause', () => {
            if (!this.isMobile()) {
              this.toBofang(2);
            }
          });
          this.player.on('playing', () => {
            if (!this.isMobile()) {
              this.toBofang(1);
            }
          });
          this.player.on('getRotateFullscreen', () => {
            this.isRfs = true;
            this.changeRfs(true);
          });
          this.player.on('exitRotateFullscreen', () => {
            this.isRfs = false;
            this.changeRfs(false);
          });
          let num = starTime;
          this.player.on('timeupdate', e => {
            if (parseInt(e.currentTime) != num && parseInt(e.currentTime) != 0) {
              if (num + 60 < parseInt(e.currentTime) && this.isMobile()) {
                this.toRecordMp4();
              }
              num = parseInt(e.currentTime);
              if (num % 60 == 0 && this.isMobile()) {
                this.toRecordMp4();
              }
            }
            var soupttime = parseInt(e.currentTime / 60) + Math.ceil(e.currentTime % 60) / 100;
            if (this.stime != soupttime) {
              this.stime = soupttime;
            }
            if (this.isApplyTime != 0 && soupttime >= this.isApplyTime) {
              this.tipBox('提示', tip);
              if (that.player != null) {
                if (this.isMobile()) {
                  this.changeRfs(false);
                }
                that.player.destroy();
                that.player = null;
              }
            }
          });
        });
    },
    toRecordMp4 () {
      if (this.stime == 0 || this.uid == 0) return;
      var img = document.createElement("img");
      img.src = this.httphelper
        .get('CourseClassApi/UpdCourseRecord', {
          courseid: this.cidIng,
          userId: this.uid,
          standingtime: this.stime,
        });
      document.body.appendChild(img);
      img.style.display = 'none'
    },
    tipBox (title, text) {
      if (this.isMobile()) {
        let domsm = document.getElementsByClassName('van-dialog')[0];
        let type = 0;
        if (domsm == undefined) {
          if (text == '请登录' || text == '试看结束，请登录后观看') type = 1;
          else if (text == 'VIP专享课程，请成为VIP后观看' || text == '试看结束，请成为VIP后观看') type = 2;
          Dialog.alert({
            title: title,
            message: text,
            showCancelButton: type == 0 ? false : true,
          })
            .then(() => {
              document.getElementsByClassName('van-dialog')[0].remove();
              if (type == 1 || (type == 2 && this.uid == 0)) {
                let callback = encodeURIComponent(window.location.href);
                window.open(this.httphelper.IsDebugH5GP519()+'/pages/login/login?callback=' + callback, '_blank');
              } else if (type == 2 && this.uid != 0) {
                let callback = encodeURIComponent(window.location.href);
                window.open(this.httphelper.IsDebugH5GP519()+'/pages/user/vip?callback=' + callback, '_blank');
              }
            })
            .catch(() => {
              document.getElementsByClassName('van-dialog')[0].remove();
            });
        }
      } else {
        let type = 0;
        let doms = document.getElementsByClassName('el-message-box__wrapper')[0];
        if (doms == undefined) {
          if (text == '请登录' || text == '试看结束，请登录后观看') type = 1;
          else if (text == 'VIP专享课程，请成为VIP后观看' || text == '试看结束，请成为VIP后观看') type = 2;
          this.$confirm(text, title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          })
            .then(() => {
              document.getElementsByClassName('el-message-box__wrapper')[0].remove();
              if (type == 1 || (type == 2 && this.uid == 0)) {
                let callback = encodeURIComponent(this.delUrlData('token'));
                window.location.href = 'https://gp519.com/loginIndex/login?callback=' + callback;
              } else if (type == 2 && this.uid != 0) {
                let callback = encodeURIComponent(window.location.href);
                window.location.href = 'https://gp519.com/user/userVip?callback=' + callback;
              }
            })
            .catch(() => {
              document.getElementsByClassName('el-message-box__wrapper')[0].remove();
            });
        }
      }
    },
    toLastRecordMp4 (cid, stime) {
      if (stime == 0) {
        this.getCoursedetail();
      }
      if (this.uid == 0) {
        this.httphelper
          .post('CourseApi/GetCourseInfoDetail', {
            userId: this.uid,
            courseId: this.cid == 0 ? this.cidIng : this.cid,
          })
          .then(res => {
            if (res.code == 200) {
              this.courseInfo = res.data;
              if (this.$route.query.courseId == 0) {
                this.info = res.data;
              } else {
                this.getCourseMenu();
              }
            }
          });
      } else {
        this.httphelper
          .get('CourseClassApi/UpdCourseRecord', {
            courseid: cid,
            userId: this.uid,
            standingtime: stime,
          })
          .then(res => {
            if (res.code == 200) {
              this.httphelper
                .post('CourseApi/GetCourseInfoDetail', {
                  userId: this.uid,
                  courseId: this.cid == 0 ? this.cidIng : this.cid,
                })
                .then(res => {
                  if (res.code == 200) {
                    this.courseInfo = res.data;
                    if (this.$route.query.courseId == 0) {
                      this.info = res.data;
                    } else {
                      this.getCourseMenu();
                    }
                  }
                });
            } else {
              this.getCourseMenu();
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
    width: 870px;
    height: 489px;
    .fengmian {
        height: 100%;
        background-size: 100%;
        img {
            cursor: pointer;
        }
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
    .tipBg {
        height: 100%;
        color: #fff;
    }
    ::v-deep .xgplayer-replay {
        z-index: 10;
    }
}
</style>
