<template>
  <div>
    <div class="box"
         :style="!info.labelName?'height:276px':''"
         @click="toCourse">
      <img :src="info.acr_PictResUrl"
           class="img"
           alt="">
      <img src="../../assets/icons/bofang3.png"
           class="bfbtn"
           alt="">
      <div class="boxBot display_column">
        <div class="title">
          <span>{{info.title}}</span>
        </div>
        <div class="bq display_flex"
             v-if="info.labelName">
          <div class="bqbox"
               v-for="(item,index) in info.labelName.split(',').slice(0,3)"
               v-show="item != ''"
               :key="index">
            <span>{{item}}</span>
          </div>
        </div>
        <div class="more display_between">
          <div class="morel">
            <span v-show="info.discountStatus == 2">免费</span>
            <span v-show="info.discountStatus == 1">VIP专享</span>
            <div v-show="info.discountStatus == 3&&userType!=1">
              <span>￥{{ Number(info.discount).toFixed(2) }}</span>
              <span class="originalPrice"
                    v-show="info.price>info.discount">原价￥{{ Number(info.price).toFixed(2) }}</span>
            </div>
            <div v-show="info.discountStatus == 3&&userType==1">
              <span style="margin-left:5px">SVIP专享</span>
            </div>
          </div>
          <div class="morer display_center_ud">
            <img src="../../assets/icons/people6.png"
                 alt="">
            <span>{{info.playSum}}人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      userType: 0
    }
  },
  created () {
    if (localStorage.getItem('userType')) {
      this.userType = localStorage.getItem('userType');
    } else {
      this.userType = 0;
    }
  },
  methods: {
    toCourse () {
      this.$router.push({
        name: 'detail',
        query: {
          courseId: this.info.courseInfoId
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
    width: 330px;
    height: 315px;
    background: #ffffff;
    box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
    margin-top: 10px;
    position: relative;
    cursor: pointer;
    .img {
        width: 300px;
        height: 169px;
        margin: 15px 15px 10px;
    }
    .bfbtn {
        position: absolute;
        top: 133px;
        left: 25px;
    }
    .boxBot {
        margin: 0 22px 20px;
        .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
            height: 40px;
            margin-bottom: 10px;
        }
        .bq {
            margin-bottom: 10px;
            .bqbox {
                height: 19px;
                background: #ffffff;
                border: 1px solid #979fa6;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #979fa6;
                line-height: 17px;
                padding: 0 3px;
                margin-right: 3px;
            }
        }
        .more {
            .morel {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #f01414;
                line-height: 14px;
            }
            .morer {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #919ca3;
                line-height: 14px;
                img {
                    margin-right: 10px;
                    margin-top: -2px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}
</style>