import $httphelpers from './HttpHelper.js'
var jweixin = require('jweixin-module')

var sharemodel = {
    title: '', //标题
    url: '',
    desc: '',
    imgUrl: ''
};


/**
 * H5微信分享
 *
 * @param {string} title 分享标题（必填）
 * @param {string} url 当前页面地址（必填）（window.location.href）
 * @param {string} desc 分享链接（可选）
 * @param {string} imgUrl  分享图标地址（可选）（默认： https://cgphome.com/static/cgpa.png）
 */
function WeiXinShare (title, url, desc, imgUrl) {
    sharemodel.title = title == undefined ? '' : title;
    sharemodel.url = url == undefined ? '' : url;
    sharemodel.desc = desc == undefined ? '' : desc;
    sharemodel.imgUrl = imgUrl == undefined ? 'https://cgphome.com/static/cgphomefx.png' : imgUrl;
    var urls = sharemodel.url;
    $httphelpers.get('CGpUserApi/GetWXShareConfigInfo', {
        url: urls
    }).then(res => {
        if (res.code == 200) {
            shareurl(res.data)
        }
    });
}

function shareurl (data) {
    jweixin.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: [
            'updateTimelineShareData',
            'updateAppMessageShareData',
        ]
    });
    jweixin.ready(function () {
        //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        jweixin.updateAppMessageShareData({
            title: sharemodel.title, // 分享标题
            desc: sharemodel.desc, // 分享描述
            link: sharemodel.url, //分享链接
            imgUrl: sharemodel.imgUrl, // 分享图标
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {

            }
        });
        /**自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容*/
        jweixin.updateTimelineShareData({
            title: sharemodel.title, // 分享标题
            link: sharemodel.url, //分享链接
            imgUrl: sharemodel.imgUrl, // 分享图标
            success: function () { }
        });
    });

}
export default {
    WeiXinShare
}
