<template>
  <div>
    <div class="display_center_ud"
         style="width:100%">
      <img src="../../assets/icons/home/right.png"
           style="transform:rotate(180deg);"
           class="btn"
           @click="changeList"
           v-show="isShow&&isShowLeft"
           alt="">
      <div class="titlebqlist display_center_ud"
           :id="'titlebqlist'+index">
        <div class="titlebq"
             :class="item.id==bqIng?'activebq':''"
             :style="index==0?'margin-left:0px':''"
             v-for="(item,index) in bqList"
             :key="index"
             @click="changeBqIng(item.id)">
          <span>{{item.ntitle}}</span>
        </div>
      </div>
      <img src="../../assets/icons/home/right.png"
           @click="changeList"
           v-show="isShow&&!isShowLeft"
           class="btn"
           alt="">
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShow: false,
      isShowLeft: false
    }
  },
  props: {
    bqIng: {
      type: Number,
      default: 0
    },
    index: {
      type: String,
      default: ''
    },
    bqList: [],
    changeBqIng: {
      type: Function,
      default: null
    }
  },
  watch: {
    bqList () {
      this.$nextTick(() => {
        let dom = document.getElementById('titlebqlist' + this.index);
        if (dom.scrollWidth > dom.offsetWidth) this.isShow = true
      })
    }
  },
  methods: {
    changeList () {
      var dom = document.getElementById('titlebqlist' + this.index);
      if (dom.scrollLeft + 100 + dom.offsetWidth > dom.scrollWidth) {
        this.isShowLeft = true
      } else if (dom.scrollLeft - 100 < 0) {
        this.isShowLeft = false
      }
      if (dom.scrollLeft % 100 == 0) {
        let i = 0
        let test = setInterval(function () {
          if (i < 10) {
            dom.scrollLeft = dom.scrollLeft + 10
            i++
          } else {
            clearInterval(test)
          }
        }, 15)
      } else {
        let i = 0
        let test = setInterval(function () {
          if (i < 10) {
            dom.scrollLeft = dom.scrollLeft - 10
            i++
          } else {
            clearInterval(test)
          }
        }, 15)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.titlebqlist {
    overflow: hidden;
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .titlebq {
        height: 34px;
        border: 1px solid #e5e5e5;
        border-radius: 17px;
        padding: 0 16px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        margin-left: 5px;
        cursor: pointer;
        white-space: nowrap;
    }
    .activebq {
        background: #0b8586;
        border: 1px solid #0b8586;
        color: #ffffff;
    }
}
.btn {
    cursor: pointer;
}
</style>