<template>
  <div style="padding-bottom:50px">
    <div class="top display_between">
      <img class="tleft"
           @click="toFanhui"
           src="../../assets/icons/fanhui.png"
           alt="" />
      <span>搜索</span>
      <div class="tright"></div>
    </div>
    <div class="every"></div>
    <van-search v-model="text"
                placeholder="请输入搜索关键词" />
    <div class="top2 display_between">
      <div class="t2left display_center_ud">
        <div :style="midIndex == 0 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;margin-left:0' : 'margin-left:0'"
             @click="midIndex = 0">全部</div>
        <div :style="midIndex == 2 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''"
             @click="midIndex = 2">视频</div>
        <div :style="midIndex == 3 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''"
             @click="midIndex = 3">音频</div>
        <div :style="midIndex == 4 ? 'color: #0B8586;border-bottom: 2px solid #0B8586;' : ''"
             @click="midIndex = 4">课件</div>
      </div>
      <div class="t2right display_center_ud">
      </div>
    </div>
    <div class="top3 display_center_ud">
      <div :style="midIndex2 == 1 ? 'color: #fff;background: #0B8586;' : ''"
           @click="midIndex2 = 1"
           class="mlbq2">最新</div>
      <div :style="midIndex2 == 2 ? 'color: #fff;background: #0B8586;' : ''"
           @click="midIndex2 = 2"
           class="mlbq2">最热</div>
      <div :style="midIndex2 == 3 ? 'color: #fff;background: #0B8586;' : ''"
           @click="midIndex2 = 3"
           class="mlbq2">限时折扣</div>
    </div>
    <div class="top4">
      <MCourseList :courseList="courseList"
                   :pageCount="pageCount"
                   :getAllCoures="getAllCoures"></MCourseList>
    </div>
    <Mfooter></Mfooter>
  </div>
</template>
<script>
import MCourseList from '../../components/mobile/course/courseList.vue';
import Mfooter from "../../components/mobile/index/footer.vue"
import lodash from 'lodash';
export default {
  data () {
    return {
      midIndex: 0,
      midIndex2: 1,
      courseList: [],
      pageIndex: 1,
      pageCount: 0,
      text: ''
    };
  },
  components: {
    MCourseList,
    Mfooter
  },
  watch: {
    midIndex: function () {
      this.getAllCoures(1);
    },
    midIndex2: function () {
      this.getAllCoures(1);
    },
    text: function () {
      this.getAllCoures(1);
    },
  },
  created () {
    if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'keyword')) {
      this.text = this.$route.query.keyword
    }
    this.getAllCoures(1);
  },
  methods: {
    getAllCoures (index) {
      this.httphelper
        .post('CGPInfoApi/GetGpCourseVideoInfoPageLists', {
          labelgather: '',
          course_Type: 0,
          resinfoType: this.midIndex,
          orderBy: this.midIndex2,
          pageIndex: index,
          pageSize: 10,
          fuzzysearch: this.text,
        })
        .then(res => {
          this.pageCount = res.data.pageCount;
          if (index == 1) {
            this.courseList = lodash.chunk(res.data.gpCourseVideoInfoViews, 2);
          } else {
            if (this.courseList != lodash.chunk(res.data.gpCourseVideoInfoViews, 2)) {
              this.courseList = this.courseList.concat(lodash.chunk(res.data.gpCourseVideoInfoViews, 2));
            }
          }
        });
    },
    toFanhui () {
      this.$router.push('/home')
    }
  },
};
</script>
<style lang="scss" scoped>
.top {
    height: 46px;
    padding: 0 17px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .tleft {
        width: 17px;
        height: 15px;
    }
    .tright {
        width: 19px;
        height: 20px;
    }
}
.every {
    width: 100%;
    height: 10px;
    background: #f6f7fb;
}
.top2 {
    height: 50px;
    margin: 3px 17px 0;
    border-bottom: 1px solid #eee;
    .t2left {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 16px;
    }
    .t2left div {
        margin-left: 24px;
        padding: 15px 0;
        border-bottom: 2px solid #ffffff;
    }
    .t2right {
        .btnText {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 12px;
            margin-right: 5px;
        }
        .btnImg {
            width: 13px;
            height: 12px;
        }
    }
}
.top3 {
    margin: 11px 17px 15px;
    div {
        height: 25px;
        margin-right: 5px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        border: 1px solid #979fa6;
        border-radius: 25px;
        padding: 0 10px;
    }
}
</style>
