function delUrlData (name) {
    var url = window.location.href;
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        //参数名前缀
        var prefix = encodeURIComponent(name) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //循环查找匹配参数
        for (var i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                //存在则删除
                pars.splice(i, 1);
            }
        }
        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}
function isMobile () {
    let m = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    return navigator.userAgent.match(m);
}
export default {
    delUrlData,
    isMobile
}