<template>
  <div class="box">
    <div class="top">
      <img src="../../assets/images/home/404.png"
           alt=""
           srcset="">
      <span>404</span>
    </div>
    <div class="mid">
      <span>很抱歉，您访问的页面不存在或者暂时无法访问，请稍后再试</span>
    </div>
    <div class="bot">
      <el-button round
                 @click="tofist">返回首页</el-button>
      <el-button class="btn2"
                 round
                 @click="totop">返回上一级</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }

  },
  methods: {
    tofist () {
      this.$router.push({
        path: '/home'
      });
    },
    totop () {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.box {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.top {
    margin-top: 207px;
    position: relative;
}
.top span {
    width: 100px;
    height: 44px;
    font-size: 60px;
    position: absolute;
    top: 74px;
    left: 119px;
    font-family: Roboto;
    font-weight: bold;
    color: #0b8586;
    line-height: 18px;
}
.mid {
    height: 19px;
    margin-top: 28px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 18px;
}
.bot {
    margin-top: 139px;
}
.bot button {
    width: 160px;
    height: 48px;
    background: #0b8586;
    border-radius: 24px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
}
.bot .btn2 {
    border: 1px solid #0b8586;
    margin-left: 10px;
    background: #ffffff;
    color: #0b8586;
}
@media screen and (max-width: 800px) {
    .top {
        margin-top: 130px;
        position: relative;
    }
    .top span {
        width: 100px;
        height: 44px;
        font-size: 30px;
        position: absolute;
        top: 50px;
        left: 90px;
        font-family: Roboto;
        font-weight: bold;
        color: #0b8586;
        line-height: 18px;
    }
    .top img {
        width: 200px;
        height: 140px;
    }
    .mid {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #474747;
        line-height: 20px;
        margin: 28px 20px 0;
        /* text-indent: 2em; */
        /* text-align: center; */
    }
    .bot {
        margin-top: 60px;
    }
    .bot button {
        width: 120px;
        height: 36px;
        background: #0b8586;
        border-radius: 18px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 10px;
    }
    .bot .btn2 {
        border: 1px solid #0b8586;
        margin-left: 10px;
        background: #ffffff;
        color: #0b8586;
    }
}
</style>