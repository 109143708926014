<script>
export default {
  created () {
    if (this.$route.query.courseId) {
      if (this.$route.query.sub) {
        this.$router.push({
          name: 'detail',
          query: {
            courseId: this.$route.query.courseId,
            sub: this.$route.query.sub
          }
        })
      } else {
        this.$router.push({
          name: 'detail',
          query: {
            courseId: this.$route.query.courseId
          }
        })
      }
    }
  }
}
</script>