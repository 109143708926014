<template>
  <div>
    <div class="box"
         @click="toCourse">
      <img :src="info.acr_Pict"
           v-show="!isloading"
           class="img"
           alt="" />
      <img src="../../assets/icons/loading.png"
           v-show="isloading"
           class="img2"
           alt="" />
      <img src="../../assets/icons/bofang3.png"
           class="bfbtn"
           alt="" />
      <div class="boxBot display_column">
        <div class="title">
          <span>{{ info.title }}</span>
        </div>
        <div class="bq display_flex"
             v-if="info.labelName">
          <div class="bqbox"
               v-for="(item, index) in info.labelName.split(',').slice(0, 3)"
               v-show="item != ''"
               :key="index">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="more display_between">
          <div class="morel">
            <span v-show="info.discountStatus == 2">免费</span>
            <span v-show="info.discountStatus == 1">VIP专享</span>
            <div v-show="info.discountStatus == 3 && userType != 1">
              <span>￥{{ Number(info.discount).toFixed(2) }}</span>
              <span class="originalPrice"
                    v-show="info.price > info.discount">原价￥{{ Number(info.price).toFixed(2) }}</span>
            </div>
            <div v-show="info.discountStatus == 3 && userType == 1">
              <!-- <span style="color:#979fa6;text-decoration:line-through;">￥{{ Number(info.discount).toFixed(2) }}</span> -->
              <span style="margin-left: 5px">SVIP专享</span>
            </div>
          </div>
          <div class="morer display_center_ud">
            <img src="../../assets/icons/people6.png"
                 alt="" />
            <span>{{ info.playSum }}人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isloading: false,
      userType: 0,
    };
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    /**资源类型 */
    restype: {
      type: Number,
      default: 0,
    },
    /** 资源排序 */
    ressort: {
      type: Number,
      default: 1,
    },
    /** 分页 */
    resindex: {
      type: Number,
      default: 1,
    },
    /** 小列表type */
    listType: {
      type: Number,
      default: 0,
    },
  },
  created () {
    this.isloading = true;
    var that = this;
    setTimeout(() => {
      that.isloading = false;
    }, 100);
    if (localStorage.getItem('userType')) {
      this.userType = localStorage.getItem('userType');
    } else {
      this.userType = 0;
    }
  },
  watch: {
    info: function () {
      this.isloading = true;
      var that = this;
      setTimeout(() => {
        that.isloading = false;
      }, 100);
    },
  },
  methods: {
    toCourse () {
      // this.$router.push({
      //   name: 'detail',
      //   query: {
      //     courseId: this.info.id,
      //     restype: this.restype,
      //     ressort: this.ressort,
      //     resindex: this.resindex,
      //     listType: this.listType
      //   },
      // });
      let routeUrl = this.$router.resolve({
        name: 'detail',
        query: {
          courseId: this.info.id,
          restype: this.restype,
          ressort: this.ressort,
          resindex: this.resindex,
          listType: this.listType,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
    width: 280px;
    height: 288px;
    background: #ffffff;
    box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    position: relative;
    .img {
        width: 280px;
        height: 158px;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
    .img2 {
        width: 32px;
        height: 32px;
        margin: 63px 124px;
    }
    .bfbtn {
        position: absolute;
        top: 114px;
        left: 10px;
    }
    .boxBot {
        margin: 12px 15px 20px;
        .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
            height: 40px;
            margin-bottom: 10px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .bq {
            margin-bottom: 10px;
            .bqbox {
                height: 19px;
                background: #ffffff;
                border: 1px solid #979fa6;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #979fa6;
                line-height: 17px;
                padding: 0 3px;
                margin-right: 3px;
            }
        }
        .more {
            .morel {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #f01414;
                line-height: 14px;
            }
            .morer {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #919ca3;
                line-height: 14px;
                img {
                    margin-right: 8px;
                    margin-top: -2px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}
</style>
