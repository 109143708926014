<template>
  <div class="PayCont"
       id="PayCont">
    <el-dialog width="45%"
               title="中国全科医学网【全科知家】付费内容购买协议"
               :visible.sync="innerVisible"
               append-to-body>
      <p>欢迎您购买中国全科医学网【全科知家】付费内容！</p>
      <p style="font-weight:bold;">请您在购买之前仔细阅读并充分理解本《中国全科医学网【全科知家】付费内容购买协议》。您确认您具备完全民事权利能力和完全民事行为能力，有能力同意并遵守本协议，并对您本协议项下的全部行为独立承担法律责任。若您不具备前述与您行为相适应的民事行为能力，则应获得法定监护人的知情同意。如您尚未成年，请在法定监护人的陪同下阅读和判断是否同意本协议。未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。</p>
      <p>1. 付费内容：本协议所称付费内容指中国全科医学网【全科知家】提供的需要单独购买的视频、文字、文档、图片、直播门票、VIP会员等内容，
        <span style="font-weight:bold;">付费内容包括预售内容。</span>
      </p>
      <p>2.购买方式：您需使用支付宝或是微信购买付费内容，关于支付宝或是微信的使用规则详见《支付宝或是微信用户协议》。如您通过任何非中国全科医学网【全科知家】官方途径进行交易，中国全科医学网【全科知家】有权根据实际情况终止向您提供或继续提供已购买的部分或全部付费内容，且无须承担任何责任。
        <span style="font-weight:bold;">此外，机构用户获得分配课程或医生圈用户兑换课无需额外支付相关付费课程费用，请您知晓。</span>
      </p>
      <p>3. 退款：
        <span style="font-weight:bold;">您已购买的付费内容不支持退换且无法转移使用。</span>
        如付费内容存在无法观看、无法提供/继续提供的情形，中国全科医学网【全科知家】将折算无法观看、无法提供/继续提供部分的付费内容价款并予以退还。
      </p>
      <p>4. 价格说明：页面中的划线价格指付费内容的官方指导价格，并非原价。未划线价格指付费内容的实时价格。</p>
      <p>5. 知识产权：您的购买行为不被视为付费内容的知识产权权属转移，您已经购买的付费内容仅能由您本人在中国全科医学网【全科知家】平台观看学习使用。
        <span style="font-weight:bold;">您不得采取出售、转让、盗用、租赁账户等方式分享或获取付费内容，亦不得以录制屏幕或其他技术手段观看、下载、传播付费内容。如您存在前述违规行为，中国全科医学网【全科知家】有权根据具体情形采取包括但不限于终止提供部分/全部付费内容、封禁相关账号等措施。</span>
      </p>
      <p>6.您理解并同意：因部分中国全科医学网【全科知家】经合法权利人授权而向您提供的付费内容受版权合约限制，需要对开通相关付费内容的用户所在地区以及用户使用付费内容的具体时间、地域等做出相应限制。
        <span style="font-weight:bold;">如因上述版权合约限制导致您在特定地区、特定时间或其他情况下无法获得/使用相关付费内容，中国全科医学网【全科知家】不承担任何责任。</span>
      </p>
      <p>7.
        <span style="font-weight:bold;">免责声明：因不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制导致的暂时关闭、病毒侵袭等影响互联网正常运行之情形）以及您的个人原因导致付费内容无法正常观看的情况下，中国全科医学网【全科知家】无须承担任何责任。</span>
      </p>
      <p>8. 联系：若您在购买付费内容时有任何问题或疑问，您可以拨打【中国全科医学网【全科知家】】（网页端）页底标注的联系电话，咨询时间9:00-17:30。</p>
      <p>9. 附则：本协议未约定事宜，适用《支付宝或是微信用户协议》及其他中国全科医学网【全科知家】协议规范的相关条款。</p>
    </el-dialog>
    <el-dialog :visible.sync="zhifuing"
               :close-on-click-modal="false"
               :show-close="false"
               class="zhifuing"
               width="300px">
      <img src="../../assets/icons/dengdai.png"
           alt="">
      <h3>等待您完成支付...</h3>
      <p>请在支付页面继续完成支付</p>
      <div class="btn"
           @click="towancheng">
        <span>支付完成</span>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isOrdered"
               :show-close="false"
               class="zhifuing"
               width="400px">
      <img src="../../assets/icons/cuowu.png"
           alt="">
      <h3>此课程已存在未支付的订单，请前往订单中心完成支付！</h3>
      <div class="btn"
           style="background-color: #E00019;"
           @click="toOrder">
        <span>前往订单中心</span>
      </div>
    </el-dialog>
    <div class="PayCont_cover"
         v-show="ZomCover">
      <div class="PayCont_body_maiun">
        <div class="PayCont_close"
             @click="ZomClose()">
          <i class="iconfont icon-guanbi"></i>
        </div>
        <div class="PayCont_main">
          <!-- 支付订单信息 -->
          <div class="PayCont_body">
            <div class="PayCont_body_notice">
              <span>确认订单信息</span>
              <span>请在24小时内完成支付，否则订单将会自动取消</span>
            </div>
            <!-- 用户信息 -->
            <div class="PayCont_Info">
              <div class="PayCont_Info_Image fl">
                <img :src="UserInfImage"
                     alt="" />
              </div>
              <div class="PayCont_Info_r fl">
                <p>账号：{{ UserInfCel }}</p>
                <p>注意：购买后不支持退款、转让、请确认好开课时间或有效期再提交订单</p>
              </div>
              <div style="clear:both;"></div>
            </div>
            <div class="line"></div>
            <!-- 订单信息 -->
            <div class="OrderInfo"
                 id="OrderInfo"
                 v-if="type == 1">
              <li style="list-style: none;"
                  v-for="(item, index) in CourseInfoList"
                  :key="index">
                <div class="OrderInfo_discount">
                  <div class="image fl">
                    <img :src="item.acr_PictResUrl"
                         alt="" />
                  </div>
                  <div class="title fl">
                    {{ item.title }}
                  </div>
                  <div class="discount fr">￥{{ item.discount }}</div>
                </div>
                <!-- <div class="OrderInfo_original">原价：￥：{{ item.price }}</div> -->
              </li>
            </div>
            <!-- 订单信息 -->
            <div class="OrderInfo"
                 id="OrderInfo"
                 v-if="type == 2">
              <li style="list-style: none;"
                  v-for="(item, index) in CourseInfoList"
                  :key="index">
                <div class="OrderInfo_discount">
                  <div class="image fl">
                    <img :src="item.acr_Pict"
                         alt="" />
                  </div>
                  <div class="title fl">
                    {{ item.title }}
                  </div>
                  <div class="discount fr">￥{{ item.discount }}</div>
                </div>
                <!-- <div class="OrderInfo_original">原价：￥{{ item.price }}</div> -->
              </li>
            </div>
            <!-- 订单信息 -->
            <div class="OrderInfo"
                 id="OrderInfo"
                 v-if="type == 3">
              <li style="list-style: none;">
                <div class="OrderInfo_discount">
                  <div class="image fl">
                    <img :src="CourseInfoList.picturl"
                         alt="" />
                  </div>
                  <div class="title fl">
                    {{ CourseInfoList.title }}
                  </div>
                  <div class="discount fr">￥{{ CourseInfoList.disMember }}</div>
                </div>
                <!-- <div class="OrderInfo_original">原价：￥：{{ CourseInfoList.member }}</div> -->
              </li>
            </div>
            <!-- 支付方式 -->
            <div class="PayMethods"
                 v-show="OrderInfoShow">
              <p class="payme">支付方式</p>
              <div class="PayMethods_list">
                <li style="list-style: none;">
                  <el-radio v-model="radio"
                            label="1">
                    <i class="iconfont icon-weixin1"></i>
                    <span>微信</span>
                  </el-radio>
                </li>
                <li style="list-style: none;">
                  <el-radio v-model="radio"
                            label="2">
                    <i class="iconfont icon-umidd17"></i>
                    <span>支付宝</span>
                  </el-radio>
                </li>
              </div>
            </div>
            <!-- 立即支付 -->
            <div class="paycheck"
                 v-show="OrderInfoShow">
              <div class="ortipay"
                   v-if="!isSix">
                实付金额：<span>￥{{ totalPrice }}</span>
              </div>
              <div class="ortipay"
                   v-if="isSix">
                折后价：<span>￥{{ totalPrice * 0.6 }}</span>
                <span style="text-decoration: line-through; color: #ccc; margin-left: 10px; font-size: 12px;">原价：￥{{ totalPrice }}</span>
              </div>
              <div class="payche_notice">
                <el-checkbox v-model="paychecked">我已同意</el-checkbox>
                <span><a style="cursor:pointer;"
                     @click="innerVisible = true">《全科知家用户付费协议》</a></span>
              </div>
              <el-button class="pay-btn fr"
                         @click="LimPayment()"
                         v-preventReClick>立即支付</el-button>
              <div style="clear:both;"></div>
            </div>
            <!-- 付款二维码 -->
            <div class="PayCont_body_PayCode"
                 id="wxpay"
                 v-loading="isloading"
                 v-show="PaymentCode">
              <p>合计：￥{{ totalPrice }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import QRCode from 'qrcodejs2';
import '../../assets/units/preventReClick';
export default {
  props: ['CourseInfo', 'type', 'payType', 'close'],
  data () {
    return {
      // 遮罩层
      ZomCover: true,
      // 用户头像
      UserInfImage: '',
      // 用户账号
      UserInfCel: '',
      // 订单信息
      OrderInfo: [
        {
          Image: '../../../static/cs.png',
          title: '过敏性鼻及过敏性咽炎',
          original: '58.00',
          discount: '38.00'
        }
      ],
      // 选择支付方式
      radio: '',
      // 勾选协议
      paychecked: false,
      title: this.info,
      // 支付订单信息
      OrderInfoShow: true,
      // 付款二维码
      PaymentCode: false,
      CourseInfoList: [],
      CourseId: [],
      VipCourseId: '',
      AuthorId: '',
      totalPrice: '',
      Ip: '',
      // 订单编号
      wxOrderNumber: '',
      // 付费协议
      innerVisible: false,
      zhifuing: false,
      isOrdered: false,
      isloading: true,
      isSix: false
    };
  },
  methods: {
    // 关闭弹框
    ZomClose () {
      //   this.ZomCover = false;
      this.close();
    },
    // 手机号中间四位隐藏
    mobileStr (str) {
      if (str.length > 7) {
        return str.substring(0, 3) + '****' + str.substring(7, str.length);
      } else {
        return str.substring(0, str.length - 1) + '****';
      }
    },
    // 获取用户个人信息
    getUserInfo () {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: this.AuthorId
        })
        .then(res => {
          this.UserInfImage = res.data.avatarUrl;
          this.UserInfCel = this.mobileStr(res.data.mobile);
        });
    },
    // 点击立即支付出二维码
    LimPayment () {
      let isSix = false
      if (localStorage.getItem('isSix')) {
        isSix = localStorage.getItem('isSix')
      }
      if (this.paychecked == false) {
        this.$message({
          message: '请勾选用户付费协议',
          type: 'warning'
        });
      } else if (this.radio == false) {
        this.$message({
          message: '请选择付费方式',
          type: 'warning'
        });
      } else {
        var payType = '';
        if (this.payType == 1) {
          payType = 1;
        } else if (this.payType == 2) {
          payType = 2;
        }
        this.isloading = true
        // payType - 1课程 2-vIP
        this.httphelper
          .post('CGpPayApi/PaymentBuy', {
            payType: payType,
            userId: this.AuthorId,
            payWay: Number(this.radio),
            courseIds: this.CourseId,
            totalFee: isSix ? this.totalPrice * 0.6 : this.totalPrice,
            spBillCreateIp: this.Ip,
            paymethod: this.radio == 1 ? 1 : 4,
            // pageUrl: window.location.href,
            code: '150'
          })
          .then(res => {
            if (res.code == 200) {
              if (this.radio == 1) {
                this.OrderInfoShow = false;
                this.PaymentCode = true;
                var qrcode = new QRCode(document.getElementById('wxpay'), {
                  width: 150,
                  height: 150
                });
                this.isloading = false
                qrcode.makeCode(res.data.qrCodeUrl);
                this.wxOrderNumber = res.data.outTradeNo;
                var _this = this;
                _this.timer = setInterval(() => {
                  _this.WxPayOK(payType);
                }, 3000);
              } else if (this.radio == 2) {
                this.OrderInfoShow = false;
                this.PaymentCode = true;
                const div = document.createElement('iframe');
                const wxcode = document.getElementById('wxpay')
                div.srcdoc = res.data.responseBody;
                div.style = 'width:150px;overflow: hidden;'
                div.marginwidth = "0"
                div.framespacing = "0"
                div.marginheight = "0"
                div.setAttribute('frameborder', 'no')
                div.scrolling = 'no'
                div.onload = () => {
                  this.isloading = false
                };
                wxcode.appendChild(div);
                this.wxOrderNumber = res.data.outTradeNo;
                this.timer = setInterval(() => {
                  this.WxPayOK(payType);
                }, 3000);
                // return
                // if (this.type == 1) {
                //   let divForm = document.getElementsByTagName('divform');
                //   if (divForm.length) {
                //     document.body.removeChild(divForm[0]);
                //   }
                //   const div = document.createElement('divform');

                //   div.innerHTML = res.data.responseBody;
                //   document.body.appendChild(div);
                //   document.forms[0].acceptCharset = 'UTF-8';
                //   document.forms[0].setAttribute('target', '_blank');
                //   document.forms[0].submit();
                //   //   this.ZomCover = false;
                //   this.zhifuing = true
                //   //   this.close();
                // } else if (this.type == (2 || 3)) {
                //   let divForm = document.getElementsByTagName('divform');
                //   if (divForm.length) {
                //     document.body.removeChild(divForm[0]);
                //   }
                //   const div = document.createElement('divform');

                //   div.innerHTML = res.data.responseBody;
                //   document.body.appendChild(div);
                //   document.forms[1].acceptCharset = 'UTF-8';
                //   document.forms[1].setAttribute('target', '_blank');
                //   document.forms[1].submit();
                //   //   this.ZomCover = false;
                //   this.zhifuing = true
                //   //   this.close();
                // }
              }
            } else if (res.code == 310) {
              //   this.$message({
              //     message: '已存在一笔未付款的订单',
              //     type: 'warning'
              //   });
              this.isOrdered = true
            } else if (res.code == 500) {
              this.$message({
                message: '金额异常，无法购买',
                type: 'warning'
              });
            }
          });
      }
    },
    // 判断是否支付成功
    WxPayOK (payType) {
      this.httphelper
        .post('CGpPayApi/WeChatVer', {
          ordernumber: this.wxOrderNumber
        })
        .then(res => {
          if (res.code == 200) {
            if (payType == 2) {
              localStorage.setItem('userType', 1);
            }
            clearInterval(this.timer);
            this.$message({
              type: 'success',
              message: '支付成功!'
            });
            // this.ZomCover = false;
            this.close();
            // this.$router.push({
            //   path: '/paySuccess',
            //   query: {
            //     payType: payType
            //   }
            // });
          } else {
            // clearInterval(this.timer);
          }
        });
    },
    towancheng () {
      location.reload()
    },
    toOrder () {
      window.open('https://gp519.com/user/userMyOrder', '_blank');
    }
  },
  mounted () {
    // 获取用户id
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.getUserInfo();
    // type 区分数据格式 课程详情页购买课程和购物车购买课程
    if (this.type == 1) {
      // 处理返回数据
      this.CourseInfoList.push(this.CourseInfo);
      this.CourseId.push(Number(this.CourseInfo.courseId));
      this.totalPrice = Number(this.CourseInfo.discount);
    } else if (this.type == 2) {
      // 处理返回数据
      this.CourseInfoList = this.CourseInfo;
      this.CourseInfoList.forEach(item => {
        this.CourseId.push(item.courseId);
      });

      let totalPrice = 0;
      for (var kk in this.CourseInfoList) {
        if (this.CourseInfoList[kk].discount) {
          totalPrice = totalPrice + Number(this.CourseInfoList[kk].discount);
        }
      }
      this.totalPrice = totalPrice;
    } else if (this.type == 3) {
      this.CourseInfoList = this.CourseInfo;
      this.CourseId.push(this.CourseInfo.id);
      this.totalPrice = this.CourseInfo.disMember;
    }

    if (this.CourseInfoList.length > 1) {
      $('#OrderInfo').css('height', '224px');
    } else {
      $('#OrderInfo').css('height', 'auto');
    }
    // 获取 ip
    this.Ip = localStorage.getItem('userIp');
  },
  created () {
    // console.log(this.CourseInfo);
    if (localStorage.getItem('isSix')) {
      this.isSix = localStorage.getItem('isSix')
    }
  },
  destroyed () {
    clearInterval(this.timer);
  }
};
</script>
<style lang="scss" scoped>
div {
    box-sizing: content-box;
}
.fl {
    float: left;
}
::v-deep.el-dialog {
    border-radius: 10px;
    p {
        line-height: 28px;
        text-align: justify;
        font-size: 15px;
    }
}
#PayCont {
    .PayCont_cover {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(000, 000, 000, 0.3);
        z-index: 999;
        left: 0;
        top: 0;
        .PayCont_body_maiun {
            width: 843px;
            // height:500px;
            position: fixed;
            left: 50%;
            top: 50%;
            overflow: hidden;
            overflow-y: auto;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            // position: absolute;
            // left: 50%;
        }
        .PayCont_body_maiun::-webkit-scrollbar {
            display: none;
        }
        .PayCont_close {
            width: 100%;
            height: 20px;
            text-align: right;
            line-height: 20px;
            position: relative;
            i {
                color: #ffffff;
                // position: absolute;
                font-size: 35px;
                cursor: pointer;
                -webkit-transition: -webkit-transform 0.5s ease-out;
                -moz-transition: -moz-transform 0.5s ease-out;
                transition: transform 0.5s ease-out;
                font-weight: bold;
            }
            i:hover {
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        // 支付弹框
        .PayCont_main {
            width: 803px;
            .PayCont_body {
                // height: 669px;
                background: #fff;
                box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
                border-radius: 10px;
                padding: 25px;
                padding-bottom: 49px;
                .PayCont_body_notice {
                    position: relative;
                    line-height: 22px;
                    span:nth-child(1) {
                        color: #000000;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 1px;
                    }
                    span:nth-child(2) {
                        margin-left: 27px;
                        color: #888888;
                        font-size: 14px;
                    }
                }
                .PayCont_Info {
                    // overflow: auto;
                    margin-top: 27px;
                    .PayCont_Info_Image {
                        width: 56px;
                        height: 56px;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                    .PayCont_Info_r {
                        width: 84%;
                        margin-left: 28px;
                        line-height: 27px;
                        p:nth-child(1) {
                            color: #000000;
                            font-size: 16px;
                            font-weight: bold;
                        }
                        p:nth-child(2) {
                            color: #888888;
                            font-size: 14px;
                        }
                    }
                }
                .line {
                    width: 100%;
                    height: 2px;
                    background: #fafafa;
                    margin-top: 22px;
                }
                .OrderInfo {
                    width: 100%;
                    // height: 224px;
                    overflow: hidden;
                    overflow-y: auto;
                    li {
                        overflow: auto;
                        border-bottom: 1px solid #fafafa;
                        padding: 13px 0;
                        .OrderInfo_discount {
                            height: 60px;
                            padding: 17px 14px;
                            background: #fafafa;
                            .image {
                                width: 98px;
                                height: 56px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .title {
                                width: 65%;
                                line-height: 56px;
                                color: #434343;
                                font-size: 14px;
                                margin-left: 18px;
                            }
                            .discount {
                                line-height: 60px;
                                color: #434343;
                                font-size: 14px;
                            }
                        }
                        .OrderInfo_original {
                            margin-top: 10px;
                            color: #e00019;
                            font-size: 14px;
                            text-align: right;
                            padding-right: 12px;
                        }
                    }
                }
                .OrderInfo::-webkit-scrollbar {
                    display: none;
                }
                // 支付方式
                .PayMethods {
                    width: 100%;
                    margin-top: 15px;
                    .payme {
                        color: #000000;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 1px;
                    }
                    .PayMethods_list {
                        height: 100px;
                        background: #fafafa;
                        margin-top: 18px;
                        padding: 19px 14px;
                        li {
                            height: 50px;
                            line-height: 50px;
                            ::v-deep.el-radio__input.is-checked .el-radio__inner {
                                border: 1px solid #c2c2c2;
                                background: #fff;
                            }
                            ::v-deep.el-radio__inner {
                                width: 12px;
                                height: 12px;
                            }
                            ::v-deep.el-radio__inner::after {
                                background: #e00019;
                            }
                            ::v-deep.el-radio__label {
                                padding-left: 32px;
                            }
                            i {
                                font-size: 26px;
                                position: relative;
                                top: 4px;
                                margin-right: 15px;
                            }
                            span {
                                color: #000;
                                font-size: 14px;
                            }
                        }
                        li:nth-child(2) {
                            i {
                                color: #07a0f8;
                                font-size: 26px;
                            }
                        }
                        li:nth-child(1) {
                            i {
                                color: #02bb29;
                                font-size: 24px;
                            }
                        }
                    }
                }
                // 立即支付
                .paycheck {
                    margin-top: 15px;
                    text-align: right;
                    .ortipay {
                        line-height: 20px;
                        color: #000;
                        font-size: 14px;
                        span {
                            color: #e00019;
                            font-size: 16px;
                        }
                    }
                    .payche_notice {
                        color: #888888;
                        font-size: 14px;
                        margin-top: 14px;
                        ::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
                            color: #888;
                        }
                        ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner {
                            border: 1px solid #888888;
                            background: #fff;
                        }
                        ::v-deep.el-checkbox__inner::after {
                            border: 1px solid #2594ea;
                            border-left: 0;
                            border-top: 0;
                        }
                    }
                    .pay-btn {
                        width: 140px;
                        height: 40px;
                        background: #e00019;
                        color: #ffffff;
                        font-size: 18px;
                        text-align: center;
                        // line-height: 40px;
                        cursor: pointer;
                        margin-top: 30px;
                    }
                }

                // 二维码
                .PayCont_body_PayCode {
                    width: 150px;
                    height: 150px;
                    margin: 0 auto;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    p {
                        position: absolute;
                        bottom: -28px;
                        left: 25px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .zhifuing {
        margin-top: 200px;
        p {
            font-size: 14px;
        }
        .btn {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            background-color: #0b8586;
            margin-top: 10px;
            line-height: 36px;
            color: #fff;
            text-align: center;
            cursor: pointer;
        }
    }
    ::v-deep .el-dialog__header {
        display: none;
    }
    ::v-deep .el-dialog__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

// ::-webkit-scrollbar
// {
//   width: 8px;
//   height: 8px;
//   // background-color: #F5F5F5;
// }

// /*定义滚动条轨道 内阴影+圆角*/
// ::-webkit-scrollbar-track
// {
//   box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//   border-radius: 10px;
//   background-color: #333;
// }

// /*定义滑块 内阴影+圆角*/
// ::-webkit-scrollbar-thumb
// {
//   border-radius: 10px;
//   box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//   background-color: #555;
// }
@media screen and (max-height: 768px) {
    .PayCont_body_maiun {
        // height: 500px;
        top: 54% !important;
    }
    .PayCont_main {
        height: 500px;
        overflow: hidden;
        overflow: auto;
    }
    .PayCont_main::-webkit-scrollbar {
        display: none;
    }
}
</style>
