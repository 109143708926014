<template>
  <div>
    <div v-if="!isMobile()">
      <div class="top">
        <TopSwipe :toRecordMp3="toRecordMp3" :isMp3IngId="isMp3IngId"></TopSwipe>
      </div>
      <div class="top2f">
        <Top2 :toRecordMp3="toRecordMp3" :isMp3IngId="isMp3IngId"></Top2>
      </div>
      <div class="top3 EC_width display_flex">
        <div class="top3left">
          <BoxTitle :imgUrl="require('../../assets/icons/home/t1.png')" title="技能课程" :type="t3bqIng" :toMore="toMore">
            <TitleBqList :bqList="t3bqList" :bqIng="t3bqIng" style="width:600px" index="t3" :changeBqIng="changeT3BqIng">
            </TitleBqList>
          </BoxTitle>
          <div class="t3lbox">
            <Course :info="item" class="t3cbox" v-for="(item, index) in t3List"
              :style="index % 3 == 2 ? 'margin-right:0px' : ''" :key="index"></Course>
          </div>
        </div>
        <div class="top3right">
          <BoxTitle :imgUrl="require('../../assets/icons/home/t2.png')" title="全科知声" :type="tjbqIng" :toMore="toMore">
          </BoxTitle>
          <div class="t3b1 display_between">
            <div class="t3b1box" :class="tjbqIng == item.id ? 'active' : ''"
              :style="index == 0 ? 'padding-left:0' : (index == tjbqList.length - 1 ? 'padding-right:0;border-right:0' : '')"
              @click="tjbqIng = item.id" v-for="(item, index) in tjbqList" :key="index">{{ item.ntitle }}</div>
          </div>
          <div class="t3b2 display_column">
            <div class="t3b2box display_flex" v-for="(item, index) in tjList" @click="toMp3Ing(item)" :key="index">
              <img src="../../assets/images/home/mp3.png" class="t3b2img" alt="">
              <img class="t3b2icon" src="../../assets/images/home/bofang.png" alt="">
              <img class="t3b2gif" v-show="isMp3IngId == item.id" src="../../assets/images/home/zanting.png" alt="">
              <div class="t3b2right display_column">
                <div class="t3b2title display_between_nocenter">
                  <span>{{ item.title }}</span>
                  <img src="../../assets/images/home/icons8.gif" v-show="isMp3IngId == item.id" alt="">
                </div>
                <div class="t3b2bq display_flex" v-if="item.labelName">
                  <div class="t3b2bqbox" v-for="(items, indexs) in item.labelName.split(',').slice(0, 3)"
                    v-show="items != ''" :key="indexs + 's'">
                    <span>{{ items }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top4 EC_width" @click="toMidUrl()">
        <img :src="midImgUrl" alt="">
      </div>
      <div class="top5 EC_width display_flex">
        <div class="top5left">
          <BoxTitle :imgUrl="require('../../assets/icons/home/t3.png')" title="推荐课程" :type="t5bqIng" :toMore="toMore">
            <TitleBqList :bqList="t5bqList" :bqIng="t5bqIng" index="t5" style="width:600px" :changeBqIng="changeT5BqIng">
            </TitleBqList>
          </BoxTitle>
          <div class="t5lbox">
            <Course :info="item" class="t5cbox" v-for="(item, index) in t5List"
              :style="index % 3 == 2 ? 'margin-right:0px' : ''" :key="index"></Course>
          </div>
        </div>
        <div class="top5right">
          <BoxTitle :imgUrl="require('../../assets/icons/home/t4.png')" title="电子书" :type="17" :toMore="toMore">
          </BoxTitle>
          <div class="t5rrow display_between_nocenter" style="margin-top:-8px">
            <div class="t5rbox display_column" v-for="(item, index) in ebookList.slice(0, 2)" @click="toCourse(item.id)"
              :key="index">
              <img :src="item.ver_Pict" alt="">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="t5rrow display_between_nocenter">
            <div class="t5rbox display_column" v-for="(item, index) in ebookList.slice(2, 4)" @click="toCourse(item.id)"
              :key="index">
              <img :src="item.ver_Pict" alt="">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="top6f">
        <div class="top6 EC_width">
          <BoxTitle :imgUrl="require('../../assets/icons/home/t5.png')" title="项目专题" :type="0" :isMore="false"></BoxTitle>
          <Top6 :toRecordMp3="toRecordMp3" :isMp3IngId="isMp3IngId"></Top6>
        </div>
      </div>
      <div class="top7f">
        <Top7></Top7>
      </div>
      <div class="top8 EC_width">
        <BoxTitle :imgUrl="require('../../assets/icons/home/t6.png')" title="新媒体矩阵" :isMore="false"></BoxTitle>
        <div class="mediaList display_between">
          <div class="t8box display_column_start_center" v-for="(item, index) in mediaList" :key="index">
            <img :src="item.qRcodeUrl" alt="">
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Mhome :midImgUrl="midImgUrl" :midUrl="midUrl" :m9List="tjList" :m10List="ebookList" :m9BqList="tjbqList"
        :m9BqIng="tjbqIng" :changeM9bqIng="changeTjbqIng"></Mhome>
    </div>
  </div>
</template>

<script>
import Top2 from "../../components/home/top2.vue"
import Top6 from "../../components/home/top6.vue"
import Top7 from "../../components/home/top7.vue"
import Course from '../../components/course/index.vue';
import TopSwipe from "../../components/home/topSwipe.vue"
import BoxTitle from "../../components/index/boxTitle.vue"
import TitleBqList from "../../components/index/titleBqList"
import Mhome from "../../components/mobile/home/index.vue"
export default {
  components: {
    Top2,
    TopSwipe,
    BoxTitle,
    Top7,
    Top6,
    Mhome,
    TitleBqList,
    Course
  },
  data() {
    return {
      t3bqList: [],
      t3bqIng: 1,
      t5bqList: [],
      t5bqIng: 1,
      midImgUrl: '',
      midUrl: '',
      mediaList: [],
      t3List: [],
      t5List: [],
      tjbqList: [],
      tjbqIng: 1,
      tjList: [],
      ebookList: [],
      isMp3IngId: 0,
      Mp3Ing: null
    }
  },
  watch: {
    t3bqIng(e) {
      this.getCourseList(e)
    },
    t5bqIng(e) {
      this.getCourseList(e)
    },
    tjbqIng(e) {
      this.getCourseList(e)
    },
  },
  created() {
    this.getMidImgUrl()
    this.getmediaList()
    this.getbqList()
    this.getCourseList(17)
  },
  mounted() {
  },
  destroyed() {
    if (this.isMp3IngId != 0) this.toRecordMp3();
  },
  methods: {
    toMore(index) {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      if (index != 0) {
        let routeData = this.$router.resolve({
          name: 'list',
          query: {
            type: index
          }
        })
        window.open(routeData.href, '_blank');
      }
    },
    toMidUrl() {
      if (this.isMp3IngId != 0) this.toRecordMp3()
      //   console.log(this.midUrl);
      window.open(this.midUrl, '_blank');
    },
    getMidImgUrl() {
      this.httphelper.post('WebSetMessApi/GetAdvertisingInfo', {
        type: 3,
        belongType: 1
      })
        .then(res => {
          if (res.code == 200 && res.data.length > 0) {
            this.midImgUrl = res.data[0].resUrl
            this.midUrl = res.data[0].url
          }
        });
    },
    getmediaList() {
      this.httphelper
        .post('WebSetMessApi/GetNewMediaInfo', { type: 1 })
        .then(res => {
          if (res.code == 200 && res.data.length > 0) {
            this.mediaList = res.data
          }
        });
    },
    getbqList() {
      for (let i = 1; i < 4; i++) {
        this.httphelper
          .get('CourseApi/GetHomeCourseNaviList', { TypeId: i })
          .then(res => {
            if (res.code == 200 && res.data.length > 0) {
              if (i == 1) {
                this.t3bqList = res.data
                this.t3bqIng = res.data[0].id
              } else if (i == 2) {
                this.t5bqList = res.data
                this.t5bqIng = res.data[0].id
              } else if (i == 3) {
                this.tjbqList = res.data
                this.tjbqIng = res.data[0].id
              }
              this.getCourseList(res.data[0].id)
            }
          });
      }
    },
    changeT5BqIng(index) {
      this.t5bqIng = index
    },
    changeT3BqIng(index) {
      this.t3bqIng = index
    },
    changeTjbqIng(index) {
      this.tjbqIng = index
    },
    getCourseList(id) {
      this.httphelper
        .post('CourseApi/GetNaviCoursePageList', {
          navId: id,
          courseType: 0,
          orderType: 0,
          page: 1,
          limit: 6
        })
        .then(res => {
          if (res.code == 200) {
            if (id == this.t3bqIng) this.t3List = res.data
            else if (id == this.t5bqIng) this.t5List = res.data
            else if (id == this.tjbqIng) this.tjList = res.data.slice(0, 5)
            else if (id == 17) this.ebookList = res.data.slice(0, 4)
          }
        });
    },
    toCourse(id) {
      this.$router.push({
        name: 'detail',
        query: {
          courseId: id,
        }
      })
    },
    toMp3Ing(item) {
      if (this.isMp3IngId == 0 || this.isMp3IngId != item.id) {
        if (this.Mp3Ing != null || this.isMp3IngId != 0) {
          this.Mp3Ing.pause();
          this.Mp3Ing = null
          this.isMp3IngId = 0
        }
        if (localStorage.getItem('ResData')) {
          this.httphelper
            .post('/CGPInfoApi/GetCourseRes', {
              courseId: item.id,
              userId: 0
            })
            .then(res => {
              this.Mp3Ing = new Audio(res.data);
              this.Mp3Ing.play();
              this.isMp3IngId = item.id
            })
        } else {
          this.$confirm('请登录后播放音频', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            let callback = encodeURIComponent(this.delUrlData('token'));
            window.location.href = 'https://gp519.com/loginIndex/login?callback=' + callback;
          })
        }

      } else {
        this.isMp3IngId = 0
        this.Mp3Ing.pause();
        this.Mp3Ing = null
      }
    },
    toRecordMp3() {
      this.Mp3Ing.pause();
      this.Mp3Ing = null
      this.isMp3IngId = 0
    }
  }
}
</script>
<style lang="scss" scoped>
.top {
  height: 450px;
}

.top2f {
  height: 340px;
  width: 100%;
  background: #f7f7f7;
}

.top3 {
  padding: 40px 0 23px;

  .top3left {
    width: 850px;

    .t3lbox {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      .t3cbox {
        margin-bottom: 27px;
        margin-right: 26px;

        ::v-deep .box {
          width: 266px;
          height: 280px;
        }

        ::v-deep .img {
          width: 266px;
          height: 150px;
        }
      }
    }
  }

  .top3right {
    width: 300px;
    margin-left: 50px;

    .t3b1 {
      .t3b1box {
        padding: 0px 20px;
        margin: 19px 0;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        text-align: center;
        border-right: 1px solid #eee;
        cursor: pointer;
      }

      .active {
        color: #0b8586;
      }
    }

    .t3b2 {
      .t3b2box {
        padding: 16px 0;
        cursor: pointer;
        position: relative;

        .t3b2img {
          margin-right: 20px;
          width: 50px;
          height: 50px;
        }

        .t3b2icon {
          position: absolute;
          top: 31px;
          left: 16px;
          display: none;
          width: 17;
          height: 17px;
          background-color: rgb(184, 10, 10);
          border-radius: 50%;
        }

        .t3b2gif {
          position: absolute;
          top: 31px;
          left: 16px;
          width: 17;
          height: 17px;
          display: none;
          background-color: rgb(184, 10, 10);
          border-radius: 50%;
        }

        .t3b2right {
          flex: 1;
        }

        .t3b2title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 21px;
          height: 42px;
          margin-bottom: 5px;

          img {
            width: 26px;
            height: 26px;
            margin-left: 10px;
            margin-bottom: -5px;
          }

          span {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .t3b2bqbox {
          padding: 0 5px;
          height: 21px;
          border: 1px solid #979fa6;
          border-radius: 2px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #979fa6;
          line-height: 19px;
          margin-right: 3px;
        }
      }

      .t3b2box:hover {
        .t3b2icon {
          display: block;
        }

        .t3b2gif {
          display: block;
        }
      }
    }
  }
}

.top4 {
  height: 110px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
  }
}

.top5 {
  padding: 40px 0 23px;

  .top5left {
    width: 850px;

    .t5lbox {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      .t5cbox {
        margin-bottom: 27px;
        margin-right: 26px;

        ::v-deep .box {
          width: 266px;
          height: 280px;
        }

        ::v-deep .img {
          width: 266px;
          height: 150px;
        }
      }
    }
  }

  .top5right {
    width: 300px;
    margin-left: 50px;

    .t5rrow {
      height: 308px;
      border-bottom: 1px solid #eee;
      padding: 28px 0;

      .t5rbox {
        width: 138px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        cursor: pointer;

        img {
          width: 138px;
          height: 181px;
          margin-bottom: 15px;
        }

        span {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}

.top6f {
  width: 100%;
  height: 495px;
  background: #f5f5f6;

  .top6 {
    padding: 40px 0 23px;
  }
}

.top7f {
  width: 100%;
  height: 186px;
  background-image: url('../../assets/images/home/botBg.png');
  background-size: 100% 100%;
}

.top8 {
  padding: 40px 0 23px;

  .mediaList {
    padding: 30px 40px;

    .t8box {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 50px;

      img {
        width: 110px;
        height: 110px;
      }
    }
  }
}
</style>