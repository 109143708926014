<template>
  <div>
    <div class="box" @click="toCourse">
      <img :src="info.acr_Pict || info.acr_PictResUrl" v-show="!isloading" class="img" alt="" />
      <img src="../../../assets/icons/loading.png" v-show="isloading" class="img2" alt="" />
      <img src="../../../assets/icons/bofang3.png" class="bfbtn" alt="" />
      <div class="boxBot display_column">
        <div class="title">
          <span>{{ info.title }}</span>
        </div>
        <div class="bq display_flex" v-if="info.labelName">
          <div class="bqbox" v-for="(item, index) in info.labelName.split(',').slice(0, 2)" v-show="item != ''"
            :key="index">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="more display_between">
          <div class="morel">
            <span v-show="info.discountStatus == 2">免费</span>
            <span v-show="info.discountStatus == 1">VIP专享</span>
            <div v-show="info.discountStatus == 3 && userType != 1">
              <span>￥{{ Number(info.discount).toFixed(2) }}</span>
              <span class="originalPrice" v-show="info.price > info.discount">原价￥{{ Number(info.price).toFixed(2) }}</span>
            </div>
            <div v-show="info.discountStatus == 3 && userType == 1">
              <span style="margin-left:5px">SVIP专享</span>
            </div>
          </div>
          <div class="morer display_center_ud">
            <img src="../../../assets/icons/people3.png" alt="" />
            <span>{{ info.playSum }}人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isloading: false,
      userType: 0
    };
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    callback: {
      type: String,
      default: '',
    },
  },
  created() {
    this.isloading = true;
    var that = this;
    setTimeout(() => {
      that.isloading = false;
    }, 100);
    if (localStorage.getItem('userType')) {
      that.userType = localStorage.getItem('userType');
    } else {
      that.userType = 0;
    }
  },
  watch: {
    info: function () {
      this.isloading = true;
      var that = this;
      setTimeout(() => {
        that.isloading = false;
      }, 100);
    },
  },
  methods: {
    toCourse() {
      let id = this.info.id;
      if (id == 0) id = this.info.courseInfoId;
      if (this.callback == '') {
        // this.$router.push({
        //     name: 'detail',
        //     query: {
        //         courseId: id,
        //     },
        // });
        let urlss = window.location.origin + '/detail?courseId=' + id;
        window.location.href = urlss;
      } else {
        // this.$router.push({
        //     name: 'detail',
        //     query: {
        //         courseId: id,
        //         callback: this.callback,
        //     },
        // });
        let urlss = window.location.origin + '/detail?courseId=' + id + '&callback=' + this.callback;
        window.location.href = urlss;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  //   width: 160px;
  width: 100%;
  //   height: 180px;
  background: #ffffff;
  position: relative;

  .img {
    // width: 160px;
    width: 100%;
    height: 90px;
  }

  .img2 {
    width: 32px;
    height: 32px;
    margin: 29px 64px;
  }

  .bfbtn {
    position: absolute;
    top: 70px;
    left: 5px;
    width: 15px;
    height: 15px;
  }

  .boxBot {
    margin-top: -5px;

    .title {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      height: 32px;
      margin: 8px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .bq {
      margin-bottom: 10px;
      overflow: hidden;

      .bqbox {
        height: 15px;
        background: #ffffff;
        border: 1px solid #979fa6;
        font-size: 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #979fa6;
        line-height: 15px;
        padding: 0 3px;
        margin-right: 6px;
      }
    }

    .more {
      margin-bottom: 6px;

      .morel {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #f01414;
        line-height: 12px;
      }

      .morer {
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #919ca3;
        line-height: 12px;

        img {
          width: 9px;
          height: 10px;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
