<template>
  <div>
    <div class="tabbar applebottom display_between">
      <div class="tabbox" @click="toUrl2(httphelper.IsDebugH5GP519())">
        <img src="../../../assets/icons/index/b1.png" alt="">
        <!-- <img src="../../../assets/icons/index/a1.png"
             alt=""> -->
        <span>网站首页</span>
      </div>
      <div class="tabbox" @click="toUrl2('https://cgphome.com/')" :style="$route.name == 'home' ? 'color:#e83030' : ''">
        <img src="../../../assets/icons/index/a2.png" v-if="$route.name == 'home'" alt="">
        <img src="../../../assets/icons/index/b2.png" v-else alt="">
        <span>首页</span>
      </div>
      <div class="tabbox" @click="toAllCourse"
        :style="$route.name == 'allCourse' || $route.name == 'detailVideo' || $route.name == 'detailAudio' || $route.name == 'detailEbook' || $route.name == 'mSearch' || $route.name == 'list' ? 'color:#e83030' : ''">
        <img src="../../../assets/icons/index/a3.png"
          v-if="$route.name == 'allCourse' || $route.name == 'detailVideo' || $route.name == 'detailAudio' || $route.name == 'detailEbook' || $route.name == 'mSearch' || $route.name == 'list'"
          alt="">
        <img src="../../../assets/icons/index/b3.png" v-else alt="">
        <span>发现课程</span>
      </div>
      <div class="tabbox" @click="toUrl(httphelper.IsDebugH5GP519() + '/pages/user/course')">
        <img src="../../../assets/icons/index/b4.png" alt="">
        <!-- <img src="../../../assets/icons/index/a4.png"
             alt=""> -->
        <span>我的课程</span>
      </div>
      <div class="tabbox" @click="toUrl(httphelper.IsDebugH5GP519() + '/pages/user/index')">
        <img src="../../../assets/icons/index/b5.png" alt="">
        <!-- <img src="../../../assets/icons/index/a5.png"
             alt=""> -->
        <span>我的</span>
      </div>
    </div>
    <!-- <div class="applebotbox"></div> -->
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  props: {
    toRecordMp3: {
      type: Function,
      default: null
    },
    isMp3IngId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    toHome() {
      this.$router.push('/home')
    },
    toAllCourse() {
      this.$router.push('/allCourse')
    },
    toUrl(url) {
      let token = localStorage.getItem('token_newgphome')
      // console.log(token);
      if (!token || token == null) {
        Dialog.alert({
          title: '提示',
          message: '请点击确认登录',
          showCancelButton: true
        }).then(() => {
          var callback = encodeURIComponent(window.location.href);
          if (this.isMp3IngId != 0) this.toRecordMp3()
          window.open(this.httphelper.IsDebugH5GP519() + '/pages/login/login?callback=' + callback, '_blank')
        });
      } else {
        if (this.isMp3IngId != 0) this.toRecordMp3()
        window.open(url + '?tokenkey=' + token, '_blank')
      }
    },
    toUrl2(url) {
      if (localStorage.getItem('app')) {
        if (this.isMp3IngId != 0) this.toRecordMp3()
        let token = localStorage.getItem('token_newgphome')
        window.open(url + '?tokenkey=' + token + '&app=1', '_blank')
      } else {
        if (this.isMp3IngId != 0) this.toRecordMp3()
        let token = localStorage.getItem('token_newgphome')
        window.open(url + '?tokenkey=' + token, '_blank')
      }

    },
  }
}
</script>
<style lang="scss" scoped>
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 51px;
  background: #ffffff;
  border-top: 1px solid #dddddd;

  .tabbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 20%;
    font-family: Microsoft YaHei;
    font-weight: 400;
    // color: #e83030;
    color: #000000;
    line-height: 16px;

    img {
      width: 23px;
      height: 23px;
    }
  }
}
</style>