<template>
  <div>
    <div v-if="!isMobile()">
      <Top :isShoucang="courseInfo.isFavorite"
           :info="courseInfo"
           :toShoucang="toShoucang"></Top>
      <div class="midbox EC_width display_flex">
        <div class="mleft">
          <router-view :toBofang="toBofang"></router-view>
        </div>
        <MiddleRight :mp4List="mp4List"
                     :info="courseInfo"
                     :isBofang="isBofang"
                     :toCourse="toCourse"></MiddleRight>
      </div>
      <div class="mid2 EC_width display_flex">
        <div class="m2left">
          <span>{{ courseInfo.title }}</span>
        </div>
        <div class="m2right display_start_end">
          <div class="m2b1 display_start_end"
               v-show="courseInfo.discountStatus == 2">
            <span class="m2span1">免费</span>
            <span class="del"
                  v-if="cid == 2554 && newCiding == 2522">原价￥299.00</span>
          </div>
          <div class="m2b2"
               v-show="courseInfo.discountStatus == 1">
            <span class="m2span1">VIP专享</span>
          </div>
          <div class="m2b3"
               v-show="courseInfo.discountStatus == 3 && userType != 1">
            <span class="m2span1">￥{{ Number(courseInfo.discount).toFixed(2) }}</span>
            <span class="m2span2"
                  v-show="courseInfo.price > courseInfo.discount">原价￥{{ Number(courseInfo.price).toFixed(2)
            }}</span>
            <!-- <span v-show="courseInfo.limitedTime != null"
                  class="m2span3">({{ courseInfo.limitedTime }})</span> -->
          </div>
          <div class="m2b2"
               v-show="courseInfo.discountStatus == 3 && userType == 1">
            <span class="m2span1">SVIP专享</span>
          </div>
        </div>
      </div>
      <div class="mid3 EC_width display_center_ud">
        <div class="m3left display_center_ud">
          <div class="m3bq">
            <span>{{ type == 3 ? '讲者/来源' : '讲者' }}</span>
          </div>
          <Jiangzhe :expertsList="expertsList"></Jiangzhe>
        </div>
        <div class="m3right display_center_ud">
          <div class="m3bq"
               style="margin-right: 10px"
               @click="toLogin"
               v-show="uid == 0">
            <span>立即登录</span>
          </div>
          <div class="m3bq"
               style="margin-right: 10px"
               @click="toShop"
               v-show="uid != 0 && !isShop && courseInfo.discountStatus == 3">
            <span>立即购买</span>
          </div>
          <div class="m3bq"
               style="margin-right: 10px"
               @click="toShopViP"
               v-show="uid != 0 && !isShop && courseInfo.discountStatus == 1">
            <span>立即成为VIP</span>
          </div>
          <div class="m3bqs"
               style="margin-right: 10px"
               v-show="uid != 0 && courseInfo.isOrgPush">
            <span>推送课程</span>
          </div>
          <!-- <div class="m3bq">
          <span>立即学习</span>
        </div> -->
          <div class="m3bq"
               v-show="uid != 0 && courseInfo.isPurchase == false && courseInfo.discountStatus == 3 && userType != 1"
               @click="toShopCar"
               style="background: #0b8586; color: #fff">
            <span>加入购物车</span>
          </div>
        </div>
      </div>
      <M4box :info="courseInfo"></M4box>
      <div class="bot">
        <Bottom :toCourse="toCourse"
                :expertsList="expertsList"
                :isBofang="isBofang"
                :info="courseInfo"
                :mp4List="mp4List"></Bottom>
      </div>
      <payCont v-if="isPayContShow"
               :CourseInfo="courseInfo"
               :type="1"
               :close="closePayContShow"
               :payType="1"></payCont>
    </div>
    <div v-else>
      <Mindex :info="courseInfo"
              v-if="!isPayContShow"
              :toCourse="toCourse"
              :mp4List="mp4List"
              :isShop="isShop"
              :isRfs="isRfs"
              :changeRfs="changeRfs"
              :tofShoucang="toShoucang"
              :expertsList="expertsList"></Mindex>
      <mPayCont v-if="isPayContShow && Object.values(courseInfo).length > 0"
                :CourseInfo="courseInfo"
                :type="1"
                :payType="1">
      </mPayCont>
    </div>
  </div>
</template>

<script>
import Bottom from '../../components/detail/bottom.vue';
import MiddleRight from '../../components/detail/middleRight.vue';
import Top from '../../components/detail/top.vue';
import Jiangzhe from '../../components/detail/jiangzhe.vue';
import M4box from '../../components/detail/m4box.vue';
import Mindex from '../../components/mobile/detail/index.vue';
import payCont from '../../components/pay/payCont.vue';
import mPayCont from '../../components/pay/mPayCont.vue';
import { Dialog } from 'vant';
export default {
  name: 'videoDetail',
  components: {
    Bottom,
    MiddleRight,
    Top,
    Jiangzhe,
    M4box,
    Mindex,
    payCont,
    mPayCont
  },
  data () {
    return {
      cid: 0,
      uid: 0,
      courseInfo: {},
      mp4List: [],
      expertsList: [],
      type: 0,
      cidIng: 0,
      isShop: true,
      isCar: false,
      isBofang: false,
      isRfs: false,
      callbackurl: '',
      isPayContShow: false,
      userType: 0,
      newCiding: 0
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (oldVal.name != 'detail') {
          if (this.$route.query.courseId) {
            this.cid = Number(this.$route.query.courseId);
            if (this.cid == 0 && this.$route.query.cidIng) {
              this.cid = Number(this.$route.query.cidIng);
            }
            if (!this.$route.query.cidIng) {
              this.getCoursedetail();
            } else {
              setTimeout(() => {
                this.getCoursedetail2();
              }, 50);
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted () { },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType');
    }
    if (this.$route.query.courseId) {
      this.cid = Number(this.$route.query.courseId);
      if (this.cid == 0) {
        this.cid = Number(this.$route.query.cidIng);
      }
      if (!this.$route.query.cidIng) {
        this.getCoursedetail();
      } else {
        setTimeout(() => {
          this.getCoursedetail2();
        }, 50);
      }
      if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'callback')) {
        this.callbackurl = decodeURIComponent(this.$route.query.callback);
      }
      if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'code')) {
        this.isPayContShow = true
      }
      if (this.$route.query.cidIng) {
        this.newCiding = Number(this.$route.query.cidIng)
      }
      console.log(this.cid, this.newCiding)
    }
  },
  methods: {
    getCoursedetail () {
      this.httphelper
        .post('CourseApi/GetCourseInfoDetail', {
          UserId: this.uid,
          CourseId: this.cid,
        })
        .then(res => {
          if (res.code == 200 && res.data != null) {
            if (res.data.is_Putaway == false) {
              if (this.isMobile()) {
                Dialog.confirm({
                  title: '提示',
                  message: '该课程已下架',
                  confirmButtonText: '返回首页',
                  showCancelButton: false
                }).then(() => {
                  this.$router.push('/home');
                });
              } else {
                this.$confirm('该课程已下架', '提示', {
                  confirmButtonText: '返回首页',
                  showCancelButton: false,
                  showClose: false
                }).then(() => {
                  this.$router.push('/home');
                });
              }
              return
            }
            this.courseInfo = res.data;
            this.getIsShop();
            this.expertsList = res.data.expertlist;
            if (res.data.video_Type == 1) {
              let info = res.data;
              this.cidIng = info.courseId;
              this.type = info.res_Type;
              info['expertName'] = this.expertsList[0].name;
              info['unit'] = this.expertsList[0].unit;
              this.mp4List = [];
              this.mp4List.push(info);
              if (this.type == 2) {
                if (this.$route.name != 'detailVideo') {
                  if (this.callbackurl == '') {
                    if (this.$route.query.sub) {
                      this.$router.replace({
                        name: 'detailVideo',
                        query: { courseId: 0, cidIng: this.cidIng, sub: this.$route.query.sub },
                      });
                    } else {
                      this.$router.replace({
                        name: 'detailVideo',
                        query: { courseId: 0, cidIng: this.cidIng },
                      });
                    }

                  } else {
                    if (this.$route.query.sub) {
                      this.$router.replace({
                        name: 'detailVideo',
                        query: { courseId: 0, cidIng: this.cidIng, callback: this.callbackurl, sub: this.$route.query.sub },
                      });
                    } else {
                      this.$router.replace({
                        name: 'detailVideo',
                        query: { courseId: 0, cidIng: this.cidIng, callback: this.callbackurl },
                      });
                    }
                  }
                }
              } else if (this.type == 3) {
                if (this.$route.name != 'detailAudio') {
                  if (this.callbackurl == '') {
                    if (this.$route.query.sub) {
                      this.$router.replace({
                        name: 'detailAudio',
                        query: { courseId: 0, cidIng: this.cidIng, sub: this.$route.query.sub },
                      });
                    } else {
                      this.$router.replace({
                        name: 'detailAudio',
                        query: { courseId: 0, cidIng: this.cidIng },
                      });
                    }
                  } else {
                    if (this.$route.query.sub) {
                      this.$router.replace({
                        name: 'detailAudio',
                        query: { courseId: 0, cidIng: this.cidIng, callback: this.callbackurl, sub: this.$route.query.sub },
                      });
                    } else {
                      this.$router.replace({
                        name: 'detailAudio',
                        query: { courseId: 0, cidIng: this.cidIng, callback: this.callbackurl },
                      });
                    }
                  }
                }
              } else if (this.type == 4) {
                if (this.$route.name != 'detailEbook') {
                  if (this.callbackurl == '') {
                    if (this.$route.query.sub) {
                      this.$router.replace({
                        name: 'detailEbook',
                        query: { courseId: 0, cidIng: this.cidIng, sub: this.$route.query.sub },
                      });
                    } else {
                      this.$router.replace({
                        name: 'detailEbook',
                        query: { courseId: 0, cidIng: this.cidIng },
                      });
                    }

                  } else {
                    if (this.$route.query.sub) {
                      this.$router.replace({
                        name: 'detailEbook',
                        query: { courseId: 0, cidIng: this.cidIng, callback: this.callbackurl, sub: this.$route.query.sub },
                      });
                    } else {
                      this.$router.replace({
                        name: 'detailEbook',
                        query: { courseId: 0, cidIng: this.cidIng, callback: this.callbackurl },
                      });
                    }
                  }
                }
              }
            } else if (res.data.video_Type == 2) {
              this.getCourseMenu();
            }
          }
        });
    },
    getCoursedetail2 () {
      this.httphelper
        .post('CourseApi/GetCourseInfoDetail', {
          UserId: this.uid,
          CourseId: this.cid,
        })
        .then(res => {
          if (res.code == 200 && res.data != null) {
            this.courseInfo = res.data;
            this.getIsShop();
            this.expertsList = res.data.expertlist;
            if (res.data.video_Type == 1) {
              let info = res.data;
              info['expertName'] = this.expertsList[0].name;
              info['unit'] = this.expertsList[0].unit;
              this.mp4List = [];
              this.mp4List.push(info);
            } else {
              this.getCourseMenu2();
            }
          }
        });
    },
    getCourseMenu () {
      this.httphelper
        .post('CourseApi/GetCourseCataLogList', {
          CourseId: this.cid,
          UserId: this.uid,
        })
        .then(res => {
          if (res.data != null && res.data.length > 0) {
            this.mp4List = res.data;
            let info = this.mp4List[0];
            this.type = info.res_Type;
            if (this.$route.query.cidIng == info.courseId) return;
            if (this.type == 2) {
              if (this.callbackurl == '') {
                if (this.$route.query.sub) {
                  this.$router.replace({
                    name: 'detailVideo',
                    query: { courseId: this.cid, cidIng: info.courseId, sub: this.$route.query.sub },
                  });
                } else {
                  this.$router.replace({
                    name: 'detailVideo',
                    query: { courseId: this.cid, cidIng: info.courseId },
                  });
                }
              } else {
                if (this.$route.query.sub) {
                  this.$router.replace({
                    name: 'detailVideo',
                    query: { courseId: this.cid, cidIng: info.courseId, callback: this.callbackurl, sub: this.$route.query.sub },
                  });
                } else {
                  this.$router.replace({
                    name: 'detailVideo',
                    query: { courseId: this.cid, cidIng: info.courseId, callback: this.callbackurl },
                  });
                }
              }
            } else if (this.type == 3) {
              if (this.callbackurl == '') {
                if (this.$route.query.sub) {
                  this.$router.replace({
                    name: 'detailAudio',
                    query: { courseId: this.cid, cidIng: info.courseId, sub: this.$route.query.sub },
                  });
                } else {
                  this.$router.replace({
                    name: 'detailAudio',
                    query: { courseId: this.cid, cidIng: info.courseId },
                  });
                }
              } else {
                if (this.$route.query.sub) {
                  this.$router.replace({
                    name: 'detailAudio',
                    query: { courseId: this.cid, cidIng: info.courseId, callback: this.callbackurl, sub: this.$route.query.sub },
                  });
                } else {
                  this.$router.replace({
                    name: 'detailAudio',
                    query: { courseId: this.cid, cidIng: info.courseId, callback: this.callbackurl },
                  });
                }
              }
            } else if (this.type == 4) {
              if (this.callbackurl == '') {
                if (this.$route.query.sub) {
                  this.$router.replace({
                    name: 'detailEbook',
                    query: { courseId: this.cid, cidIng: info.courseId, sub: this.$route.query.sub },
                  });
                } else {
                  this.$router.replace({
                    name: 'detailEbook',
                    query: { courseId: this.cid, cidIng: info.courseId },
                  });
                }
              } else {
                if (this.$route.query.sub) {
                  this.$router.replace({
                    name: 'detailEbook',
                    query: { courseId: this.cid, cidIng: info.courseId, callback: this.callbackurl, sub: this.$route.query.sub },
                  });
                } else {
                  this.$router.replace({
                    name: 'detailEbook',
                    query: { courseId: this.cid, cidIng: info.courseId, callback: this.callbackurl },
                  });
                }
              }
            }
          }
        });
    },
    getCourseMenu2 () {
      this.httphelper
        .post('CourseApi/GetCourseCataLogList', {
          CourseId: this.cid,
          UserId: this.uid,
        })
        .then(res => {
          if (res.data != null) {
            this.mp4List = res.data;
          }
        });
    },
    toCourse (item) {
      if (this.$route.query.cidIng == item.courseId) return;
      if (this.uid == 0 && item.isApply == false && !this.isMobile()) {
        this.$confirm('请登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          let callback = encodeURIComponent(this.delUrlData('token'));
          window.location.href = 'https://gp519.com/loginIndex/login?callback=' + callback;
        });
        return;
      }
      this.getCourseMenu2();
      if (item.res_Type == this.type) {
        this.$router.replace({ query: { courseId: this.cid, cidIng: item.courseId } });
      } else {
        this.type = item.res_Type;
        if (this.type == 2) {
          if (this.$route.query.sub) {
            this.$router.replace({
              name: 'detailVideo',
              query: { courseId: this.cid, cidIng: item.courseId, sub: this.$route.query.sub },
            });
          } else {
            this.$router.replace({
              name: 'detailVideo',
              query: { courseId: this.cid, cidIng: item.courseId },
            });
          }
        } else if (this.type == 3) {
          if (this.$route.query.sub) {
            this.$router.replace({
              name: 'detailAudio',
              query: { courseId: this.cid, cidIng: item.courseId, sub: this.$route.query.sub },
            });
          } else {
            this.$router.replace({
              name: 'detailAudio',
              query: { courseId: this.cid, cidIng: item.courseId },
            });
          }
        } else if (this.type == 4) {
          if (this.$route.query.sub) {
            this.$router.replace({
              name: 'detailEbook',
              query: { courseId: this.cid, cidIng: item.courseId, sub: this.$route.query.sub },
            });
          } else {
            this.$router.replace({
              name: 'detailEbook',
              query: { courseId: this.cid, cidIng: item.courseId },
            });
          }
        }
      }
    },
    toShoucang () {
      this.courseInfo.isFavorite = !this.courseInfo.isFavorite;
    },
    getIsShop () {
      if (this.courseInfo.isPurchase == true) {
        this.isShop = true;
      } else {
        if (this.courseInfo.discountStatus == 2) {
          this.isShop = true;
        } else if (this.courseInfo.discountStatus == 1 && localStorage.getItem('userType') != 0) {
          this.isShop = true;
        } else if (this.courseInfo.discountStatus == 3 && localStorage.getItem('userType') == 1) {
          this.isShop = true;
        } else {
          this.isShop = false;
        }
      }
    },
    toShopCar () {
      if (this.uid == 0) {
        this.$message({
          message: '请登录',
          type: 'warning',
        });
        return;
      }
      this.httphelper
        .post('CGPInfoApi/AddGpChat', {
          userId: this.uid,
          courseId: this.cid,
        })
        .then(res => {
          if (res.code == 200) {
            this.isCar = true;
            this.$message({
              message: '添加成功',
              type: 'success',
            });
          } else if (res.code == 242) {
            this.isCar = true;
            this.$confirm('课程已存在，点击确定跳转至购物车', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              window.location.href = 'https://gp519.com/user/userShopping'
            });
            // this.$message({
            //   message: '课程已存在，无需重复添加',
            //   type: 'warning',
            // });
          } else if (res.code == 243) {
            this.$message({
              message: '购物车已满',
              type: 'warning',
            });
          } else if (res.code == 311) {
            this.$confirm('已有订单包含该课程，点击确定跳转至订单中心', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              window.location.href = 'https://gp519.com/user/userMyOrder'
            });
            // this.$message({
            //   message: '已有订单包含该课程',
            //   type: 'warning',
            // });
          } else {
            this.$message({
              message: '添加失败',
              type: 'warning',
            });
          }
        });
    },
    toShop () {
      if (this.uid == 0) {
        this.$message({
          message: '请登录',
          type: 'warning',
        });
        return;
      }
      this.isPayContShow = true
      //   let callback = encodeURIComponent(window.location.href);
      //   window.location.href = 'https://gp519.com/user/userVip?callback=' + callback;
    },
    toShopViP () {
      if (this.uid == 0) {
        this.$message({
          message: '请登录',
          type: 'warning',
        });
        return;
      }
      let callback = encodeURIComponent(window.location.href);
      window.location.href = 'https://gp519.com/user/userVip?callback=' + callback;
    },
    closePayContShow () {
      this.isPayContShow = false
      location.reload()
    },
    toBofang (e) {
      if (e == 1) this.isBofang = true;
      else this.isBofang = false;
    },
    changeRfs (e) {
      this.isRfs = e;
    },
    toLogin () {
      let callback = encodeURIComponent(this.delUrlData('token'));
      window.location.href = 'https://gp519.com/loginIndex/login?callback=' + callback;
    },
  },
};
</script>
<style lang="scss" scoped>
.midbox {
    height: 489px;

    .mleft {
        width: 870px;
        height: 100%;

        .fengmian {
            height: 100%;
            background-size: 100%;

            img {
                cursor: pointer;
            }
        }
    }
}

.mid2 {
    height: 72px;

    .m2left {
        width: 870px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 72px;
        overflow: hidden;
        /*超出部分隐藏*/
        white-space: nowrap;
        /*不换行*/
        text-overflow: ellipsis;
        /*超出部分文字以...显示*/
    }

    .m2right {
        padding-bottom: 20px;

        .m2span1 {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
        }

        .m2span2 {
            margin-left: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            text-decoration: line-through;
            color: #666666;
        }

        .m2span3 {
            margin-left: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
        }

        .m2b4 {
            border: 1px solid #0b8586;
            margin-left: 30px;
            font-size: 14px;
            line-height: 16px;
            padding: 5px;
            border-radius: 4px;
            background-color: #0b8586;
            color: #fff;
        }
    }
}

.mid3 {
    height: 42px;
    margin-bottom: 19px;

    .m3left {
        width: 870px;
        height: 100%;

        .m3bq {
            width: 71px;
            height: 29px;
            background: #def3f3;
            border: 1px solid #0b8586;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
            line-height: 28px;
            text-align: center;
        }
    }

    .m3right {
        height: 100%;

        .m3bq {
            width: 160px;
            height: 44px;
            background: #def3f3;
            border: 1px solid #0b8586;
            border-radius: 22px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
            line-height: 42px;
            text-align: center;
            cursor: pointer;
        }

        .m3bqs {
            float: right;
            width: 160px;
            height: 44px;
            background-image: url('../../assets/images/detail/biaoqian.png');
            background-size: 100% 100%;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 42px;
            text-align: center;
            position: relative;
            left: 173px;
        }
    }
}

.bot {
    padding: 30px 0 70px;
    background: #f7f7f7;
}
.del {
    text-decoration: line-through;
    color: #ccc;
    margin-left: 10px;
}
</style>
