<template>
  <div>
    <div class="mright">
      <div class="mrtop"
           v-show="userType!=1">
        <a href="https://gp519.com/user/userVip"
           target="_blank">
          <img src="../../assets/images/detail/svip.png"
               alt="">
          <span>全站通SVIP免费看 >></span>
        </a>
      </div>
      <div class="mrmid">
        <div class="mrbox"
             :style="index==0?'border-top:0':''"
             v-for="(item, index) in mp4List"
             :ref="item.courseId==cidIng?'playing':''"
             @click="toCourse(item)"
             :key="index">
          <div class="mrbt display_between_nocenter">
            <div class="mrbq"
                 :style="item.courseId==cidIng?'background: #08A4A5;color:#fff':''">
              <span>{{item.res_Type==2?'视频':(item.res_Type==3?'音频':'电子书')}}</span>
            </div>
            <div class="mrbq"
                 v-show="(item.isApply&&!info.isPurchase)&&!((info.discountStatus == 2 && uid != 0) || (info.discountStatus == 1 && userType != 0)|| (info.discountStatus == 3 && userType == 1))"
                 style="color:#fff;background: #E60012;margin-left:10px">
              <span>试看</span>
            </div>
            <div class="mrtitle"
                 :style="item.courseId==cidIng?'color: #08A4A5;':''">
              <span>{{item.title}}</span>
            </div>
            <img src="../../assets/icons/boFang.png"
                 v-show="item.courseId==cidIng&&isBofang==false"
                 alt="">
            <img src="../../assets/gifs/bofang.gif"
                 v-show="item.courseId==cidIng&&isBofang==true"
                 alt="">
          </div>
          <div class="mrbm display_column">
            <span>讲者：{{item.expertName}}</span>
            <span>单位：{{item.unit}}</span>
            <!-- <span v-if="item.res_Type==4">页数：
              <span style="font-weight: 800;">{{item.standingtime}}</span>
              <span>/{{item.res_duration}}</span>
            </span> -->
            <span v-show="item.res_Type!=4">时长：
              <span style="font-weight: 800;">{{item.standingtime==0?'0:00':item.standingtime.toFixed(2).toString().replace('.', ':')}}</span>
              <span>/{{item.res_duration.toFixed(2).toString().replace('.', ':')}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      cidIng: 0,
      uid: 0,
      userType: 0,
    }
  },
  props: {
    mp4List: [],
    toCourse: {
      type: Function,
      default: null
    },
    info: {
      type: Object,
      default: null
    },
    isBofang: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route: {
      handler () {
        this.cidIng = Number(this.$route.query.cidIng)
        if (this.$refs.playing) {
          let dom = this.$refs.playing[0].parentElement
          for (let i = 0; i < this.mp4List.length; i++) {
            if (this.mp4List[i].courseId == this.cidIng) {
              dom.parentElement.scrollTop = dom.childNodes[i].offsetTop - dom.childNodes[i].offsetHeight - 100
            }
          }
        }
      },
      deep: true
    },
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = JSON.parse(localStorage.getItem('ResData')).id;
      this.userType = localStorage.getItem('userType')
    } else {
      this.userType = 0
    }
    if (this.$route.query.cidIng) {
      this.cidIng = Number(this.$route.query.cidIng)
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.mright {
    width: 330px;
    height: 489px;
    background: #15161b;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-arrow-color: #f0f2f5; /* 设置滚动条上的箭头颜色  */
    scrollbar-base-color: #f0f2f5; /* 设置滚动条的底色 */
    scrollbar-track-color: #f0f2f5; /* 设置滚动条块的背景色 */
    scrollbar-shadow-color: #f0f2f5; /* 设置箭头和滚动条右侧和底边的颜色 */
    .mrtop {
        margin: 15px 16px 0;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #784a13;
        position: relative;
        span {
            position: absolute;
            top: 14px;
            left: 110px;
        }
    }
    .mrmid {
        margin: 0 20px;
        .mrbox {
            padding: 20px 0;
            border-top: 1px solid #2a2a2a;
            cursor: pointer;
            .mrbt {
                .mrbq {
                    width: 42px;
                    height: 18px;
                    background: #c7c7c7;
                    border-radius: 1px;
                    text-align: center;
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 14px;
                    padding: 2px 0;
                    //   margin-bottom: -2px;
                }
                .mrtitle {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #c7c7c7;
                    line-height: 22px;
                    flex: 1;
                    margin: -2px 15px 0 10px;
                }
                img {
                    width: 15px;
                    height: 16px;
                    margin-top: 2px;
                }
            }
            .mrbm {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #777777;
                line-height: 22px;
                margin-top: 6px;
            }
        }
    }
}
.mright::-webkit-scrollbar {
    /* 滚动条宽 */
    width: 6px;
    height: 4px;
}
.mright::-webkit-scrollbar-thumb {
    /* 滚动条 拖动条 */
    background-color: #6d6d6d;
    border-radius: 6px;
}
.mright::-webkit-scrollbar-track {
    /* 滚动条背景槽 */
    background-color: #15161b;
    border-radius: 6px;
}
</style>