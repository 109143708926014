<template>
  <div>
    <div class="box display_between">
      <div class="boxleft display_center_ud">
        <img :src="imgUrl"
             alt="">
        <span>{{title}}</span>
      </div>
      <div v-if="!isMore"></div>
      <div class="boxright display_center_ud"
           v-if="isMore"
           @click="toMore!=null?toMore(type):''">
        <img src="../../../assets/icons/more.png"
             alt="">
        <span>更多</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    toMore: {
      type: Function,
      default: null
    },
    type: {
      type: Number,
      default: 0
    },
    isMore: {
      type: Boolean,
      default: true
    }
  },
}
</script>
<style lang="scss" scoped>
.box {
    height: 37px;
    border-bottom: 1px solid #eee;
    margin: 0 18px;
    .boxleft {
        border-bottom: 1px solid #0b8586;
        line-height: 36px;
        img {
            width: 16px;
            height: 18px;
            margin-right: 10px;
            margin-bottom: -2px;
        }
        span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #0b8586;
        }
    }
    .boxright {
        cursor: pointer;
        line-height: 12px;
        img {
            width: 10px;
            height: 10px;
            margin-right: 4px;
        }
        span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #404040;
        }
    }
}
</style>